import React, { useEffect } from "react";
import { getIndex } from "@Utils/functions";

interface Props {
  currIdx: number;
  setcurrIdx: React.Dispatch<React.SetStateAction<number>>;
  maxIdx: number;
}

const Filter = ({ currIdx, setcurrIdx, maxIdx }: Props) => {
  const [skip, setSkip] = React.useState<string>("00");

  useEffect(() => {
    setSkip(getIndex(currIdx + 1));
  }, [currIdx]);

  return (
    <section className="w-fit rounded-lg p-2 flex flex-[0.2] flex-col items-center gap-1 border border-gray-300 h-full">
      <section className="flex-1 overflow-auto flex flex-col gap-2 items-center">
        <div className=" flex items-center justify-center gap-2">
          <span className="font-semibold">Skip to:</span>
          <input
            className="border-gray-300 rounded-lg focus:outline-none h-[32px] w-[45px]"
            placeholder="00"
            value={skip}
            onChange={(e) => setSkip(e.target.value)}
            min={1}
            max={maxIdx + 1}
            type="text"
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              let temp = parseInt(e.currentTarget.value);
              if (temp < 1) temp = 0;
              else if (temp > maxIdx) temp = maxIdx;
              else temp -= 1;
              setcurrIdx(temp);
              setSkip(getIndex(temp + 1));
            }}
          />
        </div>
        <div className="flex flex-col items-center gap-2">
          {Array.from({ length: maxIdx + 1 }, (_, idx) => (
            <button
              className={`flex items-center justify-center border w-[81px] h-[40px] rounded-lg ${
                idx === currIdx ? "border-primary bg-primary/10 font-semibold text-primary" : "border-gray-300"
              }`}
              onClick={() => setcurrIdx(idx)}
              key={idx}
            >
              {getIndex(idx + 1)}
            </button>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Filter;
