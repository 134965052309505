import { useEffect, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { DATES_SEPARATOR, fromStringToDateRange } from "../utils";
import moment from "moment";
import { cn } from "@/utils/cn";

export function SearchFilterValuePreviewBetweenDates({ value }: { value: string }) {
  const [fromDate, toDate] = value.split(DATES_SEPARATOR);
  const styled_value = `${moment(fromDate).format("MMM D, YYYY")} - ${moment(toDate).format("MMM D, YYYY")}`;
  return <p className="text-sm">{styled_value}</p>;
}

export function SearchFilterFormBetweenDates({
  value,
  onChangeValue,
  onInteractOutside,
  closePopover,
}: {
  id: string;
  value: string;
  onChangeValue: (_value: string) => void;
  onInteractOutside: boolean;
  closePopover: () => void;
}) {
  const [date, setDate] = useState<DateRange | undefined>();
  useEffect(() => {
    if (onInteractOutside === true) {
      onSubmit();
    }
  }, [onInteractOutside]);

  useEffect(() => {
    if (value) {
      setDate(fromStringToDateRange(value));
    }
  }, [value]);

  function onSubmit() {
    if (!date || !date.from || !date.to) return;
    const fromDate = moment(date.from).format("YYYY-MM-DD");
    const toDate = moment(date.to).format("YYYY-MM-DD");
    const newValue = fromDate + DATES_SEPARATOR + toDate;
    if (newValue == value) {
      closePopover();
      return;
    }
    onChangeValue(newValue);
  }

  return (
    <div className="grid">
      <DayPicker
        showOutsideDays={true}
        className="p-3"
        classNames={{
          months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
          month: "space-y-4",
          caption: "flex justify-center pt-1 relative items-center",
          caption_label: "text-sm font-medium",
          nav: "space-x-1 flex items-center",
          nav_button: cn(
            "border border-input bg-popover hover:bg-muted hover:text-accent-foreground",
            "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
          ),
          nav_button_previous: "absolute left-1",
          nav_button_next: "absolute right-1",
          table: "w-full border-collapse space-y-1",
          head_row: "flex",
          head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
          row: "flex w-full mt-2",
          cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-muted/50 [&:has([aria-selected])]:bg-muted first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
          day: cn("hover:bg-muted hover:text-accent-foreground", "h-9 w-9 p-0 font-normal aria-selected:opacity-100"),
          day_range_end: "day-range-end",
          day_selected:
            "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
          day_today: "bg-muted text-accent-foreground",
          day_outside:
            "day-outside text-muted-foreground opacity-50 aria-selected:bg-muted/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
          day_disabled: "text-muted-foreground opacity-50",
          day_range_middle: "aria-selected:bg-muted aria-selected:text-accent-foreground",
          day_hidden: "invisible",
        }}
        autoFocus
        mode="range"
        defaultMonth={date?.from}
        selected={date}
        onSelect={setDate}
        numberOfMonths={2}
      />
      <button className="btn py-2" type="submit">
        Save
      </button>
    </div>
  );
}
