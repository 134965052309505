import React, { useEffect, useState } from "react";
import { ConfigProvider, Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import { MdDragIndicator } from "react-icons/md";

// const defaultData: TreeDataNode[] = [
//   {
//     title: <span>Parent 1</span>,
//     key: "1",
//     // icon: <span>icon</span>,
//     isLeaf: true,
//   },
// ];

type Props = {
  structureId: string;
  stepDownAPIs: any[];
  payloadConfig: any[];
  updateStepDownOrder: (structureId: string, apiId: string, dropApiId: string, below: boolean) => void;
};

const StepDownSearch = ({ structureId, stepDownAPIs, payloadConfig, updateStepDownOrder }: Props) => {
  const [gData, setGData] = useState<TreeDataNode[]>([]);

  useEffect(() => {
    const apis = stepDownAPIs.map((item: any) => ({
      title: (
        <div className="flex items-center gap-2 bg-white px-4 py-2">
          <img src={item.logo} alt={item.apiName} className="w-6 h-6" />
          <p>{item.apiName}</p>
        </div>
      ),
      key: item.apiId,
      isLeaf: true,
    }));
    const orders = payloadConfig.sort((a, b) => a.order - b.order);
    const orderedApis: TreeDataNode[] = orders.map((order: any) => {
      return apis.find((api) => api.key === order.apiId) as TreeDataNode;
    });

    setGData(orderedApis);
  }, []);

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
      }
    };
    const data = [...gData];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    let ar: TreeDataNode[] = [];
    let i: number;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      // Drop on the top of the drop node
      ar.splice(i!, 0, dragObj!);
      updateStepDownOrder(structureId, dragKey as string, dropKey as string, false);
    } else {
      // Drop on the bottom of the drop node
      ar.splice(i! + 1, 0, dragObj!);
      updateStepDownOrder(structureId, dragKey as string, dropKey as string, true);
    }
    // }
    setGData(data);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Tree: {
            colorBgBase: "#f0f2f5",
            // dr
          },
        },
      }}
    >
      <Tree
        className="draggable-tree bg-[#F6F6F6] p-4"
        draggable={{
          icon: <MdDragIndicator color="black" size={20} />,
        }}
        blockNode
        // onDragEnter={onDragEnter}
        onDrop={onDrop}
        treeData={gData}
      />
    </ConfigProvider>
  );
};

export default StepDownSearch;
