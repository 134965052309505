const DefaultAction = () => {
  return (
    <div className="bg-yellow-100 text-yellow-600 h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-3 h-3"
      >
        <g clipPath="url(#clip0_1058_3029)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99999 7.56165V6.0001L7.99999 2.27018L2.30611 7.01508L6.48506 8.05981L7.99999 8.43855V10.0001L7.99998 13.73L13.6939 8.98512L9.51491 7.94038L7.99999 7.56165ZM9.99999 6.0001V2.27018C9.99999 0.574506 8.02227 -0.351808 6.71962 0.733737L1.02574 5.47863C-0.231581 6.5264 0.233236 8.55841 1.82104 8.95536L5.99999 10.0001V13.73C5.99999 15.4257 7.9777 16.352 9.28035 15.2665L14.9742 10.5216C16.2316 9.47379 15.7667 7.44179 14.1789 7.04484L9.99999 6.0001Z"
            fill="currentColor"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1058_3029">
            <rect width="16" height="16" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DefaultAction;
