import CSVModal from "./Modals/CSVModal";
import { useState } from "react";
import { useCSVReader } from "react-papaparse";
import { ResponseConfiguration } from "../../utils/interfaces";

type Props = {
  template: ResponseConfiguration[];
  workflowId: string;
  children?: React.ReactNode;
  refresh: () => void;
};

const CSVInput = ({ template, workflowId, children, refresh }: Props) => {
  const [resArr, setResArr] = useState<any[]>([]);
  const { CSVReader } = useCSVReader();
  return (
    <div>
      <CSVReader
        onUploadAccepted={(results: any) => {
          if (results?.error) {
            console.error("error in parsing csv file --> ", results?.error);
            return;
          }
          setResArr(results?.data || []);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          getRemoveFileProps,
        }: {
          getRootProps: any;
          acceptedFile: any;
          getRemoveFileProps: any;
        }) => (
          <div {...getRootProps()}>
            {acceptedFile ? (
              <CSVModal
                workflowId={workflowId}
                name={acceptedFile.name || ""}
                getRemoveFileProps={getRemoveFileProps}
                resArr={resArr}
                template={template}
                refresh={refresh}
              />
            ) : (
              children
            )}
          </div>
        )}
      </CSVReader>
    </div>
  );
};

export default CSVInput;
