interface Props {
  fill?: string;
}

const LeftArrow = ({ fill }: Props) => {
  return (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12L1 6.5L6 1" stroke={fill || "#242424"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LeftArrow;
