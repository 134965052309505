import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { deleteRecords } from "@/utils/apis";
import { Button, Checkbox, GetProp, Modal, Row } from "antd";
import { useEffect, useState } from "react";

type Props = {
  modal: boolean;
  dataLoading: boolean;
  close: () => void;
  duplicateData: any[];
  workflowId: string;
  refresh: () => void;
  numOfRows: number;
};

const DedupeModal = ({ modal, dataLoading, close, duplicateData, workflowId, refresh, numOfRows }: Props) => {
  const [checkedValuesFinal, setCheckedValuesFinal] = useState<any[]>([]);

  useEffect(() => {
    const flattenData = duplicateData.map((data) => data.keysToRemove);
    setCheckedValuesFinal(flattenData.flat());
  }, [duplicateData]);

  const handleDelete = async () => {
    if (checkedValuesFinal.length === 0) return;
    await deleteRecords(workflowId || "", checkedValuesFinal);
    setCheckedValuesFinal([]);
    close();
    refresh();
  };

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    // Flatten the array of arrays into a single array
    const flattenedValues = checkedValues.flat();
    // Update the state with the flattened array
    setCheckedValuesFinal(flattenedValues);
  };

  if (numOfRows === 0) {
    return (
      <Modal
        title="Dedupe Based on Column"
        centered
        open={modal}
        onCancel={close}
        styles={{ footer: { display: "none" } }}
        width="30%"
        className="text-center"
      >
        <p>No rows to dedupe!</p>
      </Modal>
    );
  }

  return (
    <Modal
      title="Dedupe Based on Column"
      centered
      open={modal}
      onCancel={close}
      width="30%"
      maskClosable={false}
      className="text-center"
      footer={[
        <Button key="back" type="text" onClick={close}>
          Cancel
        </Button>,
        <Button key="submit" danger disabled={checkedValuesFinal.length === 0} onClick={handleDelete}>
          Delete
        </Button>,
      ]}
    >
      {!dataLoading ? (
        duplicateData.length > 0 ? (
          <div>
            <div className="py-4 space-y-2">
              <p>Review the duplicates found</p>
              <p>
                Duplicates rows are found with respect to the current view only. Deleting duplicates keeps only the
                first appearance of each item in the view.
              </p>
            </div>
            <div className="mb-1 font-bold flex justify-between px-5">
              <p>Items with duplicates({checkedValuesFinal.length})</p>
              <p>Total Rows</p>
            </div>
            <div className="max-h-56 overflow-y-scroll border rounded-md">
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={onChange}
                className="flex flex-col"
                defaultValue={duplicateData.map((data) => data.keysToRemove)}
              >
                {duplicateData.map((data: any, index: number) => (
                  <Row
                    key={index}
                    className={`flex w-full justify-between items-center px-6 ${index !== 0 && "border-t"} py-2`}
                  >
                    <Checkbox value={data.keysToRemove}>{data.value}</Checkbox>
                    <span className=" bg-gray-100 px-2 py-1 rounded-md">{data.count} rows</span>
                  </Row>
                ))}
              </Checkbox.Group>
            </div>
          </div>
        ) : (
          <p>No Duplicates Found!</p>
        )
      ) : (
        <span>
          <SpinnerStatus /> Loading...
        </span>
      )}
    </Modal>
  );
};

export default DedupeModal;
