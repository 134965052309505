import { useState } from "react";
import { ConfigProvider, Modal } from "antd";

type Props = {
  workflow: any;
};

const UpcomingCard = ({ workflow }: Props) => {
  const [more, setMore] = useState<boolean>(false);
  const [nameModal, setNameModal] = useState<boolean>(false);

  return (
    <div className="bg-white flex flex-col gap-4 p-8 rounded-lg shadow justify-between">
      <div className="flex gap-3 w-full overflow-x-auto">
        {workflow.steps.map((step: any, idx: number) => {
          return <img className="w-10 h-10 rounded-full" src={step.logo} alt={step.type} key={idx} />;
        })}
      </div>
      <div className="text-2xl font-semibold">{workflow.name}</div>
      <div>
        <p className={`w-full text-sm font-light transition-all ${more ? "line-clamp-none" : "line-clamp-3"}`}>
          {workflow.description}
        </p>
        <button
          onClick={() => {
            setMore(!more);
          }}
          className="text-primary font-bold mt-1 text-sm hover:underline self-start"
        >
          Read {more ? "less" : "more"}
        </button>
      </div>
      <div>
        <p className="font-bold text-gray-500">Inputs:</p>
        <section className="w-full flex gap-2 flex-wrap">
          {workflow.inputs.map((input: string, idx: number) => (
            <div
              className="border-[0.5px] border-gray-200 text-sm px-1 rounded text-gray-500 font-semibold w-fit hover:bg-gray-50 transition"
              key={idx}
            >
              {input}
            </div>
          ))}
        </section>
      </div>
      <button
        className="bg-primary p-2 text-white rounded-lg font-semibold hover:bg-dark transition-all"
        onClick={() => setNameModal(true)}
      >
        View
      </button>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#d2ccdb",
            },
          },
        }}
      >
        <Modal open={nameModal} footer="" centered onCancel={() => setNameModal(false)}>
          <div className="h-full w-full bg-[#d2ccdb] bg-dot-black/[0.5] relative flex">
            {/* Radial gradient for the container to give a faded look */}
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-[#d2ccdb] [mask-image:radial-gradient(ellipse_at_center,transparent_100%,black)]"></div>
            <img src={workflow.view} alt={workflow.name} />
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default UpcomingCard;
