import { setStateType } from "../../../utils/constants";
import { useEffect, useMemo, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration, ResponseConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import ArrayField from "./ArrayField";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import { useConnections } from "@/contexts/ConnectionContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
  responseConfiguration: ResponseConfiguration[];
};

const SendingModal = ({ action, modal, setModal, payloadConfiguration, variables, responseConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const {
    updateResponseConfig,
    updatePayloadConfig,
    getCampaigns,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
  } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure.filter((field) => field.type === "dynamicDropdown").map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
        })
        .catch((error) => {
          console.log(error);
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updatedResponses = updateResponseConfig(responses, action.id);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
    // setModal(false);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "array":
        return (
          <ArrayField
            small
            arr={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString.length > 0
                ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                : [""]
            }
            setContent={(idx: number, content: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: [content],
                  type: field.type,
                });
              } else {
                temp[index].inputString[idx] = content;
              }
              setPayloads(temp);
            }}
            add={() => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString.push("");
              setPayloads(temp);
            }}
            description={field.description}
          />
        );
      case "dropdown":
        return (
          <Select
            showSearch
            defaultValue={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
            optionFilterProp="label"
            options={field.values?.map((value: any) => ({
              label: value.name,
              value: value.name,
            }))}
          />
        );
      case "dynamicDropdown":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId] ?? true}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={(campaigns[field.valuesId] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "radio":
        return (
          <Switch
            className="w-fit"
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray": {
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex flex-col gap-2 justify-between">
                    <div className="flex flex-col gap-2" key={idx}>
                      <div className="flex flex-row gap-2 items-center justify-between">
                        <input
                          type="text"
                          className="w-full rounded-lg"
                          placeholder="Enter the name"
                          value={input.name}
                          onChange={(e) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = e.target.value;
                            setPayloads(temp);
                          }}
                        />
                        <button
                          className="hover:bg-primary/20 h-fit w-fit p-2 rounded-md text-primary border-2 bg-primary/10 transition-colors duration-300 ease-in-out"
                          onClick={() => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString.splice(idx, 1);
                            setUpdateField((prev) => prev + 1);
                            setPayloads(temp);
                          }}
                        >
                          <MdDeleteOutline size={25} />
                        </button>
                      </div>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ].tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                  </div>
                );
              })}
            <div className="flex flex-row gap-2">
              <button
                className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-gray-400 w-fit px-3 py-2 text-md"
                onClick={() => {
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                  temp[index].inputString.push({
                    name: "",
                    value: "",
                    type: "json_array",
                  });
                  setPayloads(temp);
                }}
              >
                <FaPlus />
                Add key-value pair
              </button>
            </div>
          </div>
        );
      }
      default:
        return (
          <TiptapField
            content={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""}
            setContent={(content: any, text: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  tiptapJson: content,
                  inputString: text,
                  type: field.type,
                });
              } else {
                temp[index].tiptapJson = content;
                temp[index].inputString = text;
              }
              setPayloads(temp);
            }}
            refresh={updateField}
            placeholder={field.name}
            variables={variables}
          />
        );
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 24,
            },
          },
        }}
      >
        <Drawer
          open={modal}
          onClose={() => setModal(false)}
          width="30%"
          className='!font-["Inter"] !p-0'
          footer={
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <a href="https://app.floqer.com/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </a>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          }
          title={
            <div className="flex items-center gap-2">
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          }
        >
          <div className="bg-white gap-10 flex flex-col overflow-x-hidden">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {fieldSwitchCase(field)}
                </div>
              );
            })}
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          </div>
        </Drawer>
      </ConfigProvider>
    </>
  );
};

export default SendingModal;
