import { Collapse, ConfigProvider } from "antd";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
type Props = {
  variables: any[];
  addVariable: (variable: any, actionName: string) => void;
};

const Variables = ({ variables, addVariable }: Props) => {
  const variableItems = variables.map((variable: any, idx: number) => {
    return {
      key: idx.toString(),
      label: (
        <div className="flex gap-2">
          <img src={variable.logo} alt={variable.name} className="w-6 h-6" />
          <p className="font-medium">{variable.name}</p>
        </div>
      ),
      children: (
        <div className="flex flex-wrap gap-2">
          {variable.variables.map((value: any, index: number) => (
            <button
              key={index}
              className={`px-2 py-1 flex items-center gap-2 rounded-lg ${
                variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"
              }`}
              onClick={() => {
                addVariable(value, variable.name);
              }}
            >
              <img src={variable.type === "input" ? YellowVarLogo : BlueVarLogo} alt={value.name} className="w-4 h-4" />
              {value.name}
            </button>
          ))}
        </div>
      ),
    };
  });
  const items1 = [
    {
      key: "1",
      label: (
        <div className="">
          <p className="font-semibold tracking-primary">DATA VALUES</p>
          <p className="text-sm font-light">
            Select attributes collected from previous steps to use them in your prompt.
          </p>
        </div>
      ),
      children: (
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                headerPadding: "20px 0px 15px 0px !important",
                contentPadding: "0px !important",
              },
            },
          }}
        >
          <Collapse items={variableItems} expandIconPosition="end" ghost />
        </ConfigProvider>
      ),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: "0px !important",
            contentPadding: "0px !important",
          },
        },
      }}
    >
      <Collapse rootClassName='!font-["Inter"]' destroyInactivePanel ghost items={items1} expandIconPosition="end" />
    </ConfigProvider>
  );
};

export default Variables;
