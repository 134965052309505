import { WKF_LIST_SORT_PARAM } from "@/utils/interfaces";
import React from "react";

import { RiExpandUpDownLine } from "react-icons/ri";

interface Props {
  upWard: boolean;
  workflows: any[];
  setUpWard: React.Dispatch<React.SetStateAction<boolean>>;
  sortGeneral: (field: WKF_LIST_SORT_PARAM, upWard: boolean, wkflows: any[]) => void;
  header: number;
}

const SortButton = ({ upWard, workflows, setUpWard, sortGeneral, header }: Props) => {
  return (
    <button
      className=" bg-transparent transition"
      onClick={() => {
        const toSend = !upWard;
        setUpWard(!upWard);
        sortGeneral(header === 0 ? WKF_LIST_SORT_PARAM.name : WKF_LIST_SORT_PARAM.date, toSend, workflows);
      }}
    >
      {upWard ? (
        <span className="flex items-center gap-x-2">
          <RiExpandUpDownLine className="h-6 w-6" />
        </span>
      ) : (
        <span className="flex items-center gap-x-2">
          <RiExpandUpDownLine className="h-6 w-6" />
        </span>
      )}
    </button>
  );
};

export default SortButton;
