import { Drawer } from "antd";
import { useState } from "react";
import { Action, ResponseConfiguration } from "../../../utils/interfaces";
import { setStateType } from "../../../utils/constants";
import { useBuilder } from "@/contexts/BuilderContext";
import { useParams } from "react-router-dom";
// import { MdDeleteForever } from "react-icons/md";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  responseConfiguration: ResponseConfiguration[];
};

const InputModal = ({ action, modal, setModal, responseConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(responseConfiguration.length > 0 ? responseConfiguration.length : 1);
  const [responses, setResponses] = useState<ResponseConfiguration[]>(
    responseConfiguration.length > 0
      ? responseConfiguration
      : [
          {
            name: "",
            description: "",
          },
        ]
  );
  const { updateResponseConfig, saveDraftWorkflow, workflow } = useBuilder();
  const { id } = useParams();

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updateResponseConfig(responses, action.id);
    setResponses(updated);
    await saveDraftWorkflow(id || "", workflow);
    setLoading(false);
  };

  return (
    <Drawer
      open={modal}
      onClose={() => setModal(false)}
      width="30%"
      title={
        <div className="flex items-center gap-2">
          <img src={action.logo} alt={action.name} className="w-6 h-6" />
          {action.name}
        </div>
      }
      footer={
        <button
          className={`w-full text-white rounded-lg p-2 mt-auto ${
            loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
          }`}
          onClick={saveOutputStructure}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      }
    >
      <div className="h-full bg-white gap-10 flex flex-col">
        {action.payloadStructure.map((field, idx) => {
          return (
            <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
              <div>
                <p className="tracking-primary font-semibold">{field.name.toUpperCase()}</p>
                <p className="text-sm font-light">{field.description}</p>
              </div>
              <div className="flex flex-col w-full justify-center items-start gap-4">
                {Array(count)
                  .fill(0)
                  .map((_item, index) => {
                    return (
                      <div className="w-full" key={index}>
                        <p>Input {index + 1}</p>
                        <div className=" flex justify-between">
                          <input
                            className="w-[47%] rounded-lg"
                            type="text"
                            value={responses[index].name}
                            onChange={(e) => {
                              const temp = [...responses];
                              temp[index].name = e.target.value;
                              setResponses(temp);
                            }}
                            placeholder="Name of the Field"
                          />
                          <input
                            className="w-[47%] rounded-lg"
                            type="text"
                            placeholder="Description of the Field"
                            value={responses[index].description}
                            onChange={(e) => {
                              const temp = [...responses];
                              temp[index].description = e.target.value;
                              setResponses(temp);
                            }}
                          />
                          {/* <button
                            onClick={() => {
                              const temp = [...responses];
                              temp.splice(index, 1);
                              setResponses(temp);
                              setCount(count - 1);
                            }}
                            className="text-red-500 text-2xl hover:text-red-700"
                            title="Delete Field"
                          >
                            <MdDeleteForever />
                          </button> */}
                        </div>
                      </div>
                    );
                  })}
                <button
                  className="border border-black rounded-lg p-2"
                  onClick={() => {
                    const temp = [...responses];
                    temp.push({
                      name: "",
                      description: "",
                    });
                    setResponses(temp);
                    setCount(count + 1);
                  }}
                >
                  + Add input
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

export default InputModal;
