import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "../ui/Command";
import { MultiSelectValues } from "../filter.schema";
import { formatNumberWithComma } from "../utils";
import { LuCheck } from "react-icons/lu";

export function SearchFilterValuePreviewSelectNumber({
  value,
  availableValues,
}: {
  value: number;
  availableValues: MultiSelectValues;
}) {
  const name = availableValues.find((item) => item.id === value.toString())?.name;
  return <p className="text-sm">{name ? name : formatNumberWithComma(value)}</p>;
}

export function SearchFilterFormSelectNumber({
  value,
  onChangeValue,
  availableValues,
}: {
  onChangeValue: (value: number) => void;
  availableValues: MultiSelectValues;
  value: string;
}) {
  const onChangeCondition = (newValue: string) => {
    if (newValue == value) return;
    onChangeValue(+newValue);
  };
  return (
    <Command>
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {availableValues.map((item) => (
            <CommandItem key={item.id} onSelect={() => onChangeCondition(item.id)} disabled={value == item.id}>
              {value == item.id && <LuCheck className="h-4 w-4 mr-2" />}
              {value != item.id && <div className="h-4 w-4 mr-2" />}
              <span>{item.name}</span>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
