import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import Icon from "../../../assets/SVGs/newWorkflowLibs.svg";
import { Tooltip } from "antd";

const WorkflowLibs = () => {
  const location = useLocation();
  const isEmailsPage = location.pathname === "/workflowLibs";
  const { collapsed } = useUser();

  if (collapsed) {
    return (
      <Tooltip
        title="Workflow Libraries"
        placement="right"
        overlayClassName="border border-gray-200 rounded-sm font-inter"
        overlayInnerStyle={{
          fontWeight: 600,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          width: "fit-content",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontFamily: "Inter",
        }}
      >
        <Link
          className={`text-[#2a2a2a] rounded-md h-12 w-12 font-medium flex justify-center items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
            isEmailsPage && "bg-[#efe5ff]" // Apply background color if on "leads" page
          }`}
          to="/workflowLibs"
          // title="Worklow Libraries"
        >
          <img src={Icon} alt="Libs" />
        </Link>
      </Tooltip>
    );
  } else {
    return (
      <Link
        className={`text-[#2a2a2a] rounded-md w-[90%] h-[7%] font-medium flex justify-start items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
          isEmailsPage && "bg-[#efe5ff]" // Apply background color if on "leads" page
        }`}
        to="/workflowLibs"
        // title="Worklow Libraries"
      >
        <img src={Icon} alt="Libs" width={26} />
        <span className="lg:ml-1 sm:ml-0 font-semibold text-[18px]">Workflow Library</span>
      </Link>
    );
  }
};

export default WorkflowLibs;
