const DotsBg = ({ children }: any) => {
  return (
    <div className="h-full w-full bg-[#FBF8FF] bg-dot-black/[0.5] relative flex">
      {/* Radial gradient for the container to give a faded look */}
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-[#FBF8FF] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      {children}
    </div>
  );
};

export default DotsBg;
