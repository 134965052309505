import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/Form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { formatNumberWithComma } from "../utils";

const formSchema = z.object({
  numberField: z.coerce.number().min(1, "Value must be greater than 0"),
});

export function SearchFilterValuePreviewNumber({ value }: { value: number }) {
  return <p className="text-sm">{formatNumberWithComma(value)}</p>;
}

export function SearchFilterFormNumber({
  value,
  onChangeValue,
  onInteractOutside,
  closePopover,
}: {
  id: string;
  value: number;
  onChangeValue: (_value: number) => void;
  onInteractOutside: boolean;
  closePopover: () => void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      numberField: value || 5,
    },
  });

  useEffect(() => {
    if (onInteractOutside === true) {
      onSubmit(form.getValues());
    }
  }, [onInteractOutside]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const newValue = values.numberField;
    if (newValue === value) {
      closePopover();
      return;
    }
    onChangeValue(newValue);
  }

  return (
    <>
      <Form {...form}>
        <form className="grid  max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="numberField"
            render={() => (
              <FormItem>
                <FormControl>
                  <input
                    autoFocus={true}
                    type="number"
                    className="flex h-10 w-full rounded-md border border-input bg-popover px-3 py-2 text-sm ring-offset-popover file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <button className="btn py-2" type="submit">
            Save
          </button>
        </form>
      </Form>
    </>
  );
}
