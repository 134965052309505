import SidePane from "./SidePane";
import ChatComponent from "./ChatComponent";
import { useChat } from "@/contexts/ChatContext";
import { useEffect, useState } from "react";
import Loader from "@/Components/Loader";
import { LOADER_TYPES } from "@/utils/constants";

interface Props {
  userWorkflowId: string;
}

const TableChat: React.FC<Props> = ({ userWorkflowId }) => {
  const { selectedChat, getChatIds, currChatDataRef, setSelectedChat } = useChat();
  const [wrapperLoad, setWrapperLoad] = useState(false);
  // const [wrapperErr, setWrapperErr] = useState<string | null>(null);

  useEffect(() => {
    if (!currChatDataRef.current || Object.keys(currChatDataRef.current).length === 0) return;
    if (selectedChat.id === "") {
      const firstKey = Object.keys(currChatDataRef.current)[0];
      setSelectedChat({
        id: firstKey,
        name: currChatDataRef.current?.[firstKey]?.chatName || "",
      });
    }
  }, [currChatDataRef.current]);

  useEffect(() => {
    setWrapperLoad(true);
    getChatIds(userWorkflowId)
      .then(() => {})
      .catch((err) => console.error(err))
      .finally(() => setWrapperLoad(false));
  }, []);

  if (wrapperLoad) {
    return (
      <div className="fixed h-full w-full flex items-center justify-center">
        <Loader loaderType={LOADER_TYPES.loading} payload={"chats"} />
      </div>
    );
  } else if (Object.keys(currChatDataRef.current).length === 0) {
    return (
      <section className="w-full h-[88vh] flex bg-white flex-col justify-center items-center font-semibold text-gray-500 gap-2">
        <header className="text-5xl">No chats here.</header>
        <span className="text-xl">Please go to the table tab and create a new chat.</span>
        <span className="">You can select the rows you want to chat with</span>
        <span className="">
          or just click on <span className="px-2 py-1 bg-gray-200 rounded-lg">Chat with table</span> to chat with the
          entire table.
        </span>
        <span>Give it a go!</span>
      </section>
    );
  }

  return (
    <section className="w-full h-[88vh] flex bg-white">
      <SidePane />
      <ChatComponent userWorkflowId={userWorkflowId} />
    </section>
  );
};

export default TableChat;
