import { IoMdClose } from "react-icons/io";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const CloseButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <button
      className="flex items-center justify-center p-4 rounded-full bg-transparent hover:bg-gray-200 transition
      absolute top-4 right-4 z-50
    "
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <IoMdClose />
    </button>
  );
};

export default CloseButton;
