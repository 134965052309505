import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
// import { FcGoogle } from "react-icons/fc";
import Loader from "../../Components/Loader";
import ResetPassword from "../../Components/Auth/ResetPassword";
import {
  // GOOGLE_AUTH_CLIENT_ID,
  // GOOGLE_AUTH_REDIRECT_URI,
  // GOOGLE_AUTH_SCOPE,
  LOADER_TYPES,
} from "../../utils/constants";
import { useUser } from "../../contexts/UserContext";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassowrd] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean | null>(null);
  const [vis, setVis] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const navigate = useNavigate();
  const { checkLoginMethodAndMember, loginWithEmailAndPass } = useUser();

  useEffect(() => {
    setError(null);
  }, [email, password, confirmPassword]);

  const Icon = vis ? AiOutlineEyeInvisible : AiOutlineEye;

  const handleLoginCheck = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const { signInMethods, member } = await checkLoginMethodAndMember(email);
    if (!member) {
      setError(
        "No account found for your organization with this email, please contact us or the appropriate team lead in your organization or Sign Up for your organization as a lead."
      );
    } else if (signInMethods.length === 0) {
      setIsNew(true);
      setError(null);
    } else {
      setIsNew(false);
      setError(null);
    }
    setLoading(false);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await loginWithEmailAndPass(email, password, "old");
      navigate("/");
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    }
    setLoading(false);
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await loginWithEmailAndPass(email, password, "new");
      navigate("/");
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    }
    setLoading(false);
  };

  // const GoogleLogin = async () => {
  //   setLoading(true);
  //   try {
  //     // const provider = new GoogleAuthProvider();
  //     // await signInWithPopup(auth, provider);
  //     const clientId = encodeURIComponent(GOOGLE_AUTH_CLIENT_ID);
  //     const redirect_uri = encodeURIComponent(GOOGLE_AUTH_REDIRECT_URI);
  //     const nonce = Math.random().toString(36).substring(2, 15);
  //     const responseType = encodeURIComponent("id_token");
  //     const scope = encodeURIComponent(GOOGLE_AUTH_SCOPE);
  //     const prompt = encodeURIComponent("consent");
  //     const state = encodeURIComponent("djwdjd");

  //     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=${responseType}&scope=${scope}&nonce=${nonce}&prompt=${prompt}&state=${state}`;
  //     window.location.href = authUrl;
  //     setLoading(false);
  //     // navigate("/");
  //   } catch (error: any) {
  //     console.error(error.message);
  //     setError(error.message);
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="h-screen flex justify-center items-center !cursor-default">
      {reset && <ResetPassword setReset={setReset} email={email} />}
      <div className="flex flex-col justify-center items-center border border-[#ccc] shadow-md rounded-2xl p-10 gap-4">
        <h2 className="text-3xl font-bold">
          Login with your <span className="text-primary">work email</span>
        </h2>
        <p>Use your work email to log in for your team workspace</p>
        {error && <p className="text-xs bg-yellow-300 p-1 flex w-[400px]">{error}</p>}
        <form
          className="flex flex-col justify-start items-start gap-4 w-full"
          onSubmit={isNew === null ? handleLoginCheck : isNew === true ? handleSignUp : handleLogin}
        >
          <div className="w-full">
            <label>Email:</label>
            <input
              className={"w-full rounded-md p-2" + (isNew !== null ? " bg-gray-200" : "")}
              type="email"
              value={email}
              autoComplete="off"
              readOnly={isNew !== null}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              placeholder="yourname@company.com"
            />
          </div>
          {isNew !== null && (
            <div className="w-full">
              <label>{isNew === true && "New "}Password:</label>
              <section className=" flex items-center justify-between gap-2">
                <input
                  className="rounded-md p-2 flex-1"
                  type={vis ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="off"
                  placeholder="Enter your password"
                  ref={(input) => input && input.focus()}
                />
                <button
                  className="flex items-center justify-center p-2 rounded-full bg-transparent hover:bg-gray-200 transition cursor-pointer"
                  onClick={() => setVis(!vis)}
                  type="button"
                >
                  <Icon className="text-gray-600" size={"28px"} />
                </button>
              </section>
            </div>
          )}
          {isNew === true && (
            <div className="w-full">
              <label>Confirm Password:</label>
              <input
                className="w-full rounded-md p-2"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassowrd(e.target.value)}
                required
                autoComplete="off"
                placeholder="Enter your password"
              />
            </div>
          )}
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader payload="Loading" loaderType={LOADER_TYPES.fetching} />
            </div>
          ) : (
            <>
              <button className="w-full p-3 bg-primary hover:bg-dark text-white rounded-lg" type="submit">
                {isNew === null ? "Next" : isNew === true ? "Sign Up" : "Login"}
              </button>
              {isNew === false && (
                <button
                  className="px-4 py-2 bg-white rounded-lg hover:bg-gray-200 transition mx-auto"
                  onClick={() => setReset(true)}
                  type="button"
                >
                  Reset <span className="text-primary">password</span>
                </button>
              )}
            </>
          )}
          {/* {!loading && isNew === null && (
            <div className="w-full flex flex-col gap-4">
              <div className="divider">
                <hr />
                <span>OR</span>
                <hr />
              </div>
              <button
                className="flex gap-2 justify-center items-center border border-[#dee4ed] rounded w-full px-4 py-2 hover:border-primary"
                onClick={GoogleLogin}
              >
                <FcGoogle />{" "}
                <span style={{ marginLeft: "8px" }}>Log in with Google</span>
              </button>
            </div>
          )} */}
          {/* <p className="w-full text-center flex flex-col">
            Not a part of any organization?{" "}
            <span>
              Sign up with your organization&nbsp;
              <Link to="/org" className="text-[#5452f6] hover:underline">
                <span>here</span>
              </Link>
            </span>
          </p> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
