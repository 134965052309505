import { useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { LOADER_TYPES } from "../../../utils/constants";
import Loader from "../../Loader";
import Members from "./Members";
import Profile from "./Profile";

const Wrapper = () => {
  const [idx, setIdx] = useState(0);
  const [isLead, setIsLead] = useState(false);
  const [orgId, setOrgId] = useState("");
  const { checkIsLead } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    checkIsLead().then((res) => {
      setIsLead(res.isLead);
      setIdx(res.isLead ? 0 : 1);
      setOrgId(res.orgId);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isLead) setIdx(0);
    else setIdx(1);
  }, [isLead]);

  if (loading) return <Loader payload={"app settings"} loaderType={LOADER_TYPES.loading} />;
  return (
    <div className="h-full border-[1px] border-[#E1E1E1] rounded-lg w-full bg-white">
      <div className="h-16 border-b-[1px] border-[#E1E1E1] flex flex-row justify-start items-center gap-16 w-full text-lg font-semibold bg-white pl-6 rounded-t-lg">
        {isLead && (
          <div
            className={`h-full flex flex-row items-center justify-center p-4 hover:bg-superchat-blue ${
              idx === 0 ? "border-b-2 border-primary text-primary" : ""
            } cursor-pointer`}
            onClick={() => setIdx(0)}
          >
            Members
          </div>
        )}
        <div
          className={`h-full flex flex-row items-center justify-center p-4 hover:bg-superchat-blue ${
            idx === 1 ? "border-b-2 border-primary text-primary" : ""
          } cursor-pointer`}
          onClick={() => setIdx(1)}
        >
          Profile
        </div>
        {/* {isLead && (
          <div
            className={`h-full flex flex-row items-center justify-center p-4 hover:bg-superchat-blue ${
              idx === 2 ? "border-b-2 border-primary text-primary" : ""
            } cursor-pointer`}
            onClick={() => setIdx(2)}
          >
            Company
          </div>
        )} */}
      </div>
      {idx === 0 && <Members orgId={orgId} />}
      {idx === 1 && <Profile />}
      {/* {idx === 2 && <Company orgId={orgId} />} */}
    </div>
  );
};

export default Wrapper;
