import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";
import { Dropdown, Tooltip } from "antd";
import { MdOutlineDriveFileMove, MdOutlineDriveFileMoveRtl } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/utils/cn";
import { WKF_LIST_SORT_PARAM } from "@/utils/interfaces";
import { DISPLAY_DATE_TIME_FORMAT, isV2 as isV2ENV, LOADER_TYPES } from "../../utils/constants";
import WorkflowIcon from "../../assets/SVGs/newWorkflowBlue.svg";
import WorkflowFolderIcon from "../../assets/SVGs/WorkflowFolderIcon.svg";
import RenameIcon from "../../assets/SVGs/RenameIcon.svg";
import DeleteIcon from "../../assets/SVGs/DeleteIcon.svg";
import CreateIcon from "../../assets/SVGs/CreateIcon.svg";
import CreateIconBlack from "../../assets/SVGs/CreateIconBlack.svg";
import SearchIcon from "../../assets/SVGs/SearchIcon.svg";
import Loader from "../Loader";
import CreateWorkflowUI from "./create/CreateWorkflow";
import DuplicateWorkflow from "./DuplicateWorkflow";
import CreateWorkflowFolder from "./CreateWorkflowFolder";
import MoveWorkflow from "./MoveWorkflow";
import DeleteWorkflow from "./DeleteWorkflow";
import SortButton from "./SortButton";
import DeleteWorkflowFolder from "./DeleteWorkflowFolder";
import { getUserWorkflows, renameWorkflow, renameWorkflowFolder } from "@/utils/apis";

const Workflows = () => {
  const [create, setCreate] = useState("");
  const [createFolder, setCreateFolder] = useState(false);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [workflowFolders, setWorkflowFolders] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [upWard, setUpWard] = useState(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState<any>(null);
  const [moveWorkflow, setMoveWorkflow] = useState<any>(null);
  const [oldFolderId, setOldFolderId] = useState<any>(null);
  const [isV2, setIsV2] = useState(false);
  const [duplicateWorkflow, setDuplicateWorkflow] = useState<any>(null);
  const [toRenameWorkflow, setToRenameWorkflow] = useState<any>(null);
  const [newWorkflowName, setNewWorkflowName] = useState<any>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [hoveredFolder, setHoveredFolder] = useState<number | null>(null);
  const [hoveredFolderRow, setHoveredFolderRow] = useState<number | null>(null);
  const [deleted, setDeleted] = useState(false);
  const [moved, setMoved] = useState(false);
  const [copied, setCopied] = useState(false);
  const [renamed, setRenamed] = useState(false);
  const [clickedRow, setClickedRow] = useState<number | null>(null);
  const [toRenameFolder, setToRenameFolder] = useState<any>(null);
  const [clickedFolder, setClickedFolder] = useState<number | null>(null);
  const [clickedFolderRow, setClickedFolderRow] = useState<number | null>(null);
  const [deleteWorkflowFolder, setDeleteWorkflowFolder] = useState<any>(null);
  const [activeIndices, setActiveIndices] = useState<number[]>([]); // this is for accordian
  const [search, setSearch] = useState<string>("");
  const inFolderWorkflows: string[] = [];
  const tableHeaders = ["Title", "Last updated", "Triggers/Inputs", ""];

  const sortGeneral = useCallback(
    (sortParam: WKF_LIST_SORT_PARAM, isUpwards: boolean, wkflows: any[]) => {
      const arr = [...wkflows];
      arr.sort((a: any, b: any) => {
        if (sortParam === WKF_LIST_SORT_PARAM.date) {
          const aDate = a?.createdAt || "";
          const bDate = b?.createdAt || "";
          return isUpwards ? aDate.localeCompare(bDate) : bDate.localeCompare(aDate);
        } else if (sortParam === WKF_LIST_SORT_PARAM.name) {
          const aName = a?.name || a?.publishedWorkflowConfig.name || "";
          const bName = b?.name || b?.publishedWorkflowConfig.name || "";
          return isUpwards ? aName.localeCompare(bName) : bName.localeCompare(aName);
        }
      });
      const folderArr = [...workflowFolders];
      folderArr.sort((a: any, b: any) => {
        if (sortParam === WKF_LIST_SORT_PARAM.date) {
          const aDate = a?.createdAt || "";
          const bDate = b?.createdAt || "";
          return isUpwards ? aDate.localeCompare(bDate) : bDate.localeCompare(aDate);
        } else if (sortParam === WKF_LIST_SORT_PARAM.name) {
          const aName = a?.name || "";
          const bName = b?.name || "";
          return isUpwards ? aName.localeCompare(bName) : bName.localeCompare(aName);
        }
      });
      setWorkflows(() => [...arr]);
      setWorkflowFolders(() => [...folderArr]);
    },
    [workflows, workflowFolders]
  );

  useEffect(() => {
    const fetchWorkflows = async () => {
      setLoading(true);
      const resp = await getUserWorkflows(true);
      const firebase = resp.workflows || [];
      const supabase = resp.workflowsV2 || [];
      const workflowFolders = firebase.workflowFolders || [];
      const workflowFoldersV2 = supabase.workflowFolders || [];

      const workflows = (firebase?.workflows || []).concat(supabase?.workflows || []).map((workflow: any) => {
        if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
        else workflow.supabase = true;
        return workflow;
      });
      sortGeneral(WKF_LIST_SORT_PARAM.date, upWard, workflows);
      setWorkflowFolders([...workflowFolders, ...workflowFoldersV2]);
      setLoading(false);
    };
    fetchWorkflows();
  }, []);

  const TriggerBadge = (actions: any[]): React.JSX.Element => {
    const action = (actions ?? []).find((action) => action.id === "id1");

    const ACTION_MAP: Record<string, { text: string; color: string; caption: string }> = {
      UPLOAD_PROSPECT_CSV_MANUAL: {
        text: "CSV",
        color: "bg-[#DAD8FF]",
        caption: "Inputs",
      },
      ENGAGEBAY_CONTACT_ADDED: {
        text: "New contact added to Engagebay",
        color: "bg-[#FFEBD8]",
        caption: "Triggers",
      },
      HUBSPOT_CONTACT_ADDED: {
        text: "New contact added to Hubspot",
        color: "bg-[#FFEBD8]",
        caption: "Triggers",
      },
      PHANTOMBUSTER_AGENT: {
        text: "PhantomBuster",
        color: "bg-[#DAD8FF]",
        caption: "Input",
      },
      SALESFORCE_PULL_DATA: {
        text: "Salesforce",
        color: "bg-[#DAD8FF]",
        caption: "Input",
      },
      WEBHOOK_AS_INPUT: {
        text: "Webhook",
        color: "bg-[#DAD8FF]",
        caption: "Input",
      },
      SALES_NAVIGATOR_IMPORT: {
        text: "Sales Navigator",
        color: "bg-[#DAD8FF]",
        caption: "Input",
      },
      FLOQER_CREATE_COMPANY_LIST: {
        text: "Company List",
        color: "bg-[#DAD8FF]",
        caption: "Input",
      },
    };

    const badge = (text: string, color: string, caption: string) => (
      <div className={`flex items-center px-2 py-1 w-fit ${color}`}>
        <span className="text-xs font-semibold">{caption}:</span>
        <span className="ml-1 text-xs font-normal text-gray-600">{text}</span>
      </div>
    );
    if (!action) return badge("No triggers", "bg-gray-300", "Input");
    const { text, color, caption } = ACTION_MAP[action.actionName] ?? {
      text: "Unknown",
      color: "bg-gray-300",
    };
    return badge(text, color, caption);
  };

  const handleMoveClick = (workflow: any, workflowFolderId: any) => {
    setMoveWorkflow(workflow);
    setOldFolderId(workflowFolderId);
    setMoved(true);
    setTimeout(() => setMoved(false), 2000); // Reset tooltip after 2 seconds
  };
  const handleDeleteClick = (workflow: any) => {
    setDeleteWorkflow(workflow);
    setIsV2(workflow.supabase || false);
    setDeleted(true);
    setTimeout(() => setDeleted(false), 2000); // Reset tooltip after 2 seconds
  };
  const handleDeleteFolder = (folder: any) => {
    setDeleteWorkflowFolder(folder);
    setDeleted(true);
    setTimeout(() => setDeleted(false), 2000); // Reset tooltip after 2 seconds
  };
  const handleDuplicateClick = (workflow: any) => {
    setDuplicateWorkflow(workflow);
    setIsV2(workflow.supabase || false);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset tooltip after 2 seconds
  };

  const renameWorkflowFunc = async (workflow: any, newWorkflowName: any) => {
    if (workflow.publishedWorkflowConfig.name === newWorkflowName) {
      setClickedFolderRow(null);
      setClickedRow(null);
      return;
    }
    if (!newWorkflowName.trim()) {
      return;
    }
    await renameWorkflow(workflow.id, newWorkflowName, isV2);
    // await useWorkflow().renameWorkflow(workflow.id, newWorkflowName);
    window.location.reload();
  };
  const renameFolderFunc = async (folder: any, newWorkflowName: string) => {
    if (folder.name === newWorkflowName) {
      setClickedFolder(null);
      return;
    }
    if (!newWorkflowName.trim()) {
      return;
    }
    await renameWorkflowFolder(folder.id, newWorkflowName);
    window.location.reload();
  };

  const handleRenameInline = async (workflow: any) => {
    setIsV2(workflow.supabase || false);
    await renameWorkflowFunc(toRenameWorkflow, newWorkflowName);
    // await renameWorkflow()
    // handleRename
    setClickedRow(null);
    setClickedFolderRow(null);
    setRenamed(true);
    setTimeout(() => setRenamed(false), 2000); // Reset tooltip after 2 seconds
  };
  const handleRenameFolderInline = async () => {
    await renameFolderFunc(toRenameFolder, newWorkflowName);
    // // await renameWorkflow()
    // handleRename
    setClickedFolder(null);
    setRenamed(true);
    setTimeout(() => setRenamed(false), 2000); // Reset tooltip after 2 seconds
  };

  const handleRenamed = (workflow: any) => {
    setIsV2(workflow.supabase || false);
    // setRenameWorkflow(workflow);
    setToRenameWorkflow(workflow);
    setClickedRow(hoveredRow);
    setNewWorkflowName(workflow.publishedWorkflowConfig.name);
    setRenamed(true);
    setTimeout(() => setRenamed(false), 2000); // Reset tooltip after 2 seconds
  };
  const handleRenamedInFolder = (workflow: any) => {
    // setRenameWorkflow(workflow);
    setToRenameWorkflow(workflow);
    setClickedFolderRow(hoveredFolderRow);
    setNewWorkflowName(workflow.publishedWorkflowConfig.name);

    setRenamed(true);
    setTimeout(() => setRenamed(false), 2000); // Reset tooltip after 2 seconds
  };

  const handleRenamedFolder = (folder: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setToRenameFolder(folder);
    setClickedFolder(hoveredFolder);
    setNewWorkflowName(folder.name);
    setRenamed(true);
    setTimeout(() => setRenamed(false), 2000); // Reset tooltip after 2 seconds
  };

  workflowFolders.map((folder) => {
    folder?.workflows?.map((workflow: string) => {
      inFolderWorkflows.push(workflow);
    });
  });
  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };

  if (loading)
    return (
      <div className="h-full w-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow Libraries" />
      </div>
    );
  const items = [
    {
      label: (
        <div
          className="flex gap-4 w-full items-center py-2 font-semibold text-[22px] rounded-md"
          onClick={() => setCreate("true")}
        >
          <img src={CreateIconBlack} alt="create-workflow" width={22} />
          <p> Create Workflow</p>
        </div>
      ),
      key: "create workflow",
    }, // remember to pass the key prop
    {
      label: (
        <div
          className="flex gap-4 w-full items-center py-2 font-semibold text-[22px] rounded-md"
          onClick={() => setCreateFolder(true)}
        >
          <img src={WorkflowFolderIcon} alt="workflow-folder-icon" width={22} />
          <p> Create Folder</p>
        </div>
      ),
      key: "create folder",
    },
  ];

  if (isV2ENV)
    items.push({
      label: (
        <div
          className="flex gap-4 w-full items-center py-2 font-semibold text-[22px] rounded-md"
          onClick={() => {
            setCreate("v2");
          }}
        >
          <img src={CreateIconBlack} alt="create-workflow" width={22} />
          <p> Create Workflow (Beta)</p>
        </div>
      ),
      key: "create workflow v2",
    });

  if (create !== "") {
    return <CreateWorkflowUI folders={workflowFolders} close={() => setCreate("")} v2={create === "v2"} />;
  }

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-10 p-10">
      <div className="w-full flex flex-row justify-between items-center px-4 mt-16">
        <div className="text-[2.3rem] font-umuxGrot font-semibold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-[#333333] cursor-default tracking-wider">
          Your Workflows
        </div>
      </div>
      <div className="flex flex-row justify-between w-full px-4">
        <div className="inputStyleCSS w-2/3 flex justify-between items-center px-4 border border-black rounded-md">
          <img src={SearchIcon} alt="search icon" width={20} />
          <input
            type="text"
            placeholder="Search Your Workflows"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={cn(
              "focus:!outline-none ",
              "w-full rounded-md focus:!border-none !border-none focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black"
            )}
          />
        </div>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            onClick={(e) => e.preventDefault()}
            className="flex gap-x-2 items-center bg-black hover:bg-gray-800 py-3 px-4 rounded-md font-semibold text-[22px] text-white"
          >
            <img src={CreateIcon} alt="create-workflow" width={22} color="black" /> Create
          </button>
        </Dropdown>
      </div>

      {workflows.length > 0 || workflowFolders.length > 0 ? (
        <div className="w-full h-full flex flex-row flex-wrap gap-10 p-4">
          <div className="w-full h-fit text-left table border-collapse">
            <div className="table-header-group font-semibold text-gray-700 border-b-[0.5px] border-gray-300">
              <div className="table-row text-[22px]">
                {tableHeaders.map((header, idx) => (
                  <div key={`${idx}-${header}`} className="table-cell w-fit px-6 py-3">
                    <span className="flex w-fit items-center justify-between gap-2 overflow-x-auto flex-wrap">
                      {header}
                      {header !== "" && header !== "Triggers/Inputs" && (
                        <SortButton
                          upWard={upWard}
                          workflows={workflows}
                          setUpWard={setUpWard}
                          sortGeneral={sortGeneral}
                          header={idx}
                          key={idx}
                        />
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* AntD approach, styling not working properly. */}
            {/* <Collapse items={folders} className="table-row-group bg-white text-gray-500 text-sm cursor-pointer rounded-none"/> */}

            {/* Custom approach */}
            {workflowFolders
              .filter((folder) => folder.name.toLowerCase().includes(search.toLowerCase()))
              .map((folder, folderIdx) => (
                <div
                  key={folderIdx}
                  className={`${
                    activeIndices.includes(folderIdx) ? "border-b rounded-md " : ""
                  } table-row-group w-full`}
                >
                  <div
                    className={`bg-white ${
                      activeIndices.includes(folderIdx) ? "rounded-md" : ""
                    } table-row cursor-pointer hover:bg-[#efe5ffc7] relative w-full`}
                    onClick={() => toggleAccordion(folderIdx)}
                    onMouseEnter={() => setHoveredFolder(folderIdx)}
                    onMouseLeave={() => setHoveredFolder(null)}
                  >
                    <div className="table-cell px-6 py-4 w-[40%]">
                      <div className="flex gap-x-3 h-full text-[22px] items-center font-normal w-full">
                        <img src={WorkflowFolderIcon} alt="workflow" color="blue" width={20} />
                        {clickedFolder !== folderIdx && (
                          <div className="flex">
                            {folder.name.length > 70 ? `${folder.name.slice(0, 67)}...` : folder.name}

                            <Tooltip
                              placement="bottomLeft"
                              title="Rename"
                              arrow={false}
                              overlayClassName="border border-gray-200 rounded-md"
                              overlayInnerStyle={{
                                fontWeight: 600,
                                backgroundColor: renamed ? "#6c727f" : "#f3f4f6",
                                color: renamed ? "#f3f4f6" : "#6c727f",
                                fontSize: "0.75rem",
                              }}
                            >
                              <button
                                // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                                className=" flex justify-center h-fit items-center p-2 ml-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                                onClick={(e) => handleRenamedFolder(folder, e)}
                                style={{
                                  opacity: hoveredFolder === folderIdx ? 1 : 0,
                                }}
                              >
                                <img src={RenameIcon} alt="workflow" width={16} />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {clickedFolder === folderIdx && (
                          <div className="inputStyleCSS flex items-center gap-x-4 w-full">
                            <input
                              className={cn(
                                " focus:!outline-none",
                                "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black px-0 py-1"
                              )}
                              type="text"
                              value={newWorkflowName}
                              onChange={(e) => setNewWorkflowName(e.target.value)}
                              autoFocus
                              onBlur={() => handleRenameFolderInline()}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="table-cell px-6 py-4 text-[18px] w-[20%] text-gray-500">
                      {moment.utc(folder.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
                    </div>
                    <div className="table-cell px-6 py-4 max-w-fit text-[18px] w-[20%]" />
                    <div className="table-cell px-6 py-4 text-xs w-[20%] relative">
                      {hoveredFolder === folderIdx && (
                        <div className="absolute flex items-center gap-x-4 right-4 bottom-0 top-0 mt-auto mb-auto mr-0 ml-0">
                          <Tooltip
                            placement="bottomLeft"
                            title="Delete Folder"
                            arrow={false}
                            overlayClassName="border border-gray-200 rounded-md"
                            overlayInnerStyle={{
                              fontWeight: 600,
                              backgroundColor: deleted ? "#6c727f" : "#f3f4f6",
                              color: deleted ? "#f3f4f6" : "#6c727f",
                              fontSize: "0.75rem",
                            }}
                          >
                            <button
                              // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                              className=" flex justify-center h-fit items-center p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-[#FFCECE]"
                              onClick={() => handleDeleteFolder(folder)}
                              style={{
                                opacity: hoveredFolder === folderIdx ? 1 : 0,
                              }}
                            >
                              <img src={DeleteIcon} alt="deleteFolder" width={20} />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  <AnimatePresence initial={false}>
                    {activeIndices.includes(folderIdx) &&
                      workflows
                        .filter((workflow) => folder.workflows.includes(workflow.id))
                        .filter(
                          (workflow) =>
                            workflow.name?.toLowerCase().includes(search.toLowerCase()) ||
                            workflow.publishedWorkflowConfig.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((filteredItem, ind) => (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            key={ind}
                            className="table-row bg-white border-t text-gray-500 hover:bg-[#efe5ffc7] relative py-2 w-full"
                            onMouseEnter={() => setHoveredFolderRow(ind + folderIdx * 100)}
                            onMouseLeave={() => setHoveredFolderRow(null)}
                          >
                            <div className="table-cell py-4 w-[40%]">
                              {clickedFolderRow !== ind + folderIdx * 100 && (
                                <div className="flex pl-12 items-center font-normal text-[22px]">
                                  <img src={WorkflowIcon} alt="workflow" color="blue" width={16} />
                                  <Link
                                    to={`/workflow/${filteredItem.id}?v2=${filteredItem.supabase}`}
                                    className=" pl-4"
                                  >
                                    {/* {filteredItem.name ||
                                  filteredItem.publishedWorkflowConfig.name} */}
                                    {filteredItem.name || filteredItem.publishedWorkflowConfig.name.length > 70
                                      ? `${
                                          filteredItem.name || filteredItem.publishedWorkflowConfig.name.slice(0, 67)
                                        }...`
                                      : filteredItem.name || filteredItem.publishedWorkflowConfig.name}
                                  </Link>
                                  <Tooltip
                                    placement="bottomLeft"
                                    title="Rename"
                                    arrow={false}
                                    overlayClassName="border border-gray-200 rounded-md"
                                    overlayInnerStyle={{
                                      fontWeight: 600,
                                      backgroundColor: renamed ? "#6c727f" : "#f3f4f6",
                                      color: renamed ? "#f3f4f6" : "#6c727f",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    <button
                                      // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                                      className=" flex justify-center h-fit items-center p-2 ml-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                                      onClick={() => handleRenamedInFolder(filteredItem)}
                                      style={{
                                        opacity: hoveredFolderRow === ind + folderIdx * 100 ? 1 : 0,
                                      }}
                                    >
                                      <img src={RenameIcon} alt="workflow" width={16} />
                                    </button>
                                  </Tooltip>
                                </div>
                              )}
                              {clickedFolderRow === ind + folderIdx * 100 && (
                                <div className="inputStyleCSS flex items-center gap-x-4 pl-12">
                                  <img src={WorkflowIcon} alt="workflow" color="blue" width={16} />
                                  <input
                                    className={cn(
                                      " focus:!outline-none",
                                      "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black px-0 py-1"
                                    )}
                                    type="text"
                                    value={newWorkflowName}
                                    onChange={(e) => setNewWorkflowName(e.target.value)}
                                    autoFocus
                                    onBlur={() => handleRenameInline(filteredItem)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="table-cell px-6 py-6 text-[18px] w-[20%]">
                              {moment.utc(filteredItem.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
                            </div>
                            <div className="table-cell px-6 py-6 max-w-fit text-[18px] w-[20%]">
                              {TriggerBadge(filteredItem.actions)}
                            </div>
                            <div className="w-[20%]"></div>

                            {hoveredFolderRow === ind + folderIdx * 100 && (
                              <div className="absolute flex items-center gap-x-4 right-4 bottom-0 top-0 mt-auto mb-auto mr-0 ml-0">
                                <Tooltip
                                  placement="bottomLeft"
                                  title="Delete Workflow"
                                  arrow={false}
                                  overlayClassName="border border-gray-200 rounded-md"
                                  overlayInnerStyle={{
                                    fontWeight: 600,
                                    backgroundColor: deleted ? "#6c727f" : "#f3f4f6",
                                    color: deleted ? "#f3f4f6" : "#6c727f",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  <button
                                    // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                                    className=" flex justify-center h-fit items-center p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-[#FFCECE]"
                                    onClick={() => handleDeleteClick(filteredItem)}
                                    style={{
                                      opacity: hoveredFolderRow === ind + folderIdx * 100 ? 1 : 0,
                                    }}
                                  >
                                    <img src={DeleteIcon} alt="deleteWorkflow" width={20} />
                                  </button>
                                </Tooltip>
                                <Tooltip
                                  placement="bottomLeft"
                                  title="Move Workflow"
                                  arrow={false}
                                  overlayClassName="border border-gray-200 rounded-md"
                                  overlayInnerStyle={{
                                    fontWeight: 600,
                                    backgroundColor: moved ? "#6c727f" : "#f3f4f6",
                                    color: moved ? "#f3f4f6" : "#6c727f",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  <button
                                    // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                                    className=" flex justify-center h-fit items-center p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                                    onClick={() => handleMoveClick(filteredItem, folder.id)}
                                    style={{
                                      opacity: hoveredFolderRow === ind + folderIdx * 100 ? 1 : 0,
                                    }}
                                  >
                                    <MdOutlineDriveFileMove size={26} className="text-gray-600" />
                                  </button>
                                </Tooltip>

                                <Tooltip
                                  placement="bottomLeft"
                                  title="Duplicate"
                                  arrow={false}
                                  overlayClassName="border border-gray-200 rounded-md"
                                  overlayInnerStyle={{
                                    fontWeight: 600,
                                    backgroundColor: copied ? "#6c727f" : "#f3f4f6",
                                    color: copied ? "#f3f4f6" : "#6c727f",
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  <button
                                    // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                                    className=" flex justify-center h-fit items-center p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                                    onClick={() => handleDuplicateClick(filteredItem)}
                                    style={{
                                      opacity: hoveredFolderRow === ind + folderIdx * 100 ? 1 : 0,
                                    }}
                                  >
                                    <FaRegCopy size={22} className="text-gray-600" />
                                  </button>
                                </Tooltip>
                              </div>
                            )}
                          </motion.div>
                        ))}
                  </AnimatePresence>
                </div>
              ))}

            <div className="table-row-group w-full">
              {workflows
                .filter((workflow) => !inFolderWorkflows.includes(workflow.id))
                .filter(
                  (workflow) =>
                    workflow.name?.toLowerCase().includes(search.toLowerCase()) ||
                    workflow.publishedWorkflowConfig.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((row, index) => (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ delay: index * 0.04 }}
                    key={index}
                    className="table-row bg-white text-gray-500 hover:bg-[#efe5ffc7] relative"
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <div className="table-cell max-w-fit px-6 py-4 w-[40%]">
                      {clickedRow !== index && (
                        <div className="flex gap-x-4 text-[22px] font-normal items-center">
                          <img src={WorkflowIcon} alt="Workflow" color="blue" width={16} />
                          <Link to={`/workflow/${row.id}?v2=${row.supabase}`} className="">
                            {/* {row.name || row.publishedWorkflowConfig.name} */}
                            {row.name || row.publishedWorkflowConfig.name.length > 70
                              ? `${row.name || row.publishedWorkflowConfig.name.slice(0, 67)}...`
                              : row.name || row.publishedWorkflowConfig.name}
                          </Link>
                          <Tooltip
                            placement="bottomLeft"
                            title="Rename"
                            arrow={false}
                            overlayClassName="border border-gray-200 rounded-md"
                            overlayInnerStyle={{
                              fontWeight: 600,
                              backgroundColor: renamed ? "#6c727f" : "#f3f4f6",
                              color: renamed ? "#f3f4f6" : "#6c727f",
                              fontSize: "0.75rem",
                            }}
                          >
                            <button
                              // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                              className=" flex justify-center h-fit items-center p-2 ml-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                              onClick={() => handleRenamed(row)}
                              style={{ opacity: hoveredRow === index ? 1 : 0 }}
                            >
                              <img src={RenameIcon} alt="workflow" width={16} />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                      {clickedRow === index && (
                        <div className="inputStyleCSS flex items-center gap-x-4">
                          <img src={WorkflowIcon} alt="workflow" color="blue" width={16} />
                          <input
                            className={cn(
                              "focus:!outline-none",
                              "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black px-0 py-1"
                            )}
                            type="text"
                            autoFocus
                            value={newWorkflowName}
                            onChange={(e) => setNewWorkflowName(e.target.value)}
                            onBlur={() => handleRenameInline(row)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="table-cell px-6 py-4 max-w-fit text-[18px] w-[20%]">
                      {moment.utc(row.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
                    </div>
                    <div className="table-cell px-6 py-4 max-w-fit text-[18px] w-[25%]">
                      {TriggerBadge(row.actions)}
                    </div>
                    <div className="table-cell w-[20%]">
                      {hoveredRow === index && (
                        <>
                          <Tooltip
                            placement="bottomLeft"
                            title="Delete"
                            arrow={false}
                            overlayClassName="border border-gray-200 rounded-md"
                            overlayInnerStyle={{
                              fontWeight: 600,
                              backgroundColor: deleted ? "#6c727f" : "#f3f4f6",
                              color: deleted ? "#f3f4f6" : "#6c727f",
                              fontSize: "0.75rem",
                            }}
                          >
                            <button
                              // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                              className="absolute flex justify-center items-center right-32 mt-4 mr-2 p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-[#FFCECE]"
                              onClick={() => handleDeleteClick(row)}
                              style={{ opacity: hoveredRow === index ? 1 : 0 }}
                            >
                              <img src={DeleteIcon} alt="delete-workflow" width={20} />
                            </button>
                          </Tooltip>
                          <Tooltip
                            placement="bottomLeft"
                            title="Move Workflow"
                            arrow={false}
                            overlayClassName="border border-gray-200 rounded-md"
                            overlayInnerStyle={{
                              fontWeight: 600,
                              backgroundColor: moved ? "#6c727f" : "#f3f4f6",
                              color: moved ? "#f3f4f6" : "#6c727f",
                              fontSize: "0.75rem",
                            }}
                          >
                            <button
                              // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                              className="absolute flex justify-center items-center right-16 mt-4 mr-2 p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                              onClick={() => handleMoveClick(row, "root")}
                              style={{ opacity: hoveredRow === index ? 1 : 0 }}
                            >
                              <MdOutlineDriveFileMoveRtl size={26} className="text-gray-600" />
                            </button>
                          </Tooltip>
                          <Tooltip
                            placement="bottomLeft"
                            title="Duplicate"
                            arrow={false}
                            overlayClassName="border border-gray-200 rounded-md"
                            overlayInnerStyle={{
                              fontWeight: 600,
                              backgroundColor: copied ? "#6c727f" : "#f3f4f6",
                              color: copied ? "#f3f4f6" : "#6c727f",
                              fontSize: "0.75rem",
                            }}
                          >
                            <button
                              // className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                              className="absolute flex justify-center items-center right-4 mt-4 mr-2 p-2 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                              onClick={() => handleDuplicateClick(row)}
                              style={{ opacity: hoveredRow === index ? 1 : 0 }}
                            >
                              <FaRegCopy size={22} className="text-gray-600" />
                            </button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </motion.div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <div className="text-2xl font-semibold text-gray-400 text-center">
            No workflows copied. <br />
            You can copy one from{" "}
            <Link to="/workflowLibs" className="hover:underline">
              here
            </Link>
          </div>
        </div>
      )}

      <CreateWorkflowFolder modal={createFolder} close={() => setCreateFolder(false)} />

      {deleteWorkflow && (
        <DeleteWorkflow
          modal={!!deleteWorkflow}
          close={() => setDeleteWorkflow(null)}
          workflow={deleteWorkflow}
          isV2={isV2}
        />
      )}
      {deleteWorkflowFolder && (
        <DeleteWorkflowFolder
          modal={!!deleteWorkflowFolder}
          close={() => setDeleteWorkflowFolder(null)}
          folder={deleteWorkflowFolder}
        />
      )}

      {moveWorkflow && (
        <MoveWorkflow
          modal={!!moveWorkflow}
          close={() => setMoveWorkflow(null)}
          workflow={moveWorkflow}
          oldWorkflowFolderId={oldFolderId}
          workflowFolders={[{ name: "Default", id: "root" }, ...workflowFolders]}
        />
      )}
      {duplicateWorkflow && (
        <DuplicateWorkflow
          modal={!!duplicateWorkflow}
          close={() => setDuplicateWorkflow(null)}
          workflow={duplicateWorkflow}
          workflowFolders={workflowFolders}
          isV2={isV2}
        />
      )}
      {/* {renameWorkflow && (
        <RenameWorkflow
          modal={!!renameWorkflow}
          close={() => setRenameWorkflow(null)}
          workflow={renameWorkflow}
        />
      )} */}
    </div>
  );
};

export default Workflows;
