import { useUser } from "../../contexts/UserContext";
import Logout from "../../assets/SVGs/Logout";
import Wrapper from "./Components/Wrapper";

const SettingIndex = () => {
  const { logout } = useUser();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-10">
      <div className=" w-full flex flex-row justify-between items-center px-4">
        <div className="text-4xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Settings
        </div>
        <div onClick={handleLogout}>
          <Logout />
        </div>
      </div>
      <Wrapper />
    </div>
  );
};

export default SettingIndex;
