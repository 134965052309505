import { Input } from "antd";
import FloqerSVG from "@/assets/SVGs/chatTab/FloqerSVG";
import CopyButton from "@/Components/Generics/CopyButton";
import { useChat } from "@/contexts/ChatContext";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "@/Components/Loader";
import LoadingChat from "./LoadingChat";
import { CHAT_FILLER_PAYLOAD, LOADER_TYPES } from "@/utils/constants";
import { IoSend } from "react-icons/io5";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { CHAT_SRC } from "@/utils/interfaces";
import { useUser } from "@/contexts/UserContext";

const { TextArea } = Input;

interface ChatPayloadProps {
  key: string;
  msgObj: any;
}

const ChatPayload = ({ key, msgObj }: ChatPayloadProps) => {
  const { currentUser } = useUser();
  return (
    <span
      key={key}
      className={`text-wrap whitespace-pre rounded-xl flex flex-col gap-2 p-4 w-full sm:w-4/5 max-w-[1600px] items-start`}
    >
      <span className="flex items-center justify-center gap-2">
        {(msgObj as any).src === "AI" ? (
          <FloqerSVG />
        ) : (
          <span className="flex items-center justify-center p-2 bg-yellow-200 rounded-full w-[42px] h-[42px] font-semibold text-xl">
            {currentUser.substring(0, 1).toUpperCase()}
          </span>
        )}
        <span className="font-semibold text-lg">{(msgObj as any).src === CHAT_SRC.AI ? "Floqer" : currentUser}</span>
      </span>
      <span>{(msgObj as any).payload}</span>
      {(msgObj as any).src === "AI" && !msgObj.loading && (
        <CopyButton content={(msgObj as any).payload} placement="bottomLeft" />
      )}
    </span>
  );
};

interface Props {
  userWorkflowId: string;
}

type Obj = {
  [key: string]: any;
};

const ChatComponent: React.FC<Props> = ({ userWorkflowId }) => {
  const { selectedChat, getChatForId, triggerRAGTextGeneration, currChatDataRef, setCurrChatData } = useChat();
  const [chatLoad, setChatLoad] = useState(false);
  // const [chatErr, setChatErr] = useState<string | null>(null);
  const [content, setContent] = useState("");
  const [currChat, setCurrChat] = useState<Obj | null>(null);
  const [genLoad, setGenLoad] = useState(false);
  const chatWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // console.log(
    //   "selectedChat.id --> ",
    //   selectedChat.id,
    //   currChatDataRef.current
    // );
    if (selectedChat.id === "" || !currChatDataRef.current) return;
    setChatLoad(true);
    if (currChatDataRef.current?.[selectedChat.id]?.chatDump) {
      setCurrChat({ ...currChatDataRef.current[selectedChat.id] });
      setChatLoad(false);
    } else {
      getChatForId(userWorkflowId, selectedChat.id)
        .then((chatObj: any) => {
          setCurrChat({ ...chatObj });
          setCurrChatData({
            ...currChatDataRef.current,
            [selectedChat.id]: chatObj,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setChatLoad(false));
    }
  }, [selectedChat.id]);

  // useEffect(() => {
  //   if(!currChatDataRef.current) return;
  //   console.log("currChatDataRef.current --> ", currChatDataRef.current)
  // }, [currChatDataRef.current])

  const updateChat = useCallback(
    (temp: any) => {
      setCurrChat((prev: Obj) => ({
        ...prev,
        chatDump: { ...prev.chatDump, ...temp },
      }));
      setCurrChatData({
        ...currChatDataRef.current,
        [selectedChat.id]: {
          ...currChatDataRef.current[selectedChat.id],
          chatDump: {
            ...currChatDataRef.current[selectedChat.id].chatDump,
            ...temp,
          },
        },
      });
    },
    [currChat, selectedChat.id, currChatDataRef.current]
  );

  const formSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      // console.log("submitting query, ", content);
      // return;
      const currtime = moment.utc().format("YYYY-MM-DD_HH:mm:ss");
      const promptUid = uuid();
      let temp = {
        [currtime]: {
          payload: content,
          src: CHAT_SRC.USER,
          id: promptUid,
        },
      };
      updateChat(temp);
      setGenLoad(true);
      triggerRAGTextGeneration(selectedChat.id, userWorkflowId, content, currtime, promptUid)
        .then((ragRes) => {
          temp = {
            [ragRes.responseGenerationTime]: {
              payload: ragRes.response,
              src: CHAT_SRC.AI,
              id: ragRes.responseUid,
            },
          };
          updateChat(temp);
        })
        .catch((err) => alert(err))
        .finally(() => {
          setGenLoad(false);
        });
      setContent("");
    },
    [content, currChat]
  );

  useEffect(() => {
    if (!chatWrapperRef.current) return;
    chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
  }, [currChat, chatWrapperRef]);

  if (chatLoad) {
    return (
      <section className="flex flex-1 lg:flex-[0.7] xl:flex-[0.8] flex-col p-4">
        <Loader loaderType={LOADER_TYPES.fetching} payload="current chat" />
      </section>
    );
  }

  return (
    <section className="flex flex-1 lg:flex-[0.7] xl:flex-[0.8] flex-col p-4">
      <section className="flex-grow overflow-auto scroll-smooth" ref={chatWrapperRef}>
        <section className="flex flex-col gap-2">
          <>
            <ChatPayload
              key="filler"
              msgObj={{
                src: CHAT_SRC.AI,
                payload: CHAT_FILLER_PAYLOAD,
                uid: "filler",
              }}
            />
            {currChat ? (
              currChat.chatDump ? (
                <>
                  {Object.entries(currChat.chatDump || {}).map(([key, msgObj]) => (
                    <ChatPayload key={key} msgObj={msgObj} />
                  ))}
                  {genLoad && (
                    <ChatPayload
                      key={"skakskl"}
                      msgObj={{
                        src: CHAT_SRC.AI,
                        payload: <LoadingChat />,
                        uid: "sals",
                        loading: true,
                      }}
                    />
                  )}
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        </section>
      </section>
      <section className="h-16 sm:h-24 md:h-32 p-4 flex items-center justify-center">
        <form className="flex w-full items-start justify-center gap-4" onSubmit={formSubmit}>
          <TextArea
            placeholder="Hey! I'm here to help you out. Ask me anything about your table data!"
            allowClear
            className="flex-grow"
            onChange={(e) => {
              setContent(e.target.value);
            }}
            value={content}
            autoSize={{ minRows: 3, maxRows: 3 }}
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              if (!e.shiftKey) {
                e.preventDefault();
                // console.log("submitting query, ", content)
                formSubmit(e);
              }
            }}
          />
          <button
            type="submit"
            className="flex items-center justify-center p-4 rounded-full bg-gray-100 transition group"
            disabled={content === ""}
          >
            <IoSend className="group-hover:text-primary transition" />
          </button>
        </form>
      </section>
      <section className=""></section>
    </section>
  );
};

export default ChatComponent;
