import { setStateType } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration, ResponseConfiguration } from "../../../utils/interfaces";
import { ConfigProvider, Drawer, Select, Switch, Modal } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { HiOutlinePlay } from "react-icons/hi2";
import PingViewer from "../Modals/PingViewer/PingViewer";
import CustomDropdown from "./ModalComponents/CustomDropdown";
import { RiExpandUpDownLine } from "react-icons/ri";
import { cn } from "@/utils/cn";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
  responseConfiguration: ResponseConfiguration[];
};

type VariableMappingModalProps = {
  modal: boolean;
  setModal: setStateType<boolean>;
  variables: Record<
    string,
    {
      name: string;
      description: string;
      responseStructureId: string;
      responseId: string;
      mapping: string;
    }
  >;
  setValueMapping: setStateType<Record<string, any>>;
};

const Separator = ({ className }: { className?: string }) => {
  return (
    <div className={cn("flex flex-row items-center justify-center w-full gap-2", className)}>
      <div className="w-full bg-gray-200 h-[1px]" />
    </div>
  );
};

const VariableMappingModal = ({ modal, setModal, variables, setValueMapping }: VariableMappingModalProps) => {
  return (
    <Modal open={modal} onCancel={() => setModal(false)} footer={null} title="Variable Mapping">
      <div className="flex flex-col gap-4 w-full mt-5">
        <table className="w-full border">
          <tr className="h-12 border-b">
            <th className="px-2 border-r w-1/2">Key</th>
            <th className="px-2 w-1/2">Value</th>
          </tr>
          {Object.keys(variables).map((key) => {
            return (
              <tr className="border-b">
                <td className="border-r w-1/2 text-center">{variables[key].name}</td>
                <td className="w-1/2">
                  <input
                    type="text"
                    className="w-full border-none h-12"
                    placeholder="Enter the value"
                    value={variables[key].mapping}
                    onChange={(e) => {
                      const temp = { ...variables };
                      temp[key].mapping = e.target.value;
                      setValueMapping(temp);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </table>
        <button
          className="text-white bg-purple-600 flex items-center justify-center w-full shadow-md rounded-md p-2 hover:bg-purple-700 transition-colors duration-300 ease-in-out"
          onClick={() => {
            setModal(false);
          }}
        >
          Save Mapping
        </button>
      </div>
    </Modal>
  );
};

const HTTPModal = ({ action, modal, setModal, payloadConfiguration, variables, responseConfiguration }: Props) => {
  const colorMap: Record<string, string> = {
    GET: "text-[#008C49]",
    POST: "text-[#D9A900]",
    PUT: "text-[#0566AD]",
    PATCH: "text-[#5A08AC]",
    DELETE: "text-[#A80404]",
  };
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const {
    updateResponseConfig,
    updatePayloadConfig,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
    getAllWorkflowActionsDetails,
    pingHttpApiCall,
  } = useWorkflow();
  const [changeName, setChangeName] = useState(false);
  const [ping, setPing] = useState<any>({
    loading: false,
    data: action.ping,
    response: null,
  });
  const { id } = useParams();
  const [variableModal, setVariableModal] = useState(false);
  const [variableMapping, setVariableMapping] = useState<Record<string, any>>({});

  const addField = (key: string) => {
    const temp = [...responses];
    const name = key.split(".");
    temp.push({
      name: name[name.length - 1],
      description: "",
      responseStructureId: key,
    });
    setResponses(temp);
    setChanged(true);
  };

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updatedResponses = updateResponseConfig(responses, action.id);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
    // setModal(false);
  };

  const validJSON = (key: string, json: string) => {
    try {
      JSON.parse(json);
      if (errors[key]) {
        const temp = { ...errors };
        delete temp[key];
        setErrors(temp);
      }
      return true;
    } catch (e) {
      if (!errors[key]) {
        setErrors((prev) => ({ ...prev, [key]: (e as Error).message }));
      }
      return false;
    }
  };

  const jsonParser = (json: string) => {
    if (typeof json !== "string") return json;
    try {
      return JSON.parse(json);
    } catch (e) {
      return json;
    }
  };

  const valuesCheck = () => {
    for (const key in variableMapping) {
      if (!variableMapping[key]?.mapping) return false;
    }
    return true;
  };

  const variablesPresent = () => {
    const pattern = /{{(.*?)}}/g;
    const _variables: Record<string, any> = {};

    const findVariable = (responseId: string) => {
      for (const action of variables) {
        for (const variable of action.variables) {
          if (variable.responseId === responseId) return { ...variable, mapping: undefined };
        }
      }
      return null;
    };

    const processPayload = (payload: any) => {
      payload.forEach((query: any) => {
        const matches = query.value.match(pattern);
        if (matches) {
          matches.forEach((match: string) => {
            const key = match.slice(2, -2);
            _variables[key] = findVariable(key);
          });
        }
      });
    };

    const endpoint = payloads.find((input) => input.payloadStructureId === "endpointId")?.inputString;
    const queryParams = payloads.find((input) => input.payloadStructureId === "queryParamsId")?.inputString;
    const body = payloads.find((input) => input.payloadStructureId === "bodyId")?.inputString;
    const headers = payloads.find((input) => input.payloadStructureId === "headersId")?.inputString;

    if (endpoint) {
      const matches = endpoint.match(pattern);
      if (matches) {
        matches.forEach((match: string) => {
          const key = match.slice(2, -2);
          _variables[key] = findVariable(key);
        });
      }
    }
    if (queryParams) {
      processPayload(queryParams);
    }
    if (body) {
      if (body[0]?.type === "raw_json") {
        const key = body[0].value.match(pattern);
        if (key) {
          key.forEach((match: string) => {
            const key = match.slice(2, -2);
            _variables[key] = findVariable(key);
          });
        }
      } else {
        processPayload(body);
      }
    }
    if (headers) {
      processPayload(headers);
    }
    return _variables;
  };

  const substituteVariables = () => {
    if (!valuesCheck()) return {};
    const _payloads: PayloadConfiguration[] = JSON.parse(JSON.stringify(payloads));
    const pattern = /{{(.*?)}}/g;
    const method = _payloads.find((input) => input.payloadStructureId === "methodId")?.inputString;
    const endpoint = _payloads.find((input) => input.payloadStructureId === "endpointId")?.inputString;
    const queryParams = _payloads.find((input) => input.payloadStructureId === "queryParamsId")?.inputString;
    const body = _payloads.find((input) => input.payloadStructureId === "bodyId")?.inputString;
    const headers = _payloads.find((input) => input.payloadStructureId === "headersId")?.inputString;

    const replaceVariables = (payload: any) => {
      payload.forEach((query: any) => {
        query.value = query.value.replace(pattern, (match: string) => {
          const key = match.slice(2, -2);
          return variableMapping[key]?.mapping;
        });
      });
    };

    if (endpoint) {
      endpoint.replace(pattern, (match: string) => {
        const key = match.slice(2, -2);
        endpoint.replace(match, variableMapping[key]?.mapping);
      });
    }
    if (queryParams) {
      replaceVariables(queryParams);
    }
    if (body) {
      replaceVariables(body);
    }
    if (headers) {
      replaceVariables(headers);
    }

    return {
      method,
      endpoint,
      parameters: queryParams,
      body,
      headers,
    };
  };

  useEffect(() => {
    const mapping = variablesPresent();
    setVariableMapping(mapping);
  }, [payloads, variables]);

  const updateColor = () => {
    const method = payloads.find((input) => input.payloadStructureId === "methodId")?.inputString;
    const color = colorMap[method?.toUpperCase()] as string;
    const methodDropdown = document.querySelector(".method-dropdown");
    if (methodDropdown) {
      const selectionItem = methodDropdown.querySelector(".ant-select-selection-item");
      if (selectionItem) {
        selectionItem.className = `ant-select-selection-item ${color}`;
      }
    }
  };

  updateColor();

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            className="method-dropdown h-11 rounded-r-none w-1/4"
            placeholder="Method"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
            optionFilterProp="label"
            options={field.values?.map((value: any) => ({
              label: value.name,
              value: value.name,
            }))}
            optionRender={(option, idx) => (
              <div
                className={`flex items-center justify-start font-semibold h-10 px-1.5 py-3 ${
                  idx.index !== field.values.length - 1 && "border-b"
                } ${colorMap[(option.label as string)?.toString().toUpperCase()]}`}
              >
                <div>{option.label}</div>
              </div>
            )}
            dropdownRender={(menu) => <div className="custom-dropdown-menu">{menu}</div>}
          />
        );
      case "radio":
        return (
          <Switch
            className="w-fit"
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray": {
        if (
          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[0]?.type ===
          "json_array"
        ) {
          return (
            <div className="flex flex-col gap-4">
              <table className="w-full border border-[#ABABAB]">
                <tr className="h-12 border-b border-[#ABABAB]">
                  <th className="px-2 border-r w-1/2 border-[#ABABAB]">Key</th>
                  <th className="px-2">Value</th>
                </tr>
                {payloads
                  .find((input) => input.payloadStructureId === field.payloadStructureId)
                  ?.inputString.map((input: any, idx: number) => {
                    return (
                      <tr className="relative border-b border-[#ABABAB] group">
                        <td className="border-r w-1/2 border-[#ABABAB]">
                          <input
                            type="text"
                            className="w-full border-none h-12"
                            placeholder="Enter the key"
                            value={input.name}
                            onChange={(e) => {
                              const temp = [...payloads];
                              const index = payloads.findIndex(
                                (input) => input.payloadStructureId === field.payloadStructureId
                              );
                              temp[index].inputString[idx].name = e.target.value;
                              setPayloads(temp);
                            }}
                          />
                        </td>
                        <td className="border-r w-1/2 border-[#ABABAB]">
                          <TiptapField
                            centerPlaceholder={true}
                            className="border-none rounded-none min-h-[48px] p-0 px-3 py-2 m-0 break-words break-all overflow-y-auto"
                            content={
                              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                ?.inputString[idx].tiptapJson || ""
                            }
                            setContent={(content: any, text: string) => {
                              const temp = [...payloads];
                              const index = payloads.findIndex(
                                (input) => input.payloadStructureId === field.payloadStructureId
                              );
                              temp[index].inputString[idx].value = text;
                              temp[index].inputString[idx].tiptapJson = content;
                              setPayloads(temp);
                            }}
                            refresh={updateField}
                            placeholder={undefined}
                            variables={variables}
                            applyStyles={false}
                            skipDataValues
                          />
                          <button
                            className="hidden group-hover:block absolute right-1.5 top-0 bottom-0 my-auto h-fit w-fit p-2 rounded-md bg-white border text-primary transition-all duration-300 ease-in-out hover:text-red-500 hover:bg-red-200"
                            onClick={() => {
                              const temp = [...payloads];
                              const index = payloads.findIndex(
                                (input) => input.payloadStructureId === field.payloadStructureId
                              );
                              temp[index].inputString.splice(idx, 1);
                              setUpdateField((prev) => prev + 1);
                              setPayloads(temp);
                            }}
                          >
                            <MdDeleteOutline size={25} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
              <button
                className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-[#ABABAB] w-fit px-3 py-2 text-md"
                onClick={() => {
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                  temp[index].inputString.push({
                    name: "",
                    value: "",
                    type: "json_array",
                  });
                  setPayloads(temp);
                }}
              >
                <FaPlus />
                Add key-value pair
              </button>
            </div>
          );
        }
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                if (input?.type === "raw_json") {
                  return (
                    <div className="flex gap-2 justify-between">
                      <div className="flex w-full flex-col gap-2" key={idx}>
                        <div className="flex flex-row gap-2 items-center justify-between">
                          <div className="flex flex-row gap-2 items-center justify-between">
                            {validJSON(`jsonArray-${idx}-${field.payloadStructureId}`, input.value) ? (
                              <div className="w-full p-2 bg-green-100 rounded-sm text-green-700">Valid JSON</div>
                            ) : (
                              <div className="w-full p-2 bg-red-100 rounded-sn text-red-700">Invalid JSON</div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="text-[#f9a606] text-sm bg-[#fff2d9] px-1.5 py-1 border border-[#ffcf71] rounded-sm">
                              {
                                (
                                  {
                                    raw_json: "JSON",
                                  } as Record<string, string>
                                )[input.type]
                              }
                            </span>
                            <button
                              className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                              onClick={() => {
                                const temp = [...payloads];
                                const index = payloads.findIndex(
                                  (input) => input.payloadStructureId === field.payloadStructureId
                                );
                                temp[index].inputString.splice(idx, 1);
                                setUpdateField((prev) => prev + 1);
                                setPayloads(temp);
                              }}
                            >
                              <MdDeleteOutline size={25} />
                            </button>
                          </div>
                        </div>
                        <TiptapField
                          className="bg-[#01001fd2] text-[#c6c6c6]"
                          content={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].tiptapJson || ""
                          }
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = text;
                            temp[index].inputString[idx].tiptapJson = content;
                            setPayloads(temp);
                          }}
                          refresh={updateField}
                          placeholder="Enter the value"
                          variables={variables}
                          customStyles={true}
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="flex flex-col gap-2 justify-between">
                    <div className="flex flex-col gap-2" key={idx}>
                      <div className="flex flex-row gap-2 items-center justify-between">
                        <input
                          type="text"
                          className="w-full rounded-lg"
                          placeholder="Enter the name"
                          value={input.name}
                          onChange={(e) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = e.target.value;
                            setPayloads(temp);
                          }}
                        />
                        <button
                          className="hover:bg-primary/20 h-fit w-fit p-2 rounded-md text-primary border-2 bg-primary/10 transition-colors duration-300 ease-in-out"
                          onClick={() => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString.splice(idx, 1);
                            setUpdateField((prev) => prev + 1);
                            setPayloads(temp);
                          }}
                        >
                          <MdDeleteOutline size={25} />
                        </button>
                      </div>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ].tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                  </div>
                );
              })}
            <div className="flex flex-row gap-2">
              {field.payloadStructureId !== "bodyId" ? (
                <button
                  className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-[#ABABAB] w-fit px-3 py-2 text-md"
                  onClick={() => {
                    const temp = [...payloads];
                    const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                    temp[index].inputString.push({
                      name: "",
                      value: "",
                      type: "json_array",
                    });
                    setPayloads(temp);
                  }}
                >
                  <FaPlus />
                  Add key-value pair
                </button>
              ) : payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                  ?.length === 0 ? (
                <CustomDropdown payloads={payloads} setPayloads={setPayloads} field={field} action={action} />
              ) : null}
            </div>
          </div>
        );
      }
      case "webhookArray":
        return (
          <div className="flex flex-col w-full justify-center items-start gap-4">
            {responses.length > 0 && (
              <table className="w-full border border-[#ABABAB]">
                <tr className="h-12 border-b border-[#ABABAB]">
                  <th className="px-2 border-r w-1/2 border-[#ABABAB]">Key</th>
                  <th className="px-2">Value</th>
                </tr>
                {responses.map((response, idx) => {
                  return (
                    <tr className="relative border-b border-[#ABABAB] group">
                      <td className="border-r w-1/2 border-[#ABABAB]">
                        <input
                          type="text"
                          className="w-full border-none h-12"
                          placeholder="Enter the key"
                          value={response.name}
                          onChange={(e) => {
                            const temp = [...responses];
                            temp[idx].name = e.target.value;
                            setResponses(temp);
                          }}
                        />
                      </td>
                      <td className="border-r w-1/2 border-[#ABABAB]">
                        <input
                          type="text"
                          className="w-full border-none h-12"
                          placeholder="Enter the value"
                          value={response.responseStructureId}
                          onChange={(e) => {
                            const temp = [...responses];
                            temp[idx].responseStructureId = e.target.value;
                            setResponses(temp);
                          }}
                        />
                        <button
                          className="hidden group-hover:block absolute right-1.5 top-0 bottom-0 my-auto h-fit w-fit p-2 rounded-md bg-white border text-primary transition-all duration-300 ease-in-out hover:text-red-500 hover:bg-red-200"
                          onClick={() => {
                            const temp = [...responses];
                            temp.splice(idx, 1);
                            setResponses(temp);
                            setChanged(true);
                          }}
                        >
                          <MdDeleteOutline size={25} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
            <button
              className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-gray-400 w-fit px-3 py-2 text-md"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
                setChanged(true);
              }}
            >
              <FaPlus />
              Add input
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 24,
            },
          },
        }}
      >
        {variableModal && (
          <VariableMappingModal
            modal={variableModal}
            setModal={setVariableModal}
            variables={variableMapping}
            setValueMapping={setVariableMapping}
          />
        )}
        <Drawer
          open={modal}
          onClose={() => setModal(false)}
          width="30%"
          className='!font-["Inter"] !p-0'
          footer={
            <div className="w-full flex flex-col gap-4 bg-white">
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || Object.keys(errors).length > 0
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || Object.keys(errors).length > 0}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          }
          title={
            <div className="flex items-center gap-2">
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          }
        >
          <div className="bg-white flex flex-col gap-4 overflow-x-hidden">
            <div className="flex flex-col w-full gap-4 rounded-lg">
              <div>
                <p className="tracking-primary font-semibold uppercase">METHOD</p>
                <p className="text-sm font-light text-[#747474]">
                  Send or retrieve from any tool or database using a general API endpoint
                </p>
              </div>
              <div className="flex flex-row">
                {action.payloadStructure.slice(0, 2).map((field) => {
                  return (
                    <>
                      {field.needVars ? (
                        <TiptapField
                          className="min-h-11 max-h-11 p-0 px-2 py-2 rounded-l-none border-black break-words break-all overflow-y-auto"
                          centerPlaceholder={true}
                          applyStyles={false}
                          content={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.tiptapJson || ""
                          }
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            if (index === -1) {
                              temp.push({
                                payloadStructureId: field.payloadStructureId,
                                tiptapJson: content,
                                inputString: text,
                                type: field.type,
                              });
                            } else {
                              temp[index].tiptapJson = content;
                              temp[index].inputString = text;
                            }
                            setPayloads(temp);
                          }}
                          refresh={updateField}
                          variables={variables}
                          skipDataValues
                        />
                      ) : (
                        fieldSwitchCase(field)
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <Separator className="my-6" />
          <div className="bg-white flex flex-col gap-4 overflow-x-hidden">
            {action.payloadStructure.slice(2, action.payloadStructure.length - 1).map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && <span className="text-sm font-normal tracking-normal"> (Optional)</span>}
                    </p>
                    <p className="text-sm font-light text-[#747474]">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      placeholderText="Enter URL"
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                  {idx !== action.payloadStructure.length - 4 && <Separator className="my-3" />}
                </div>
              );
            })}
            <h3 className="bg-primary/20 text-gray-800 text-center rounded-[3px] w-full py-2 my-5 text-lg font-semibold uppercase">
              Output
            </h3>
            {action.payloadStructure.slice(action.payloadStructure.length - 1).map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light text-[#747474]">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                  <Separator className="my-3" />
                </div>
              );
            })}
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col items-start justify-between">
                <div>
                  <p className="tracking-primary font-semibold uppercase">Test Request</p>
                  <p className="text-sm font-light text-[#747474]">Send a test request to map the response!</p>
                </div>
                <button
                  className="flex font-semibold w-full gap-1 mt-4 items-center justify-center bg-[#F4F4F4] border border-black p-2 rounded my-2 hover:bg-[#F4F4F4]/90 transition-colors duration-300 ease-in-out"
                  disabled={ping.loading}
                  onClick={async () => {
                    if (Object.keys(variableMapping).length > 0 && !valuesCheck()) {
                      setVariableModal(true);
                      return;
                    }
                    setPing((prev: any) => ({
                      ...prev,
                      loading: true,
                      response: null,
                    }));
                    const res = await pingHttpApiCall(id as string, action.id, substituteVariables());
                    const data = await getAllWorkflowActionsDetails(id || "");
                    const _ping = data?.actions?.find((act: any) => act.id === action.id)?.ping || undefined;
                    setPing((prev: any) => ({
                      ...prev,
                      loading: false,
                      response: res,
                      data: _ping,
                    }));
                  }}
                >
                  {ping.loading ? <SpinnerStatus /> : <HiOutlinePlay className="w-5 h-5" color="black" />}
                  {ping.loading ? "Testing..." : "Test Request"}
                </button>
                {ping.response && <div className="w-full p-2 bg-red-100 rounded-sm text-red-700">{ping.response}</div>}
                {Object.keys(variableMapping).length > 0 && !valuesCheck() && (
                  <div className="w-full p-2 bg-red-100 rounded-sm text-red-700">
                    Please map all variables to their responses
                  </div>
                )}
              </div>
            </div>
            <Separator className="my-3" />
            <div className="flex flex-col w-full gap-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="tracking-primary font-semibold uppercase">Mapping</p>
                  <p className="text-sm font-light text-[#747474]">Click the field to add as an acceptable parameter</p>
                </div>
              </div>
              <div className="bg-gray-100 rounded py-2 px-4">
                {ping.data ? (
                  Object.entries(jsonParser(ping.data)).map(([key, value], idx) => {
                    return (
                      <PingViewer
                        keyName={key}
                        value={value}
                        prev={""}
                        key={idx}
                        addField={addField}
                        showValue={true}
                      />
                    );
                  })
                ) : (
                  <div className="p-2 flex flex-col items-center">
                    <p>No mapping found</p>
                    <p>The HTTP API call has not received any response yet</p>
                    <p>Make a request and refresh to see the mapping.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </ConfigProvider>
    </>
  );
};

export default HTTPModal;
