import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from "@xyflow/react";
import AddAction from "@/assets/SVGs/AddAction";
import { MdAltRoute, MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import AddActionModal from "@/Components/Workflow/ActionModals/AddActionModal";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { useState } from "react";
import ConditionalModal from "@/Components/Workflow/ActionModals/ConditionalModal";
import { NextActionConfiguration } from "@/utils/interfaces";
import { useParams } from "react-router-dom";

export default function AddEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  source,
  target,
  data,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [addModal, setAddModal] = useState(false);
  const [conditionalModal, setConditionalModal] = useState(false);
  const { actions, setActions, availableActions, addNewPath, saveWorkflowActions } = useWorkflow();
  const { id } = useParams();

  const checkFinalAction = () => {
    if (!target.includes("-end")) return false;
    const actionId = target.replace("-end", "");
    const action = actions.find((act) => act.id === actionId);
    const nextActions = action?.nextAction || [];
    if (nextActions.length < 2) return false;
    return nextActions.some((a: any) => a === null || a.actionId === null);
  };

  const handleEdgeDelete = async (target: string) => {
    if (target.includes("-end")) {
      const actionId = target.replace("-end", "");
      const action = actions.find((act) => act.id === actionId);
      const nextActions = (action?.nextAction || []).filter((a: any) => a.actionId);
      action.nextAction = nextActions;
      const _actions = actions.map((act) => (act.id === actionId ? action : act));
      setActions(_actions);
      await saveWorkflowActions(id || "");
    }
  };

  // console.log(data, "[AddEdge] data");

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={data?.error ? { stroke: "red", strokeWidth: 2, ...style } : style}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <div className="group flex relative">
            {!target.includes("-end") && (
              <button
                onClick={() => setConditionalModal(true)}
                className={`focus:outline-none absolute transform duration-200 group-hover:-translate-x-6 flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border text-purple-800 ${data?.error ? "bg-red-300" : "bg-white"} hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0 ${data?.error ? "border-red-500" : "border-purple-500"}  ring-offset-2 focus:ring-2`}
              >
                <MdEdit />
              </button>
            )}
            <button
              onClick={() => setAddModal(true)}
              className={`focus:outline-none flex z-10 select-none px-3 py-2 font-semibold focus:ring-purple-500 border text-purple-800 bg-white hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0  ${data?.error ? "border-red-500" : "border-purple-500"} ring-offset-2 focus:ring-2`}
            >
              <AddAction />
            </button>
            {target.includes("-end") && checkFinalAction() && (
              <button
                onClick={() => handleEdgeDelete(target)}
                className="focus:outline-none absolute transform duration-200 group-hover:translate-x-6 flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border text-purple-800 bg-white hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0  border-purple-500 ring-offset-2 focus:ring-2"
              >
                <IoMdTrash />
              </button>
            )}
            {!target.includes("-end") && (
              <button
                onClick={async () => {
                  if (addNewPath(source)) await saveWorkflowActions(id || "");
                }}
                className="focus:outline-none absolute transform duration-200 group-hover:translate-x-6 flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border text-purple-800 bg-white hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0  border-purple-500 ring-offset-2 focus:ring-2"
              >
                <MdAltRoute />
              </button>
            )}
          </div>
        </div>
        <AddActionModal
          modal={addModal}
          setModal={setAddModal}
          source={source}
          target={target.includes("-end") ? null : target}
          availableActions={availableActions.filter((act) => act.type !== "input")}
        />
        <ConditionalModal
          source={source}
          target={target.includes("-end") ? null : target}
          nextActionConfiguration={data?.conditions as NextActionConfiguration}
          modal={conditionalModal}
          setModal={setConditionalModal}
          variables={data?.variables as any[]}
        />
      </EdgeLabelRenderer>
    </>
  );
}
