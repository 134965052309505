import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdInfoOutline } from "react-icons/md";
import InputModal from "./Modals/InputModal";
import SendingModal from "./Modals/SendingModal";
import ActionModal from "./Modals/ActionModal";
import { PayloadConfiguration, ResponseConfiguration } from "@/utils/interfaces";
import AddAction from "@/assets/SVGs/AddAction";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import AddActionModal from "./Modals/AddActionModal";
import { useBuilder } from "@/contexts/BuilderContext";
import DeleteAction from "@/assets/SVGs/DeleteAction";
import AddSection from "./Modals/AddSection";

type Props = {
  availableActions: any[];
};

const Builder = ({ availableActions }: Props) => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState("");
  const { workflow, publishDraftWorkflow, deleteActionFormDraft } = useBuilder();
  const [workflowActions, setWorkflowActions] = useState<any[]>([]);
  const [responseConfiguration, setResponseConfiguration] = useState<ResponseConfiguration[]>([]);
  const [payloadConfiguration, setPayloadConfiguration] = useState<PayloadConfiguration[]>([]);
  const [action, setAction] = useState<any>(undefined);
  const [variables, setVariables] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!workflow) return;
    setWorkflowActions([]);
    const tempActions = Object.entries(workflow.actions).map(([key, value]: [string, any]) => ({
      id: key,
      ...value,
    }));
    let aid = workflow.firstAction;
    while (aid) {
      const act = tempActions.find((act) => act.id === aid);
      setWorkflowActions((prev) => [...prev, act]);
      aid = act.nextAction[0];
    }
  }, [workflow]);

  useEffect(() => {
    if (!action) return;
    let aid = workflow.firstAction;
    const responses = [];
    while (aid && action.id !== aid) {
      const act = workflowActions.find((act) => act.id === aid);
      const actionDetails = availableActions.find((a) => a.id === act.actionName);
      if (!act) return;
      const a: any = {
        name: actionDetails.name,
        type: actionDetails.type,
        logo: actionDetails.logo,
      };
      if (actionDetails.outputStructureType === "userDefined") {
        responses.push({
          ...a,
          variables: act.responseConfiguration,
        });
      } else {
        const arr: any[] = [];
        const configs: {
          responseId: string;
          responseStructureId: string;
        }[] = act.responseConfiguration || [];
        const structures: {
          name: string;
          description: string;
          responseStructureId: string;
          type: string;
        }[] = actionDetails.responseStructure;

        configs.forEach((config: any) => {
          const response: any = config;
          const structure = structures.find((str) => str.responseStructureId === config.responseStructureId);
          arr.push({
            ...response,
            ...structure,
          });
        });
        responses.push({
          ...a,
          variables: arr,
        });
      }
      aid = act.nextAction[0];
    }
    setVariables(responses);
  }, [action]);

  const publish = async () => {
    if (!id) return;
    setLoading(true);
    await publishDraftWorkflow(id);
    setLoading(false);
    navigate("/workflowLibs");
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <div className="w-full flex flex-col gap-5 items-center">
        {/* {Object.entries(workflow.actions).map(([id, act]: any, idx: number) => { */}
        {workflowActions.map((act: any, idx: number) => {
          const actionDetails = availableActions.find((a) => a.id === act.actionName);
          if (!actionDetails) return null;
          return (
            <div className="builder-step-card relative group px-12 py-3 w-4/5" key={idx}>
              <div
                onClick={() => {
                  setModal(false);
                  setAction({
                    ...actionDetails,
                    id: act.id,
                    reviewNeeded: act.reviewNeeded || false,
                  });
                  setResponseConfiguration(act.responseConfiguration || []);
                  setPayloadConfiguration(act.payloadConfiguration || []);
                  setModal(true);
                }}
                className="flex bg-white justify-between items-start sm:items-center transition-opacity duration-300 w-full max-w-full py-3 px-4 md:p-6 border border-grey-400 rounded-2xl cursor-pointer relative z-10 hover:border-primary hover:bg-gray-50"
              >
                <div className="flex items-center justify-center gap-4 w-full">
                  <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
                    {actionDetails.logo.includes("https") ? (
                      <img src={actionDetails.logo} alt="icon" />
                    ) : (
                      <DefaultAction />
                    )}
                  </div>
                  <div className="flex w-fit items-center justify-between overflow-hidden">
                    <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                      {actionDetails.name}
                    </label>
                  </div>
                </div>
              </div>
              {act.id !== "id1" && (
                <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:right-0 group-hover:opacity-100">
                  <button
                    onClick={async () => await deleteActionFormDraft(act.id, id || "")}
                    className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
                  >
                    <DeleteAction />
                  </button>
                </div>
              )}
              <div className="flex items-center justify-center absolute z-10 bg-white w-fit right-0.5 transform -translate-y-5 m-auto duration-200 left-0 -bottom-10">
                <button
                  onClick={() => setAddModal(act.id)}
                  className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border text-purple-800 hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0  border-purple-500 ring-offset-2 focus:ring-2"
                >
                  <AddAction />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {modal &&
        (action.type === "input" ? (
          <InputModal action={action} setModal={setModal} modal={modal} responseConfiguration={responseConfiguration} />
        ) : action.type === "add_section" ? (
          <AddSection
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            variables={variables}
            payloadConfiguration={payloadConfiguration}
          />
        ) : action.type === "sending" ? (
          <SendingModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        ) : (
          <ActionModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        ))}
      {/* {addModal && ( */}
      <AddActionModal
        currActionId={addModal}
        setModal={() => setAddModal("")}
        modal={addModal !== ""}
        actions={availableActions.filter((act) => act.type !== "input")}
      />
      {/* )} */}
      <div className="sticky bottom-0 left-0 z-10 bg-white w-full py-5 px-10 flex gap-10 items-center border-t-2 border-[#cccccc]">
        <button
          className={`font-semibold py-3 px-10 rounded-lg transition ${
            loading ? "cursor-not-allowed bg-gray-400" : "cursor-pointer bg-primary hover:bg-dark text-white"
          }`}
          onClick={publish}
          disabled={loading}
        >
          {loading ? "Publishing..." : "Publish"}
        </button>
        <div className="flex gap-2 items-center">
          <MdInfoOutline size={25} />
          <span className="text-sm">
            New published changes will be applied to all prospects currently in this sequence. The data will not be
            changed for the prospects that are completed
          </span>
        </div>
      </div>
    </div>
  );
};

export default Builder;
