import "./index.scss";

const index = () => {
  return (
    <div className="typing">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default index;
