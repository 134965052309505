import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import Icon from "../../../assets/SVGs/newWorkflows.svg";
import { Tooltip } from "antd";

const Workflows = () => {
  const location = useLocation();
  const isEmailsPage = location.pathname.includes("/workflows") || location.pathname.includes("/workflow/");
  const { collapsed } = useUser();

  if (collapsed) {
    return (
      <Tooltip
        title="Workflows"
        placement="right"
        overlayClassName="border border-gray-200 rounded-sm font-inter"
        overlayInnerStyle={{
          fontWeight: 600,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          width: "fit-content",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontFamily: "Inter",
        }}
      >
        <Link
          className={`text-[#2a2a2a] rounded-md w-12 h-12 font-medium flex justify-center items-center align-middle gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
            isEmailsPage && "bg-[#efe5ff]" // Apply background color if on "leads" page
          }`}
          to="/workflows"
          // title="Workflows"
        >
          <img src={Icon} alt="Workflows" />
        </Link>
      </Tooltip>
    );
  } else {
    return (
      <Link
        className={`text-[#2a2a2a] rounded-md w-[90%] h-[7%] font-medium flex justify-start items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
          isEmailsPage && "bg-[#efe5ff]" // Apply background color if on "leads" page
        }`}
        to="/workflows"
        // title="Workflows"
      >
        <img src={Icon} alt="Workflows" width={24} />
        <span className="lg:ml-1 sm:ml-0 font-semibold text-[18px]">Your Workflows</span>
      </Link>
    );
  }
};

export default Workflows;
