const DeleteAction = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-purple-500 group-hover:text-purple-600 h-3.5 w-3.5 !text-grey-600"
    >
      <g clipPath="url(#clip0_5_349)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 2H7C6.44772 2 6 2.44772 6 3V4H10V3C10 2.44772 9.55228 2 9 2ZM12 4V3C12 1.34315 10.6569 0 9 0H7C5.34315 0 4 1.34315 4 3V4H2H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H2V13C2 14.6569 3.34315 16 5 16H11C12.6569 16 14 14.6569 14 13V6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H14H12ZM12 6H10H6H4V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5523 12 13V6Z"
          fill="currentColor"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5_349">
          <rect width="16" height="16" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteAction;
