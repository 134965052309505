import { LineChart } from "@mui/x-charts";
import React from "react";

interface Props {
  dataset: {
    days: number[];
    data: number[];
  };
  barDataSet?: any;
}

const CreditsUsedAreaChart: React.FC<Props> = ({ dataset, barDataSet }) => {
  return (
    <LineChart
      xAxis={[{ data: dataset?.days }]}
      series={[
        {
          data: dataset?.data,
          area: true,
          // @ts-ignore
          valueFormatter: (value, metaData: any) => {
            const data = barDataSet[metaData.dataIndex];
            if (!data) {
              return;
            }

            const keys = Object.keys(data);
            const filteredKeys = keys.filter((key) => key !== "date" && key !== "credits");

            if (!filteredKeys?.length) return <div>No credits used</div>;

            return (
              <div>
                {filteredKeys?.map((key, index) => {
                  return (
                    <div key={index}>
                      {key}: {data[key]}
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      ]}
      height={300}
      colors={["#5750FF"]}
    />
  );
};

export default CreditsUsedAreaChart;
