import { ConfigProvider, Modal, Select } from "antd";
import { useState } from "react";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import HomeIcon from "../../assets/SVGs/All.svg";
import FolderIcon from "../../assets/SVGs/WorkflowFolderIcon.svg";
import { FaChevronDown } from "react-icons/fa";
import { moveWorkflow } from "@/utils/apis";

type Props = {
  modal: boolean;
  close: () => void;
  workflow: any;
  oldWorkflowFolderId: string;
  workflowFolders: any[];
};

const MoveWorkflow = ({ modal, close, workflow, oldWorkflowFolderId, workflowFolders }: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [newWorkflowFolderId, setNewWorkflowFolderId] = useState("root");

  const handleMove = async () => {
    if (newWorkflowFolderId === oldWorkflowFolderId) {
      setErrorMessage("Please select a different folder to move the workflow");
      return;
    }
    setErrorMessage("");
    await moveWorkflow(workflow.id, oldWorkflowFolderId, newWorkflowFolderId);
    window.location.reload();
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="50%">
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2 mb-6">
          <label htmlFor="workflow-name" className="text-[2rem] font-umuxGrot mb-5 font-semibold tracking-wider">
            Move Workflow to:
          </label>
          <ConfigProvider
            theme={{
              token: {
                colorBorder: "rgb(156 163 175)",
                colorPrimary: "#b8b8b8",
                colorPrimaryHover: "rgb(0 0 0)",
                fontSize: 22,
              },
            }}
          >
            <Select
              placeholder="Default(root) folder"
              className="w-full"
              popupClassName="py-1 py-4 rounded-md h-fit"
              rootClassName="py-1 rounded-md h-16 text-[22px]"
              suffixIcon={<FaChevronDown size={16} color="rgb(156 163 175)" />}
              value={newWorkflowFolderId}
              onChange={(value) => setNewWorkflowFolderId(value)}
              options={workflowFolders.map((folder: any) => ({
                label: (
                  <div className="flex items-center gap-2 py-2 my-1 text-[22px] font-normal">
                    <img
                      src={`${folder.name === "Default" ? HomeIcon : FolderIcon}`}
                      alt="salesforce"
                      className="w-6 h-6"
                    />
                    <span>{folder.name}</span>
                  </div>
                ),
                value: folder.id,
              }))}
            />
          </ConfigProvider>
          {errorMessage && <span className="text-red-500 text-sm pl-2">{errorMessage}</span>}
        </div>
        <div className="">
          <LoaderButtonBlack
            disabled={false}
            error=""
            loaderText="Moving Workflow..."
            text="Move Workflow"
            onClickCallback={handleMove}
            btnClasses="w-fit bg-black text-white hover:bg-gray-800"
          />
        </div>
      </div>
    </Modal>
  );
};

export default MoveWorkflow;
