import LoaderButton from "@/Components/LoaderButton";
import { useBuilder } from "@/contexts/BuilderContext";
import { Modal } from "antd";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

type Props = {
  modal: boolean;
  close: () => void;
};

const CreateDraft = ({ modal, close }: Props) => {
  //   const [modal, setModal] = useState(false);
  const [workflow, setWorkflow] = useState<{
    name: string;
    description: string;
    inputs: string[];
  }>({
    name: "",
    description: "",
    inputs: [""],
  });
  const { createDraftWorkflow } = useBuilder();
  const navigate = useNavigate();
  return (
    <Modal open={modal} onCancel={close} footer={<></>} width="50%">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <label htmlFor="workflow-name" className="text-lg font-semibold text-gray-600">
            Workflow Name
          </label>
          <input
            type="text"
            name="workflow-name"
            id="workflow-name"
            placeholder="Enter workflow name"
            value={workflow.name}
            onChange={(e) => setWorkflow({ ...workflow, name: e.target.value })}
            className="p-2 border-1 border-gray-400 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="workflow-description" className="text-lg font-semibold text-gray-600">
            Workflow Description
          </label>
          <textarea
            name="workflow-description"
            id="workflow-description"
            rows={3}
            value={workflow.description}
            placeholder="Enter workflow description"
            onChange={(e) => setWorkflow({ ...workflow, description: e.target.value })}
            className="p-2 border-1 border-gray-400 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="workflow-inputs" className="text-lg font-semibold text-gray-600">
            Workflow Inputs
          </label>
          {workflow.inputs.map((input: any, idx: number) => (
            <div className="flex gap-2 items-center">
              <input
                type="text"
                name="workflow-inputs"
                id="workflow-inputs"
                placeholder="Enter input"
                value={input}
                onChange={(e) =>
                  setWorkflow({
                    ...workflow,
                    inputs: workflow.inputs.map((inp, i) => (i === idx ? e.target.value : inp)),
                  })
                }
                className="w-60 p-2 border-1 border-gray-400 rounded-md"
              />
              {idx === workflow.inputs.length - 1 && (
                <button
                  className="w-10 h-10 flex items-center justify-center bg-primary text-white rounded-full mt-2 hover:bg-dark"
                  onClick={() =>
                    setWorkflow({
                      ...workflow,
                      inputs: [...workflow.inputs, ""],
                    })
                  }
                >
                  <FaPlus />
                </button>
              )}
            </div>
          ))}
        </div>
        {/* <button className="w-fit py-3 px-6 bg-primary font-semibold text-white rounded-md hover:bg-dark">
        Save Draft 
      </button> */}
        <LoaderButton
          disabled={false}
          error=""
          loaderText="Creating Draft..."
          text="Create Draft"
          onClickCallback={async () => {
            const id = await createDraftWorkflow(workflow);
            navigate(`/builder/${id}`);
          }}
          btnClasses="w-fit"
        />
      </div>
    </Modal>
  );
};

export default CreateDraft;
