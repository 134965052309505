import { useState } from "react";
import { Modal } from "antd";
import { setStateType } from "@/utils/constants";
import { ResponseStructure } from "@/utils/interfaces";
import TiptapField from "../../Tiptap/TiptapField";

type Props = {
  open: boolean;
  setOpen: setStateType<boolean>;
  responses: ResponseStructure[];
  variables: any[];
  values: any[];
  saveValues: (values: any[]) => void;
};

const OtherwiseModal = ({ open, setOpen, responses, variables, values, saveValues }: Props) => {
  const [contents, setContents] = useState<any[]>(values);

  return (
    <Modal
      open={open}
      title={<p className="text-xl font-bold">What should happen if the condition is not met?</p>}
      styles={{
        body: {
          height: "500px",
          overflowY: "auto",
        },
      }}
      width={800}
      centered
      onCancel={() => setOpen(false)}
      footer={
        <div className="flex gap-2 justify-between font-semibold text-lg">
          <button
            className="w-full border-black border p-2 hover:bg-gray-200 rounded-sm"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className="w-full !rounded bg-primary text-white p-2 hover:bg-dark disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => {
              saveValues(contents);
              setOpen(false);
            }}
          >
            Save
          </button>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {responses &&
          Array.isArray(responses) &&
          responses.map((response, idx) => {
            return (
              <div key={idx} className="flex flex-col gap-1">
                <div>
                  <p className="tracking-primary font-semibold">{response.name.toUpperCase()}</p>
                  <p className="text-sm font-light">{response.description}</p>
                </div>
                <TiptapField
                  content={
                    contents.find((content) => content.responseStructureId === response.responseStructureId)
                      ?.tiptapJson || ""
                  }
                  setContent={(content: any, text: string) => {
                    const temp = [...contents];
                    const index = temp.findIndex(
                      (content) => content.responseStructureId === response.responseStructureId
                    );
                    if (index > -1) {
                      temp[index] = {
                        responseStructureId: response.responseStructureId,
                        tiptapJson: content,
                        inputString: text,
                      };
                    } else {
                      temp.push({
                        responseStructureId: response.responseStructureId,
                        tiptapJson: content,
                        inputString: text,
                      });
                    }
                    setContents(temp);
                  }}
                  refresh={1}
                  placeholder={response.name}
                  variables={variables}
                  skipDataValues
                />
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default OtherwiseModal;
