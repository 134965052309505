import { useEffect, useState } from "react";
import { useBuilder } from "@/contexts/BuilderContext";
import Loader from "../Loader";
import { LOADER_TYPES } from "@/utils/constants";
import DraftCard from "./DraftCard";
import CreateDraft from "./Modals/CreateDraft";

const ExistingDrafts = () => {
  const [loading, setLoading] = useState(false);
  const [drafts, setDrafts] = useState<any[]>([]);
  const [create, setCreate] = useState(false);
  const { getAllDraftWorkflows } = useBuilder();

  useEffect(() => {
    setLoading(true);
    getAllDraftWorkflows().then((data) => {
      setDrafts(data);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className="h-full w-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow Libraries" />
      </div>
    );

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-10">
      <div className="flex flex-col gap-4">
        <div className="w-full flex justify-between">
          <span className="text-4xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
            Existing Drafts
          </span>
          <button
            onClick={() => setCreate(true)}
            className="bg-primary hover:bg-dark py-3 px-4 rounded-md font-semibold text-white"
          >
            Create Workflow
          </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-10">
          {drafts.length > 0 ? (
            drafts.map((workflow) => <DraftCard workflow={workflow} key={workflow.id} />)
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <div className="text-2xl font-semibold text-gray-400">No workflows available</div>
            </div>
          )}
        </div>
      </div>
      <CreateDraft modal={create} close={() => setCreate(false)} />
    </div>
  );
};

export default ExistingDrafts;
