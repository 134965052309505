import { useState } from "react";
import NameModal from "./NameModal";

type Props = {
  workflow: any;
};

const WorkflowCard = ({ workflow }: Props) => {
  const [more, setMore] = useState<boolean>(false);
  const [nameModal, setNameModal] = useState<boolean>(false);

  return (
    <div className="bg-white flex flex-col gap-4 p-8 rounded-lg shadow ">
      <div className="flex gap-3 w-full overflow-x-auto">
        {workflow.actions.map((action: string, idx: number) => {
          if (!action.includes("https")) return;
          else return <img className="w-10 h-10 rounded-full" src={action} alt={action} key={idx} />;
        })}
      </div>
      <div className="text-[22px] font-semibold">{workflow.name}</div>
      <div>
        <p className={`w-full text-sm font-light transition-all ${more ? "line-clamp-none" : "line-clamp-3"}`}>
          {workflow.description}
        </p>
        <button
          onClick={() => {
            setMore(!more);
          }}
          className="text-primary font-bold mt-1 text-sm hover:underline self-start"
        >
          Read {more ? "less" : "more"}
        </button>
      </div>
      <div>
        <p className="font-bold text-gray-500">Inputs:</p>
        <section className="w-full flex gap-2 flex-wrap">
          {(workflow.inputs as any[])
            .filter((input: string) => input !== "")
            .map((input: string, idx: number) => (
              <div
                className="border-[0.5px] border-gray-200 text-sm px-1 rounded text-gray-500 font-semibold w-fit hover:bg-gray-50 transition"
                key={idx}
              >
                {input}
              </div>
            ))}
        </section>
      </div>
      <button
        className="bg-primary p-2 mt-auto text-white rounded-lg font-semibold hover:bg-dark transition-all"
        onClick={() => setNameModal(true)}
      >
        Try Workflow
      </button>
      {nameModal && <NameModal id={workflow.id} close={() => setNameModal(false)} />}
    </div>
  );
};

export default WorkflowCard;
