// import useTiptap from "@/hooks/useTiptap";
// import { EditorContent } from "@tiptap/react";

import { CELL_STATUS_TYPE } from "@/utils/constants";
import React from "react";

interface Props {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  cellStatus: CELL_STATUS_TYPE;
  disabled?: boolean;
}

const Formatter = ({ content, setContent, cellStatus, disabled }: Props) => {
  return (
    <div className="w-full flex flex-col gap-6">
      {/* <EditorContent editor={editor} /> */}
      <textarea
        rows={20}
        className={`w-full  text-[#474747] !text-sm font-normal p-2 rounded-lg ${
          cellStatus === CELL_STATUS_TYPE.COMPLETED ? "bg-[#f6f6f6]" : "bg-white"
        } `}
        onChange={(e) => setContent(e.target.value)}
        value={content}
        disabled={disabled}
      />
    </div>
  );
};

export default Formatter;
