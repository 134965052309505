import { Tooltip } from "antd";
import Icon from "../../../assets/SVGs/newSettings.svg";
import { useUser } from "../../../contexts/UserContext";
import { Link, useLocation } from "react-router-dom";

const Settings = () => {
  const { collapsed } = useUser();
  const location = useLocation();
  const isSettingsPage = location.pathname === "/settings";

  if (collapsed) {
    return (
      <Tooltip
        title="Settings"
        placement="right"
        overlayClassName="border border-gray-200 rounded-sm font-inter"
        overlayInnerStyle={{
          fontWeight: 600,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          width: "fit-content",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontFamily: "Inter",
        }}
      >
        <Link
          className={`text-[#2a2a2a] rounded-md w-12 h-12 font-medium flex justify-center items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
            isSettingsPage && "bg-[#efe5ff]"
          }`}
          to="/settings"
          // title="Credits"
        >
          <img src={Icon} alt="Libs" />
        </Link>
      </Tooltip>
    );
  } else {
    return (
      <Link
        className={`text-[#2a2a2a] rounded-md w-[90%] h-[7%] font-medium flex justify-start items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 hover:bg-superchat-blue transition cursor-pointer lg:text-base sm:text-sm ${
          isSettingsPage && "bg-[#efe5ff]"
        }`}
        to="/settings"
        // title="Credits"
      >
        <img src={Icon} alt="settings" width={26} className=" text-gray-400 border-black" />
        <span className="lg:ml-1 sm:ml-0 font-semibold text-[18px]">Settings</span>
      </Link>
    );
  }
};

export default Settings;
