import { Modal } from "antd";
import { useState } from "react";

import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { cn } from "@/utils/cn";

type Props = {
  modal: boolean;
  close: () => void;
};

const CreateWorkflowFolder = ({ modal, close }: Props) => {
  const [workflowFolderName, setWorkflowFolderName] = useState("");
  const [error, setError] = useState<string>("");
  const { createNewWorkflowFolder } = useWorkflow();
  return (
    <Modal centered open={modal} onCancel={close} styles={{ footer: { display: "none" } }} width="50%">
      <div className="flex flex-col gap-4 p-4">
        <h1 className="text-[2rem] font-umuxGrot font-semibold mb-4 tracking-wider">Create Workflow Folder</h1>
        <div className="inputStyleCSS2 flex gap-2 w-full items-center">
          <label htmlFor="workflow-name" className="text-[22px] font-semibold w-fit text-nowrap">
            Folder Name:
          </label>
          <input
            type="text"
            name="workflow-name"
            id="workflow-name"
            placeholder="Enter workflow folder name"
            value={workflowFolderName}
            onChange={(e) => setWorkflowFolderName(e.target.value)}
            // className="p-2 border-1 border-gray-400 rounded-md w-full"
            className={cn(
              "focus:!outline-none !text-[22px]",
              "w-full rounded-md focus:!border-gray-500 focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black"
            )}
          />
        </div>

        <div className="text-red-500">{error}</div>
        <LoaderButtonBlack
          disabled={false}
          error=""
          loaderText="Creating Folder..."
          text="Create Folder"
          onClickCallback={async () => {
            if (!workflowFolderName.trim()) {
              setError("Folder Name cannot be empty");
              return;
            }
            const id = await createNewWorkflowFolder(workflowFolderName);
            if (id === "") setError("Error creating workflow Folder");
            window.location.reload();
          }}
          btnClasses="w-fit text-white bg-black hover:bg-gray-800"
        />
      </div>
    </Modal>
  );
};

export default CreateWorkflowFolder;
