import { PropsWithChildren, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useResizeObserver } from "@/hooks/use-resize-observer";
import { cn } from "@/utils/cn";

export const ScrollWithFadeOut = forwardRef(
  ({ children, className }: PropsWithChildren<{ className?: string }>, forwardedRef) => {
    const ref = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current);

    const [scrollProgress, setScrollProgress] = useState(1);

    const updateScrollProgress = useCallback(() => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const newScrollProgress = scrollHeight === clientHeight ? 1 : scrollTop / (scrollHeight - clientHeight);
      setScrollProgress(newScrollProgress);
    }, []);

    const resizeObserverEntry = useResizeObserver(ref);

    useEffect(updateScrollProgress, [resizeObserverEntry]);

    return (
      <>
        <div className={cn("overflow-y-scroll", className)} ref={ref} onScroll={updateScrollProgress}>
          {children}
        </div>
        {/* Bottom scroll fade */}
        <div
          className="pointer-events-none absolute bottom-0 left-0 hidden h-16 w-full bg-gradient-to-t from-white sm:block"
          style={{ opacity: scrollProgress === 1 ? 0 : 0.6 }}
        ></div>
      </>
    );
  }
);
