import { getBrowserTokens, handleUpdateTokens } from "@/utils/functions";
import { CREATE_CHAT_URL, GET_CHATIDS_URL, GET_CHAT_FOR_ID_URL, RAG_BASED_TEXT_GENERATION_URL } from "@/utils/urls";
import { createContext, useContext, useState } from "react";
import useStateRef from "react-usestateref";

export const ChatContext = createContext({
  selectedChat: { id: "", name: "" },
  setSelectedChat: (_val: { id: string; name: string }) => {},
  currChatDataRef: { current: {} as any },
  setCurrChatData: (_val: any) => {},
  getChatIds: async (_userWorkflowId: string) => {},
  getChatForId: async (_userWorkflowId: string, _chatId: string) => {
    return {} as any;
  },
  createChat: async (_dataIds: string[], _userWorkflowId: string, _chatName: string) => {
    return "";
  },
  triggerRAGTextGeneration: async (
    _chatId: string,
    _workflowId: string,
    _userPrompt: string,
    _promptGenerationTime: string,
    _promptUid: string
  ) => {
    return { response: "", responseGenerationTime: "", responseUid: "" };
  },
});

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: any) => {
  const [_, setCurrChatData, currChatDataRef] = useStateRef({});
  const [selectedChat, setSelectedChat] = useState({ id: "", name: "" });

  const getChatForId = async (userWorkflowId: string, chatId: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers,
        body: JSON.stringify({
          userWorkflowId,
          chatId,
          accessToken,
          refreshToken,
        }),
      };
      const res = await fetch(GET_CHAT_FOR_ID_URL, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) {
        throw new Error(data.message);
      }
      const chatObj = data.chatObj || {};
      let obj: any = currChatDataRef.current;
      obj = { ...obj, [chatId]: chatObj };
      setCurrChatData(() => obj);
      return chatObj;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getChatIds = async (userWorkflowId: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers,
        body: JSON.stringify({ userWorkflowId, accessToken, refreshToken }),
      };
      const res = await fetch(GET_CHATIDS_URL, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) {
        throw new Error(data.message);
      }
      const chatData: any[] = data.chatData || [];
      let obj: any = {};
      chatData.sort((a, b) => {
        const firstKey = Object.keys(a)[0];
        const aCreeatedAt = a[firstKey].createdAt;
        const secondKey = Object.keys(b)[0];
        const bCreeatedAt = b[secondKey].createdAt;
        return aCreeatedAt > bCreeatedAt ? -1 : 1;
        // return 1;
      });
      chatData.forEach((chatEle) => {
        const chatId = Object.keys(chatEle)[0];
        const chatObj = chatEle[chatId];
        obj = { ...obj, [chatId]: chatObj };
      });
      setCurrChatData(() => obj);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createChat = async (dataIds: string[], userWorkflowId: string, chatName: string) => {
    try {
      // return;
      const [accessToken, refreshToken] = getBrowserTokens();
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers,
        body: JSON.stringify({
          dataIds,
          userWorkflowId,
          chatName,
          accessToken,
          refreshToken,
        }),
      };
      const res = await fetch(CREATE_CHAT_URL, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) {
        throw new Error(data.message);
      }
      return data.chatId || "";
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const triggerRAGTextGeneration = async (
    chatId: string,
    workflowId: string,
    userPrompt: string,
    promptGenerationTime: string,
    promptUid: string
  ) => {
    try {
      // return;
      const [accessToken, refreshToken] = getBrowserTokens();
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers,
        body: JSON.stringify({
          workflowId,
          chatId,
          userPrompt,
          promptGenerationTime,
          promptUid,
          accessToken,
          refreshToken,
        }),
      };
      const res = await fetch(RAG_BASED_TEXT_GENERATION_URL, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) {
        throw new Error(data.message);
      }
      return {
        response: data.generatedResponse,
        responseGenerationTime: data.responseGenerationTime,
        responseUid: data.responseUid,
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const value = {
    selectedChat,
    setSelectedChat: (val: { id: string; name: string }) => setSelectedChat(() => val),
    currChatDataRef,
    setCurrChatData: (val: any) => setCurrChatData(() => val),
    getChatIds,
    getChatForId,
    createChat,
    triggerRAGTextGeneration,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
