import Icon from "../../../assets/SVGs/floqerLogo.svg";
import { useUser } from "../../../contexts/UserContext";

const Logo = () => {
  const { collapsed } = useUser();

  if (collapsed) {
    return (
      <div
        className={`text-[#5a5a5a] rounded-md h-[fit-content] font-medium flex justify-start items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 
          }`}
      >
        <img src={Icon} alt="Libs" />
      </div>
    );
  } else {
    return (
      <div
        className={`text-[#5a5a5a] rounded-md w-[90%] h-[fit-content] font-medium flex justify-start items-center gap-4 2xl:p-2 xl:p-1 lg:p-0.5 md:p-0 
          }`}
      >
        <img src={Icon} alt="Libs" className="h-8 w-8 border-black" />
        <span className="lg:ml-1 sm:ml-0 font-bold text-3xl text-[#3f3f3f]">Floqer</span>
      </div>
    );
  }
};

export default Logo;
