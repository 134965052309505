import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { MdCalendarToday, MdDataArray, MdDataObject, MdNumbers, MdTextFields } from "react-icons/md";
import { BASE_URL, TURBO_URL } from "./urls";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DISPLAY_DATE_TIME_FORMAT = "MMM DD, hh:mm A";
export const isProd = import.meta.env.VITE_IS_PROD === "true";
export const isV2 = import.meta.env.VITE_IS_V2 === "true";

export const GrayCrossIcon = () => (
  <svg
    style={{
      display: "inline-block",
      verticalAlign: "middle",
    }}
    width="20"
    height="20"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="" />
    <path d="M12 12L36 36M36 12L12 36" stroke="gray" strokeWidth="6" strokeLinecap="round" />
  </svg>
);

export const keyDownArr1 = ["KeyT", "KeyK", "KeyM", "KeyI", "KeyC", "KeyF", "KeyJ", "KeyL", "KeyP", "KeyN"];
// no shift, no alt, no ctrl, no meta
export const keyDownArr2 = [
  "Space",
  "Enter",
  "Comma",
  "Period",
  "Delete",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
];

export enum TabEnum {
  "build" = "build",
  "run" = "run",
  "table" = "table",
  "chat" = "chat",
}

export enum LOADER_TYPES {
  generating = "generating",
  loading = "loading",
  fetching = "fetching",
  specific = "specific",
  pasting = "pasting",
}

export const enum CELL_STATUS_TYPE {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
  PAYLOAD_FILLED = "payloadFilled",
  READY_TO_BE_REVIEWED = "readyToBeReviewed",
  REVIEWED = "reviewed",
  CHECKING_NEXT_SOURCE = "checkingNextSource",
  MISSING = "missing",
  RETRYING = "retrying",
}

export const CELL_STATUS_DISPLAY: { [key: string]: string } = {
  pending: "Pending",
  completed: "View",
  failed: "Failed",
  payloadFilled: "In progress",
  readyToBeReviewed: "Review",
  checkingNextSource: "Checking next source",
  retrying: "Retrying",
  conditionNotMet: "Condition not met",
};

export const CELL_STATUS_SYMBOL: { [key: string]: React.ReactNode } = {
  completed: "✅",
  failed: "❌",
  pending: "⏲",
  payloadFilled: <SpinnerStatus />,
  checkingNextSource: <SpinnerStatus />,
  readyToBeReviewed: "✔",
  retrying: <SpinnerStatus />,
  conditionNotMet: <GrayCrossIcon />,
};

export const TABLE_COLUMN_STATUS_FILTERS = [
  {
    text: <span>{CELL_STATUS_SYMBOL.completed} Completed</span>,
    value: "completed",
  },
  {
    text: <span>{CELL_STATUS_SYMBOL.failed} Failed</span>,
    value: "failed",
  },
  {
    text: <span>{CELL_STATUS_SYMBOL.payloadFilled} In Progress</span>,
    value: "payloadFilled",
  },
  {
    text: <span>{CELL_STATUS_SYMBOL.readyToBeReviewed} Ready to be reviewed</span>,
    value: "readyToBeReviewed",
  },
  {
    text: <span>{CELL_STATUS_SYMBOL.checkingNextSource} Checking next source</span>,
    value: "checkingNextSource",
  },
  {
    text: <span>{CELL_STATUS_SYMBOL.retrying} Retrying</span>,
    value: "retrying",
  },
  {
    text: <span className="inline-block">{CELL_STATUS_SYMBOL.conditionNotMet} Condition not met</span>,
    value: "conditionNotMet",
  },
];

export enum USER_ACTION_STATUS {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
  PAYLOAD_FILLED = "payloadFilled",
  READY_TO_BE_REVIEWED = "readyToBeReviewed",
  CHECKING_NEXT_SOURCE = "checkingNextSource",
  RETRYING = "retrying",
  CONDITION_NOT_MET = "conditionNotMet",
}

export enum ACTION_TYPES {
  ADD_SECTION = "add_section",
  AI = "AI",
  EXTRACT = "extract",
  DATA_ENRICHMENT = "data_enrichment",
  SENDING = "sending",
  SCRAPE_DATA = "scrape_data",
  INPUT = "input",
}

// export const ACTIONS_WITH_USER_KEY_TOGGLE = [
//   "GENERATE_TEXT",
//   "GENERATE_SUBJECT_USING_TEMPLATES_AND_PROMPT",
//   "GENERATE_EMAIL_USING_TEMPLATES_AND_PROMPT",
//   "ANALYZE_RISKS_FOR_10K_DOC",
//   "GROQ_GENERATE_TEXT",
//   "CLAUDE_GENERATE_TEXT"
// ];

export const NON_AI_ACTIONS_WITH_API_PRICIING_IDS = [
  "FIND_EMAIL_USING_NAME_AND_COMPANY_NAME",
  "FIND_WORK_EMAIL_BY_LINKEDIN_URL",
  "FIND_EMAIL_COMBINED",
];

export const ENGAGEBAY_SYSTEM_FIELDS = [
  {
    field_label: "First Name",
    field_name: "name",
    type: "SYSTEM",
  },
  {
    field_label: "Last Name",
    field_name: "last_name",
    type: "SYSTEM",
  },
  {
    field_label: "Email",
    field_name: "email",
    type: "SYSTEM",
  },
  {
    field_label: "Role",
    field_name: "role",
    type: "SYSTEM",
  },
  {
    field_label: "Phone Number",
    field_name: "phone",
    type: "SYSTEM",
  },
  {
    field_label: "Website",
    field_name: "website",
    type: "SYSTEM",
  },
  {
    field_label: "Address",
    field_name: "address",
    type: "SYSTEM",
  },
  {
    field_label: "Contact ID",
    field_name: "id",
    type: "SYSTEM",
  },
];

export const SALESFORCE_RESPONSE_NAME = "Records Found";
export const APOLLO_EMAIL_STATUS_RESPONSE_STRUCTURE_ID = "4dfd9c98-c018-48b1-b8da-20c9e4c2fc34";
export const APOLLO_EMAIL_RESPONSE_STRUCTURE_NAME = "729aa56a-a019-4712-a469-c11fc9bbb880";
export const OPENAI_PERSONAL_API_KEY_STRUCTURE_ID = "openaiApiKey";
export const CLAUDE_PERSONAL_API_KEY_STRUCTURE_ID = "claudeApiKey";
export const GROQ_PERSONAL_API_KEY_STRUCTURE_ID = "59237791-8107-4796-a68f-0adc81efa783";
export const OPENAI_MODELS_PAYLOAD_STRUCTURE_ID = "cc82c01d-e9be-415a-aa4a-7517693f4ff5";
export const OPENAI_ACTIONS_CONNECTION_ID = "openai";
export const EMAIL_VALIDATORS_STRUCTURE_ID = "emailValidation";

// payments stuff

export const pricings = {
  Starter: {
    monthly: {
      price: "$60",
      link: "https://buy.stripe.com/cN2dUkg4Z0sK8XSbIL",
      // link: "https://buy.stripe.com/test_dR6dTe2jy5Nm8IUbII",
    },
    yearly: {
      price: "$48",
      link: "https://buy.stripe.com/4gw17y061b7o3Dy8wA",
    },
    features: ["Personalization at Scale", "Store 100 Leads", "500 Emails Monthly", "Email Support"],
  },
  Growth: {
    monthly: {
      price: "$110",
      link: "https://buy.stripe.com/dR64jK1a57Vc2zu9AA",
    },
    yearly: {
      price: "$88",
      link: "https://buy.stripe.com/7sIdUk2e9fnEa1WeV2",
    },
    features: ["Personalization at Scale", "Store 500 Leads", "1,000 Emails Monthly", "Premium Support"],
  },
  HyperGrowth: {
    monthly: {
      price: "$299",
      link: "https://buy.stripe.com/3cs03u3id2AS0rmbIR",
    },
    yearly: {
      price: "$239",
      link: "https://buy.stripe.com/4gwg2sbOJejAde8aEO",
    },
    features: ["Personalization at Scale", "Store unlimited Leads", "Unlimited Emails Monthly", "Premium Support"],
  },
};

export const firebaseAccessToken = "firebase_access_token";

export const firebaseRefreshToken = "firebase_refresh_token";

export const chrome_extension_key = "mgpmocfddaldjiccjhdhbdefmhjeedoa";

export type setStateType<T> = React.Dispatch<React.SetStateAction<T>>;

export const ADMIN_EMAILS = ["avishjain0@gmail.com", "shivamtest@gmail.com", "admin@floqer.com"];

export const CHAT_FILLER_PAYLOAD = `Hey I am Floqer, I am here to help with any questions you have regarding your data in this table. You can ask me questions like: 
Who does management identify as a threat? Can I help them compete?
Does management think [what I’m solving for] is a risk?
Where does power reside in the org? Who are the execs & what is their contact info?`;

const bucketName = isProd ? "10-k_doc_sections_bucket_prod" : "10-k_doc_sections_bucket";
export const tenkDocFolder = `9dbf7f3a-59f5-46ea-a5d6-914c616fb655/`;

const workflowBucketName = isProd ? "workflow_response_bucket_prod" : "workflow_response_bucket";

export const FOLDERS_APPLICABLE_FOR_PROMPTS = isProd
  ? [`${bucketName}/${tenkDocFolder}`, `${workflowBucketName}`]
  : ["10-k_doc_sections_bucket/9dbf7f3a-59f5-46ea-a5d6-914c616fb655", "workflow_response_bucket/"];

export const getIconFromType = (type: string) => {
  switch (type) {
    case "string":
      return <MdTextFields />;
    case "number":
      return <MdNumbers />;
    case "date":
      return <MdCalendarToday />;
    case "array":
      return <MdDataArray />;
    case "sectionList":
      return <MdDataArray />;
    case "json":
      return <MdDataObject />;
    default:
      return <MdTextFields />;
  }
};

const getConditionsForType = (type: string) => {
  switch (type) {
    case "string":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
        {
          label: "starts with",
          value: "starts with",
        },
        {
          label: "ends with",
          value: "ends with",
        },
      ];
    case "number":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "greater than",
          value: "greater than",
        },
        {
          label: "less than",
          value: "less than",
        },
        {
          label: "greater than or equal to",
          value: "greater than or equal to",
        },
        {
          label: "less than or equal to",
          value: "less than or equal to",
        },
        {
          label: "is between",
          value: "is between",
        },
      ];
    case "date":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "is after",
          value: "is after",
        },
        {
          label: "is before",
          value: "is before",
        },
        {
          label: "is between",
          value: "is between",
        },
      ];
    case "array":
      return [
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
      ];
    case "sectionList":
      return [
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
      ];
    case "json":
      return [
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
      ];
    default:
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
      ];
  }
};

export const finalConditions = (type: string) => {
  return type
    ? [
        {
          value: "is empty",
          label: "is empty",
        },
        {
          value: "is not empty",
          label: "is not empty",
        },
        ...getConditionsForType(type),
      ]
    : [
        {
          value: "is empty",
          label: "is empty",
        },
        {
          value: "is not empty",
          label: "is not empty",
        },
      ];
};

export const inputTypeFromVariableType = (type: string) => {
  switch (type) {
    case "string":
      return "text";
    case "number":
      return "number";
    case "date":
      return "date";
    default:
      return "text";
  }
};

// Added for webhook endpoints so that even if we change the backend url, we don't have to update the db and just use the id param instead of using the entire url
// For existing endpoints which have the entire url, we will just extract the id from the url and use that
export const getEndpointFromNameAndId = (name: string, id: string) => {
  let newId = id;
  if (typeof id === "string" && (id.includes("https") || id.includes("http"))) newId = id.split("id=")[1];
  if (name.toLowerCase().includes("webhook")) return BASE_URL + (getV2() ? "/v2" : "") + "/trigger/webhook?id=" + newId;
  if (name.toLowerCase().includes("phantom"))
    return TURBO_URL + (getV2() ? "/v2" : "") + "/trigger/phantom?id=" + newId;
  if (name.toLowerCase().includes("engagebay"))
    return BASE_URL + (getV2() ? "/v2" : "") + "/trigger/engagebay?id=" + newId;
};

export const TABLE_ERROR_MESSAGES = ["Not enough credits", "Missing input data"];

export const WATERFALL_ACTIONS = [
  "FIND_WORK_EMAIL_BY_LINKEDIN_URL",
  "FIND_PHONE_NUMBER_BY_LINKEDIN_URL",
  "FIND_COMPANY_DOMAIN_BY_NAME",
  "FIND_EMAIL_USING_NAME_AND_COMPANY_NAME",
  "FIND_LINKEDIN_URL_BY_WORK_EMAIL",
  "FIND_EMAIL_COMBINED",
];

export const getV2 = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const v2 = searchParams.get("v2");
  return v2 === "true";
};
