import { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader";
import { LOADER_TYPES } from "../../utils/constants";
import Builder from "./Builder";
import { useBuilder } from "@/contexts/BuilderContext";
import DotsBg from "../DotsBg";

const WorkflowBuilder = () => {
  const [loading, setLoading] = useState(true);
  const [availableActions, setAvailableActions] = useState<any[]>([]);
  const { getDraftWorkflow, workflow } = useBuilder();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getDraftWorkflow(id).then((data) => {
      setAvailableActions(data);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow" />
      </div>
    );

  return (
    <DotsBg>
      <div className="w-full flex h-full">
        <div className="flex flex-col w-full items-center z-10">
          <section className="bg-transparent inset-0 flex self-start p-4">
            <Link
              to="/builder"
              className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
            >
              <MdOutlineKeyboardBackspace size={30} />
              <span>{workflow.name}</span>
            </Link>
          </section>
          <Builder availableActions={availableActions} />
        </div>
      </div>
    </DotsBg>
  );
};

export default WorkflowBuilder;
