import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_EMAILS, LOADER_TYPES } from "./utils/constants";
import {
  Connections,
  CreateWorkflow,
  GoogleRedirect,
  HubSpotRedirect,
  Login,
  Payments,
  ProfileSetup,
  Settings,
  SignUpOrg,
  SingleWorkflow,
  WorkflowBuilder,
  WorkflowLibs,
  Workflows,
  Pricings,
  SalesForceRedirect,
} from "./pages/index.ts";
import PrivateRoutes from "./utils/PrivateRoutes";
import { useUser } from "./contexts/UserContext";
import Loader from "./Components/Loader";
import SlackRedirect from "./pages/Auth/SlackRedirect.tsx";
import CreditsNew from "./pages/CreditsNew.tsx";
import "@radix-ui/themes/styles.css";

function App() {
  const { currentUser, userLoad, checkProfileAndPayment } = useUser();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(false);
  // const [paymentPending, setPaymentPending] = useState(false);
  const paymentPending = false;
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      // let prof = false,
      // payp = true;
      if (currentUser) {
        const res = await checkProfileAndPayment();
        setProfile(res.profile);
        if (!res.profile) setLoading(false);
        // prof = res.profile;
        // setPaymentPending(res.paymentPending);
        // payp = res.paymentPending;
      } else if (!userLoad) {
        setLoading(false);
      }
      // setLoading(false);
      // if (currentUser || (prof && !payp)) {
      //   refreshExe().then(() => {
      //     console.log("extension refreshed from the application side");
      //   });
      // }
    };

    setLoading(true);
    fetchData();
  }, [currentUser, refresh, userLoad]);

  useEffect(() => {
    if (profile) setLoading(false);
  }, [profile]);

  // useEffect(() => {
  //   console.log("userload --> ", userLoad, "  loading --> ", loading)
  // }, [userLoad, loading])

  if (userLoad || loading) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <Loader payload={"buildpipeline"} loaderType={LOADER_TYPES.loading} />
      </div>
    );
  }
  return (
    <Routes>
      <Route element={<PrivateRoutes profile={profile} paymentPending={paymentPending} />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/workflowLibs" element={<WorkflowLibs />} />
        <Route path="/workflows" element={<Workflows />} />
        <Route path="/workflow/:id" element={<SingleWorkflow />} />
        <Route path="/connections" element={<Connections />} />
        <Route path="/credits" element={<CreditsNew />} />

        <Route path="/builder" element={ADMIN_EMAILS.includes(currentUser) ? <CreateWorkflow /> : <></>} />
        <Route path="/builder/:id" element={ADMIN_EMAILS.includes(currentUser) ? <WorkflowBuilder /> : <></>} />
      </Route>
      <Route path="/gauth" element={<GoogleRedirect />} />
      <Route path="/hubspotAuth" element={<HubSpotRedirect />} />
      <Route path="/oauth2/callback" element={<SalesForceRedirect />} />
      <Route path="/slackAuth" element={<SlackRedirect />} />
      <Route
        path="/setup"
        element={
          !currentUser ? (
            <Navigate to="/" />
          ) : !profile ? (
            <ProfileSetup setRefresh={setRefresh} />
          ) : paymentPending ? (
            <Navigate to="/pricing" />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
      <Route path="/org" element={<SignUpOrg />} />
      <Route path="/pricings" element={<Pricings />} />
      <Route
        path="/pricing"
        element={
          !currentUser ? (
            <Navigate to="/" />
          ) : !profile ? (
            <Navigate to="/setup" />
          ) : paymentPending ? (
            <Payments />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
      <Route
        path="/"
        element={
          !currentUser ? (
            <Login />
          ) : !profile ? (
            <Navigate to="/setup" />
          ) : paymentPending ? (
            <Navigate to="/pricing" />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
    </Routes>
  );
}

export default App;
