import Navbar from "../Components/Navbar";
import { useUser } from "../contexts/UserContext";
import SettingIndex from "../Components/Settings";

const SettingsPage = () => {
  const { collapsed } = useUser();

  return (
    <div className="h-screen flex flex-row justify-start">
      <div
        className={`fixed h-screen ${collapsed ? "w-[5%]" : "w-[20%]"} transition-all duration-500`}
        style={{
          transitionProperty: "all",
        }}
      >
        <Navbar />
      </div>
      <div
        className={`relative ${
          collapsed ? "w-[95%] left-[5%]" : "w-[80%] left-[20%]"
        } transition-all duration-500 bg-[#FBF8FF] p-10 overflow-auto h-full`}
        style={{
          transitionProperty: "all",
        }}
      >
        <SettingIndex />
      </div>
    </div>
  );
};

export default SettingsPage;
