import LoaderButton from "@/Components/LoaderButton";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { getWorkflowDetailsWithActions, publishDraft2 } from "@/utils/apis";
import { ResponseConfiguration } from "@/utils/interfaces";
import { Modal } from "antd";
import { FaClipboard, FaPlus } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";

type addInputColumnsModalsProps = {
  modal: boolean;
  responses: ResponseConfiguration[];
  selectedWorkflow: string;
  inputs: any;
  setInputs: React.Dispatch<React.SetStateAction<any>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setResponses: React.Dispatch<React.SetStateAction<ResponseConfiguration[]>>;
  saveOutputStructure: () => Promise<void>;
};

/**
 *
 * @description
 * This component is used to add input columns to a workflow.
 * case 1: initial input cols = 0
 * 1. add new input columns into workflow
 * case 2: initial input cols > 0
 * 1. push new input columns into existing inputs into workflow
 */

export const AddInputColumnsModal = ({
  modal,
  responses,
  selectedWorkflow,
  inputs,
  setInputs,
  setModal,
  setResponses,
  saveOutputStructure,
}: addInputColumnsModalsProps) => {
  const { saveAnotherWorkflowActions } = useWorkflow();

  const cleanupModalState = () => {
    setResponses([]);
    setModal(false);
  };

  const handlePaste = (e: any, index: number, field: string) => {
    e.preventDefault(); // Prevent the default paste behavior
    const pastedData = e.clipboardData.getData("text"); // Get clipboard data
    // Split the pasted data by new lines (assuming each line is a row)
    const lines = pastedData.split("\t").filter((line: any) => line.trim() !== ""); // Avoid empty lines
    const updatedRows: any[] = [...responses];
    // Update the rows starting from the pasted input's index
    lines.forEach((line: any, lineIndex: number) => {
      if (index + lineIndex < responses.length) {
        // Update existing rows
        updatedRows[index + lineIndex][field] = line;
      } else {
        // Add new rows if needed
        const newRow: any = { name: "", description: "" };
        newRow[field] = line;
        updatedRows.push(newRow);
      }
    });
    setResponses(updatedRows);
  };

  return (
    <Modal
      centered
      open={modal}
      onCancel={cleanupModalState}
      width={500}
      className="rounded-lg overflow-hidden"
      title={
        <div className="px-2 py-1">
          <div className="flex gap-2 items-center text-xl">
            <FaPlus className="text-primary" />
            <span className="font-semibold">Add Inputs</span>
          </div>
          <p className="text-sm text-gray-500 mt-1">Decide input columns to add to your workflow</p>
        </div>
      }
      footer={
        <div className="flex gap-3 justify-between px-2 py-1">
          <button
            className="w-full px-6 py-2 rounded-lg border border-gray-300 font-medium hover:bg-gray-50 transition-colors"
            onClick={cleanupModalState}
          >
            Cancel
          </button>
          {/* add new inputs & push existing inputs into workflow */}
          <LoaderButton
            text="Add"
            loaderText="Adding..."
            btnClasses="w-full px-6 py-2 rounded-lg bg-primary hover:bg-primary/90 text-white font-medium transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            onClickCallback={async () => {
              setInputs({ loading: true, fields: [...inputs.fields] });
              let dashes = selectedWorkflow.split("-");
              let isV2 = dashes.length === 5 ? true : false;
              const filteredResponses = responses?.filter((res) => {
                return !!res?.name;
              });
              const responseData = await getWorkflowDetailsWithActions(selectedWorkflow, isV2);
              const action = responseData.response.actions.find((action: any) => action.id === "id1");
              const updatedConfiguration = filteredResponses.map((config) => ({
                responseId: config.responseId || uuidv4(), // Keep existing ID or generate a new one if it's not present
                ...config,
              }));
              if (inputs.fields.length > 0) action.responseConfiguration.push(...updatedConfiguration);
              else action.responseConfiguration = updatedConfiguration;
              setInputs({
                loading: false,
                fields: [...inputs.fields.concat(updatedConfiguration)],
              });
              await saveOutputStructure();
              await saveAnotherWorkflowActions(selectedWorkflow || "", [action], isV2);
              await publishDraft2(selectedWorkflow, isV2);
              cleanupModalState();
            }}
            error=""
            disabled={responses.length === 0}
          />
        </div>
      }
    >
      <div className="px-3 py-3">
        <div className="space-y-6">
          {/* Input Columns Section */}
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <div>
                <label className="text-sm font-medium text-gray-700">Input Columns</label>
                <p className="text-xs text-gray-500 mt-0.5">Paste directly into any field or click + to add new rows</p>
              </div>
              <span className="text-xs text-gray-500">{responses.length} columns</span>
            </div>

            <div className="rounded-lg border border-gray-200 bg-gray-50">
              <div
                className={
                  responses.length > 0
                    ? "max-h-[240px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
                    : ""
                }
                id="inputs-container"
              >
                <div className="p-4 space-y-4">
                  {responses.map((response, index) => (
                    <div
                      key={index}
                      id={`input-${index}`}
                      className="bg-white rounded-lg p-3 border border-gray-200 shadow-sm transition-all hover:border-primary/30"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-medium text-gray-600">Input {index + 1}</span>
                        <button
                          className="p-1.5 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors"
                          onClick={() => {
                            const temp = [...responses];
                            temp.splice(index, 1);
                            setResponses(temp);
                          }}
                          title="Remove input"
                        >
                          <MdDeleteOutline size={20} />
                        </button>
                      </div>
                      <div className="relative">
                        <input
                          className="w-full px-3 py-2 pr-10 rounded-lg border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors outline-none text-sm"
                          type="text"
                          value={response.name}
                          onChange={(e) => {
                            const temp = [...responses];
                            temp[index].name = e.target.value;
                            setResponses(temp);
                          }}
                          onPaste={(e) => handlePaste(e, index, "name")}
                          placeholder="Enter field name or paste multiple values"
                        />
                        <div className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400">
                          <FaClipboard size={14} />
                        </div>
                      </div>
                    </div>
                  ))}

                  {responses.length === 0 && (
                    <div className="flex flex-col items-center py-6">
                      <div
                        className="bg-white border-2 border-dashed border-gray-300 rounded-lg p-4 mb-4 max-w-sm w-full text-center transition-all duration-300 hover:border-primary hover:shadow-md group cursor-pointer"
                        onPaste={(e) => handlePaste(e, 0, "name")}
                      >
                        <FaClipboard
                          className="mx-auto text-gray-400 mb-2 group-hover:text-primary transition-colors duration-300"
                          size={24}
                        />
                        <h3 className="text-sm font-medium text-gray-700 mb-1 group-hover:text-primary">
                          Paste Column Names
                        </h3>
                        <p className="text-xs text-gray-500 group-hover:text-gray-600">
                          Copy columns from a spreadsheet and paste them here
                        </p>
                        <div className="mt-2 text-xs text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Press Ctrl+V to paste
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          const temp = [...responses];
                          temp.push({
                            name: "",
                            description: "",
                          });
                          setResponses(temp);

                          setTimeout(() => {
                            const container = document.getElementById("inputs-container");
                            const newInput = document.getElementById(`input-${temp.length - 1}`);
                            if (container && newInput) {
                              container.scrollTo({
                                top: newInput.offsetTop,
                                behavior: "smooth",
                              });
                            }
                          }, 100);
                        }}
                        className="px-4 py-2 rounded-lg bg-white border border-gray-300 hover:border-primary hover:bg-primary/5 text-sm font-medium transition-colors flex items-center gap-2"
                      >
                        <FaPlus size={14} />
                        Add First Input
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {responses.length > 0 && (
                <div className="p-3 border-t border-gray-200">
                  <button
                    className="w-full py-2 px-4 rounded-lg bg-white hover:bg-gray-50 border border-gray-300 hover:border-gray-400 text-sm font-medium transition-colors flex items-center justify-center gap-2"
                    onClick={() => {
                      const temp = [...responses];
                      temp.push({
                        name: "",
                        description: "",
                      });
                      setResponses(temp);

                      setTimeout(() => {
                        const container = document.getElementById("inputs-container");
                        const newInput = document.getElementById(`input-${temp.length - 1}`);
                        if (container && newInput) {
                          container.scrollTo({
                            top: newInput.offsetTop,
                            behavior: "smooth",
                          });
                        }
                      }, 100);
                    }}
                  >
                    <FaPlus size={14} />
                    Add Input Column
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
