import { useWorkflow } from "@/contexts/WorkflowContext";
import { Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import Markdown from "react-markdown";
type Props = {
  keyName: string;
  value: any;
  open: boolean;
};

const KeyValue = ({ keyName, value, open }: Props) => {
  const [actValue, setActValue] = useState(value);
  const [isObject, setIsObject] = useState(value && typeof value === "object");
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { getDataStoreValue } = useWorkflow();

  useEffect(() => {
    if (isObject && actValue && actValue.path && actValue.path.length > 1) {
      getDataStoreValue(actValue.path[1]).then((res) => {
        setActValue(res);
      });
    }
  }, [value]);

  useEffect(() => {
    setIsOpen(false);
    setIsObject(actValue && typeof actValue === "object");
    setCopied(false);
  }, [open, actValue]);

  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(actValue).then(() => {
      setCopied(true);
    });
  }, [actValue]);

  return (
    <>
      <div
        className={`p-2 border border-gray-200 rounded flex items-center flex-wrap gap-2
      [&_*]:max-w-full [&_*]:overflow-x-auto relative group ${
        keyName === "Risk assessment doc" ? "bg-white" : "bg-gray-100"
      } `}
      >
        {isObject ? (
          !isOpen ? (
            <button onClick={() => setIsOpen(true)}>
              <IoIosArrowForward />
            </button>
          ) : (
            <button onClick={() => setIsOpen(false)}>
              <IoIosArrowDown />
            </button>
          )
        ) : null}
        <span className="font-semibold text-lg self-start">{keyName}</span>
        {!isObject ? (
          <span className="text-gray-500 text-start">
            <Markdown>{actValue?.toString() ?? ""}</Markdown>
          </span>
        ) : (
          <span className="text-gray-500">
            {actValue.length !== undefined ? `[${actValue.length}]` : `{${Object.keys(actValue).length}}`}
          </span>
        )}
        {keyName === "Work Email" && (
          <Tooltip
            placement="bottomRight"
            title={copied ? "Copied!" : "Copy to clipboard"}
            arrow={false}
            overlayClassName="border border-gray-200 rounded-md"
            overlayInnerStyle={{
              fontWeight: 600,
              backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
              color: `${copied ? "#f3f4f6" : "#6c727f"}`,
              fontSize: "0.75rem",
            }}
          >
            <button
              className="opacity-0 group-hover:opacity-100 transition absolute top-2 right-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
              onClick={copyEmail}
            >
              <FaRegCopy className="text-gray-600" />
            </button>
          </Tooltip>
        )}
      </div>
      {isOpen ? (
        <div className="flex flex-col gap-2 ml-6">
          {Object.entries(actValue).map(([key, val]: [string, any]) => (
            <KeyValue key={key} keyName={key} value={val} open={open} />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default KeyValue;
