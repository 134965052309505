/*global chrome*/
import { createContext, useContext } from "react";
import {
  createDraftWorkflowURL,
  getAllDraftWorkflowsURL,
  getDraftWorkflowURL,
  publishDraftWorkflowURL,
  saveDraftWorkflowURL,
} from "../utils/urls";
import { getBrowserTokens, handleUpdateTokens } from "../utils/functions";
import { setStateType } from "@/utils/constants";
import { v4 as uuidv4 } from "uuid";
import useStateRef from "react-usestateref";

export const BuilderContext = createContext({
  workflow: {} as any,
  setWorkflow: {} as setStateType<any>,
  updateResponseConfig: (_responses: any[], _actionId: string) => {
    return [] as any[];
  },
  updatePayloadConfig: (_payload: any[], _actionId: string, _review: boolean) => {
    return [] as any[];
  },
  deleteActionFormDraft: async (_id: string, _did: string) => {},
  getAllDraftWorkflows: async () => {
    return [] as any[];
  },
  getDraftWorkflow: async (_draftId: string) => {
    return [] as any[];
  },
  createDraftWorkflow: async (_workflow: any) => {
    return "";
  },
  saveDraftWorkflow: async (_draftId: string, _workflow: any) => {},
  publishDraftWorkflow: async (_draftId: string) => {},
});

export const useBuilder = () => useContext(BuilderContext);

export default function BuilderContextProvider({ children }: any) {
  const [workflow, setWorkflow, workflowRef] = useStateRef<any>({});

  const updateResponseConfig = (responses: any[], actionId: string): any => {
    const action = workflow.actions[actionId];
    const updatedConfiguration = responses.map((config) => ({
      ...config,
      responseId: config.responseId || uuidv4(), // Keep existing ID or generate a new one if it's not present
    }));
    action.responseConfiguration = updatedConfiguration;

    setWorkflow({
      ...workflow,
      actions: {
        ...workflow.actions,
        [actionId]: action,
      },
    });
    return updatedConfiguration;
  };

  const updatePayloadConfig = (payload: any[], actionId: string, review: boolean): any => {
    const action = workflow.actions[actionId];
    action.payloadConfiguration = payload;
    action.reviewNeeded = review;

    setWorkflow({
      ...workflow,
      actions: {
        ...workflow.actions,
        [actionId]: action,
      },
    });
    return payload;
  };

  const deleteActionFormDraft = async (id: string, draftId: string) => {
    const actions = { ...workflow.actions };
    const action = actions[id];
    let prevId = "";
    Object.entries(actions).map(([aid, act]: [string, any]) => {
      if (act.nextAction.includes(id)) prevId = aid;
    });

    delete actions[id];

    const newDraft = {
      ...workflow,
      actions: {
        ...actions,
        [prevId]: {
          ...actions[prevId],
          nextAction: action.nextAction,
        },
      },
    };
    // delete newDraft.actions[id];
    setWorkflow(newDraft);
    await saveDraftWorkflow(draftId || "", newDraft);
  };

  const getAllDraftWorkflows = async (): Promise<any[]> => {
    try {
      const url = getAllDraftWorkflowsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      return responseData.response;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getDraftWorkflow = async (draftId: string): Promise<any[]> => {
    try {
      const url = getDraftWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(`${url}/${draftId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      setWorkflow(responseData.response.workflow);
      return responseData.response.actions;
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
      return [];
    }
  };

  const createDraftWorkflow = async (workflow: any): Promise<string> => {
    try {
      const url = createDraftWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          workflow,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.draftId;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to copy workflow");
    }
  };

  const saveDraftWorkflow = async (draftId: string, workflow: any): Promise<any> => {
    try {
      const url = saveDraftWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          workflow,
        }),
      };
      const response = await fetch(`${url}/${draftId}`, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      // return responseData.draftId;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to copy workflow");
    }
  };

  const publishDraftWorkflow = async (draftId: string): Promise<any> => {
    try {
      const url = publishDraftWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "PATCH",
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(`${url}/${draftId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
    }
  };

  const value = {
    workflow: workflowRef.current,
    setWorkflow,
    updateResponseConfig,
    updatePayloadConfig,
    deleteActionFormDraft,
    getAllDraftWorkflows,
    getDraftWorkflow,
    createDraftWorkflow,
    saveDraftWorkflow,
    publishDraftWorkflow,
  };

  return <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>;
}
