import "../../../../styles/select.css";

import { Select } from "antd";
import { RiExpandUpDownLine } from "react-icons/ri";

interface CustomDropdownProps {
  payloads: any;
  setPayloads: any;
  field: any;
  action: any;
}

export default function CustomDropdown({ payloads, setPayloads, field, action }: CustomDropdownProps): JSX.Element {
  return (
    <Select
      suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
      placeholder="Select field type"
      className="custom-dropdown w-full h-10"
      onChange={(value) => {
        const temp = [...payloads];
        const index = payloads.findIndex((input: any) => input.payloadStructureId === field.payloadStructureId);
        temp[index].inputString.push({
          name: "",
          value: "",
          type: value,
        });
        setPayloads(temp);
      }}
      options={
        !(action.actionName.includes("HTTP_API") && field.payloadStructureId === "bodyId")
          ? [{ label: "Variable Name and Variable Value Pair", value: "json_array" }]
          : [
              { label: "Variable Name and Variable Value Pair", value: "json_array" },
              { label: "Raw JSON", value: "raw_json" },
            ]
      }
      optionRender={(option) => (
        <div
          className={`flex items-center justify-between text-center h-11 px-1.5 py-3 ${
            option.value === "raw_json" && "border-t"
          }`}
        >
          <div>{option.label}</div>
        </div>
      )}
      dropdownRender={(menu) => <div className="custom-dropdown-menu">{menu}</div>}
    />
  );
}
