import { useState } from "react";
import LoaderButton from "../LoaderButton";
import CheckCircle from "../../assets/SVGs/CheckCircle.svg";
import { setStateType } from "../../utils/constants";
import { useUser } from "../../contexts/UserContext";

type Props = {
  setReset: setStateType<boolean>;
  email: string;
};

const ResetPassword = ({ setReset, email }: Props) => {
  const [sent, setSent] = useState(false);
  const { resetPassword } = useUser();

  const handleReset = async () => {
    await resetPassword(email);
    setSent(true);
  };
  return (
    <div className="h-screen w-screen bg-transparent backdrop-blur-md flex items-center justify-center fixed inset-0 z-10">
      <div className="flex flex-col justify-center border border-[#ccc] rounded-2xl p-10 gap-4 sm:w-full lg:w-[500px] xl:w-[700px] shadow-lg z-20 bg-white">
        {sent ? (
          <>
            <div className="flex flex-row items-center gap-2">
              <img src={CheckCircle} alt="check" className="h-7 w-7" />
              <span className="text-2xl font-semibold">We have sent you a password reset link.</span>
            </div>
            <button
              className="bg-white hover:bg-gray-100 transition rounded-lg p-4 ml-auto text-primary border-2 border-primary"
              onClick={() => setReset(false)}
            >
              Go back
            </button>
          </>
        ) : (
          <>
            <span className="text-2xl font-semibold">Are you sure you want to reset your password?</span>

            <div className="flex gap-4">
              <button
                className="bg-white hover:bg-gray-200 transition rounded-lg p-4 ml-auto"
                onClick={() => setReset(false)}
              >
                Go back
              </button>
              <LoaderButton
                text={"Yes"}
                loaderText={"Sending the reset mail..."}
                onClickCallback={handleReset}
                disabled={false}
                error=""
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
