import React from "react";

interface Props {
  width?: string;
}

const FloqerSVG: React.FC<Props> = ({ width }) => {
  return (
    <svg
      width={width || "42"}
      height={width || "42"}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" y="0.75" width="40.5" height="40.5" rx="20.25" fill="#E8E7FF" stroke="#ABABAB" strokeWidth="0.5" />
      <rect x="0.75" y="0.75" width="40.5" height="40.5" rx="20.25" fill="#E8E7FF" stroke="#ABABAB" strokeWidth="0.5" />
      <path
        d="M16.2012 9.7998L18.149 11.6502C18.8794 12.332 19.269 13.2572 19.269 14.2798C19.269 15.2537 19.6585 16.1789 20.3403 16.8606L20.5837 17.1041L22.8237 9.8485"
        fill="#8665FF"
      />
      <path
        d="M9.94531 16.471L12.6236 16.3736C13.5975 16.3249 14.5714 16.7145 15.2531 17.3962C15.9348 18.0779 16.86 18.4188 17.8339 18.4188H18.1748L14.5714 11.7476"
        fill="#8665FF"
      />
      <path
        d="M9.94687 25.7233L11.7486 23.7755C12.4303 23.045 13.3555 22.6068 14.3294 22.6068C15.3033 22.6068 16.1799 22.1685 16.8616 21.4868L17.1051 21.2433L9.80078 19.1494"
        fill="#8665FF"
      />
      <path
        d="M16.5689 32.1512L16.4228 29.473C16.3741 28.4991 16.715 27.5252 17.3967 26.7948C18.0297 26.113 18.4193 25.1391 18.3706 24.2139V23.873L11.748 27.6226"
        fill="#8665FF"
      />
      <path
        d="M25.8239 31.9562L23.8274 30.2032C23.0969 29.5701 22.6587 28.6449 22.61 27.6223C22.5613 26.6484 22.123 25.7719 21.4413 25.0902L21.1978 24.8467L19.25 32.1997"
        fill="#8665FF"
      />
      <path
        d="M32.1511 25.1876L29.4729 25.3824C28.499 25.4797 27.5251 25.1389 26.7946 24.4571C26.0642 23.8241 25.139 23.4832 24.1651 23.5319H23.8242L27.7198 30.0571"
        fill="#8665FF"
      />
      <path
        d="M31.7136 15.9839L30.0092 18.0291C29.3762 18.7595 28.451 19.2465 27.4771 19.2952C26.5032 19.3439 25.6266 19.7821 24.9936 20.5125L24.7988 20.756L32.2005 22.5091"
        fill="#8665FF"
      />
      <path
        d="M24.8487 9.7998L25.0922 12.478C25.1896 13.4519 24.8975 14.4259 24.2157 15.205C23.5827 15.9354 23.2905 16.8606 23.3392 17.8345L23.3879 18.1754L29.8157 14.1337"
        fill="#8665FF"
      />
    </svg>
  );
};

export default FloqerSVG;
