import React, { useEffect, useState } from "react";
import { ConfigProvider, Table } from "antd";
import type { GetProp, TableProps } from "antd";

type Props = {
  rows: any[];
  columns: any[];
  setHeaders: any;
};

type TablePaginationConfig = Exclude<GetProp<TableProps, "pagination">, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
}

const TableComponent = ({ rows, columns, setHeaders }: Props) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      total: 0,
    },
  });

  const handleTableChange = (page: number, pageSize: number) => {
    setTableParams((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          current: page,
          pageSize: pageSize,
        },
      };
    });
  };

  useEffect(() => {
    setTableParams((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          total: rows.length,
        },
      };
    });
  }, [rows]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKey: React.Key[], selectedRow: any[]) => {
      console.log(`selectedRowKey: ${selectedRowKey}`, "selectedRow: ", selectedRow);
      setHeaders(selectedRow[0]);
    },
  };

  return (
    <div className="relative w-full h-full overflow-auto py-2 ">
      <ConfigProvider>
        <Table
          rowSelection={{
            type: "radio",
            ...rowSelection,
            defaultSelectedRowKeys: [1],
          }}
          columns={columns}
          dataSource={rows}
          showHeader={false}
          bordered
          pagination={{
            ...tableParams.pagination,
            onChange: handleTableChange,
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableComponent;
