import CloseButton from "@/Components/Generics/CloseButton";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { useChat } from "@/contexts/ChatContext";
import { TabEnum, setStateType } from "@/utils/constants";
import { Input } from "antd";
import { useState } from "react";

// const { Search  } = Input;

interface Props {
  setChatNameModal: setStateType<boolean>;
  disabled?: boolean;
  selected: string[];
  userWorkflowId: string;
  setTab: setStateType<TabEnum>;
}

const ChatNameModal: React.FC<Props> = ({ setChatNameModal, disabled, selected, userWorkflowId, setTab }) => {
  const { createChat, setSelectedChat } = useChat();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [err, setErr] = useState<string | null>(null);

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (name === "") throw new Error("Chat name is required");
      if (selected.length === 0) throw new Error("Select at least one completed row to start a chat");
      setLoading(true);
      const chatId = await createChat(selected, userWorkflowId, name);
      setSelectedChat({ id: chatId, name });
      setTab(TabEnum.chat);
      setChatNameModal(false);
    } catch (err) {
      setErr((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 h-screen w-screen flex flex-col items-center justify-normal bg-black bg-opacity-10 z-[101]">
      <form
        className="mt-12 md:mt-20 lg:mt-24 flex flex-col items-start gap-4 relative rounded-md bg-white min-h-32 max-h-fit shadow-lg p-8 w-[90%] sm:w-3/5 md:w-1/2 lg:w-1/3 max-w-[1200px]"
        onSubmit={formSubmit}
      >
        <CloseButton onClick={() => setChatNameModal(false)} disabled={disabled} />
        <header className="font-semibold text-lg">Name of the chat instance:</header>
        <span className="flex items-center justify-between w-full">
          <Input
            placeholder="Enter chat name"
            // loading={loading}
            onChange={(e) => setName(e.target.value)}
            status={err ? "" : "error"}
            classNames={{
              input: "!border !border-gray-300 !rounded-lg !focus:border-red-400 ",
              suffix: "hidden",
            }}
            className="flex-grow w-full"
          />
        </span>
        {err && <span className="text-red-500 font-light text-xs mt-[-0.5rem]">{err}</span>}
        <button
          type="submit"
          className={`px-4 py-2 rounded-lg flex items-center justify-center gap-2 font-semibold  
           transition text-lg  ml-auto ${loading ? "bg-white text-gray-400" : "bg-primary hover:bg-dark text-white"}`}
          disabled={loading}
        >
          {loading && <SpinnerStatus white={false} />}
          <span>Create Chat</span>
        </button>
      </form>
    </div>
  );
};

export default ChatNameModal;
