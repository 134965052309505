const AddAction = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="group-hover:text-purple-600 h-3 w-3 text-grey-600 hover:text-white"
    >
      <g clipPath="url(#clip0_780_1774)">
        <path
          d="M6 10.75V1.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.75 6H1.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_780_1774">
          <rect width="12" height="12" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddAction;
