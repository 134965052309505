import { setStateType } from "@/utils/constants";
import { Action, PayloadConfiguration, ResponseConfiguration, ResponseStructure } from "@/utils/interfaces";
import { ConfigProvider, Drawer, Select } from "antd";
import { useEffect, useState } from "react";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { SALESFORCE_RESPONSE_NAME } from "../../../utils/constants";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: ResponseConfiguration[];
  variables: any[];
};
const AddSection = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [sectionList, setSectionList] = useState<any[]>([]);
  const [vars, setVars] = useState<any[]>([]);
  const [salesforce, setSalesforce] = useState<{
    is: boolean;
    objects: any[];
    objectsLoading: boolean;
    fields: any[];
    fieldsLoading: boolean;
  }>({
    is: false,
    objects: [],
    objectsLoading: false,
    fields: [],
    fieldsLoading: false,
  });
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const { updatePayloadConfig, updateResponseConfig, saveWorkflowActions, getSalesforceObjects, getSfObjectsFields } =
    useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setResponses(responseConfiguration);
  }, [payloadConfiguration, responseConfiguration]);

  useEffect(() => {
    const sectionListPayload = payloads.find((payload) => payload.payloadStructureId === "sectionListId");
    if (!sectionListPayload) return;
    if (sectionListPayload.selectedList === SALESFORCE_RESPONSE_NAME) setSalesforce((prev) => ({ ...prev, is: true }));
    else setSalesforce((prev) => ({ ...prev, is: false }));

    // Load Salesforce objects if needed
    if (salesforce.is && !salesforce.objectsLoading && salesforce.objects.length === 0) {
      setSalesforce((prev) => ({ ...prev, objectsLoading: true }));
      getSalesforceObjects().then((objs: any) => {
        setSalesforce((prev) => ({
          ...prev,
          objects: objs.sfObjects,
          objectsLoading: false,
        }));
      });
    }

    if (sectionListPayload.inputString) {
      const listId = sectionListPayload.inputString?.split("{{")[1]?.split("}}")[0];
      const objectId = sectionListPayload.salesforceObjectId;

      let list: any[] = [];
      variables.forEach((action) => {
        action.variables.forEach((variable: any) => {
          if (variable.responseId === listId) {
            if (variable.name === SALESFORCE_RESPONSE_NAME && objectId) {
              getSfObjectsFields(objectId).then((fields: any) => {
                const fieldList = fields.map((field: any) => ({
                  responseStructureId: field.name,
                  name: field.label,
                  type: "string",
                  responseId: field.name,
                }));
                setSectionList(fieldList);
                setSalesforce((prev) => ({ ...prev, fields }));
              });
            } else {
              list.push(
                ...variable.sectionVariablesStructure.map((section: any) => {
                  const config = variable.sectionVariablesConfiguration.find(
                    (config: any) => config.responseStructureId === section.responseStructureId
                  );
                  return {
                    ...section,
                    ...config,
                  };
                })
              );
              setSectionList(list);
            }
          }
        });
      });
    }
  }, [payloads, variables, salesforce.is]);

  useEffect(() => {
    const tempVars: any[] = [];
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        tempVars.push(option);
      });
    });
    setVars(tempVars);
  }, [variables]);

  const saveOutputStructure = async () => {
    setLoading(true);

    updateResponseConfig(responses, action.id);
    updatePayloadConfig(payloads, action.id, false);
    await saveWorkflowActions(id || "");
    setLoading(false);
    setModal(false);
  };

  const handleSelectList = (value: any, options: any) => {
    const temp = [...payloads];
    const index = temp.findIndex((payload) => payload.payloadStructureId === "sectionListId");
    temp[index].selectedList = options.name;

    if (index === -1) return;
    temp[index].inputString = `{{${value}}}`;
    setPayloads(temp);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "sectionList":
        return (
          <>
            <Select
              placeholder={`Select ${field.name}`}
              defaultValue={
                payloads
                  .find((payload) => payload.payloadStructureId === "sectionListId")
                  ?.inputString.split("{{")[1]
                  ?.split("}}")[0]
              }
              onChange={handleSelectList}
              optionFilterProp="label"
              options={variables
                .filter((action) => action.variables.some((variable: any) => variable.type === field.type))
                .map((variable) => {
                  return {
                    label: (
                      <div className="flex gap-2">
                        <img src={variable.logo} alt={variable.name} className="w-4 h-4" />
                        <span>{variable.name}</span>
                      </div>
                    ),
                    title: variable.name,
                    options: variable.variables
                      .filter((variable: any) => variable.type === field.type)
                      .map((v: any, idx: number) => {
                        return {
                          label: (
                            <div
                              key={idx}
                              className={`px-2 flex items-center gap-2 rounded-lg
                        ${variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"}`}
                            >
                              <img
                                src={variable.type === "input" ? YellowVarLogo : BlueVarLogo}
                                alt={v.name}
                                className="w-4 h-4"
                              />
                              {v.name}
                            </div>
                          ),
                          value: v.responseId,
                          name: v.name,
                        };
                      }),
                  };
                })}
            />
            {salesforce.is && salesforce.objects.length > 0 && (
              // <Skeleton
              //   loading={salesforce.objectsLoading}
              //   active={salesforce.objectsLoading}
              // >
              <div className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">{"SELECT SALESFORCE OBJECT"}</p>
                </div>
                <Select
                  placeholder={`Select salesforce object`}
                  showSearch
                  defaultValue={
                    payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                      ?.salesforceObjectId || ""
                  }
                  onChange={(value) => {
                    const temp = [...payloads];
                    const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                    temp[index].salesforceObjectId = value;
                    setPayloads(temp);
                  }}
                  options={salesforce.objects?.map((value: any) => ({
                    label: value.label,
                    value: value.name,
                  }))}
                />
              </div>
              // </Skeleton>
            )}
            {sectionList.length > 0 && (
              <div className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">{"SELECT VARIABLES"}</p>
                </div>
                <Select
                  placeholder={`Select variables`}
                  mode="multiple"
                  defaultValue={
                    responses.length > 0
                      ? responses
                          .filter((res) => !res?.extraVar)
                          .reduce((acc: any[], curr) => {
                            return [...acc, curr?.responseStructureId];
                          }, [])
                      : []
                  }
                  onChange={(value: any) => {
                    const extra = responses.filter((res) => res?.extraVar);
                    setResponses(
                      value
                        .map((res: any) => {
                          return sectionList.find((variable: any) => variable.responseStructureId === res);
                        })
                        .concat(extra)
                    );
                  }}
                  options={sectionList.map((variable: any) => ({
                    label: variable.name,
                    value: variable.responseStructureId,
                  }))}
                />
              </div>
            )}
          </>
        );
      case "dropdown":
        return (
          <Select
            showSearch
            mode="multiple"
            defaultValue={responses
              .filter((res) => res?.extraVar)
              .reduce((acc: any[], curr) => {
                return [...acc, curr?.responseStructureId];
              }, [])}
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1)
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value.map((val: any) => ({
                    name: val,
                    value: `{{${val}}}`,
                  })),
                });
              else
                temp[index].inputString = value.map((val: any) => ({
                  name: val,
                  value: `{{${val}}}`,
                }));

              setPayloads(temp);
              const extra = responses.filter((res) => !res?.extraVar);
              setResponses(
                extra.concat(
                  value.map((res: any) => {
                    const tempVar = vars.find((variable: any) => variable.id === res);
                    return {
                      name: tempVar.name,
                      description: "",
                      responseStructureId: tempVar.id,
                      extraVar: true,
                    };
                  })
                )
              );
            }}
            options={vars.map((option, index: number) => ({
              label: (
                <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                  <img src={option.logo} alt="logo" className="w-6 h-6" />
                  <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                    {option.actionName}
                  </span>
                  <p className="">{">"}</p>
                  <p>{option.name}</p>
                </div>
              ),
              value: option.id,
              name: `${option.actionName} - ${option.name}`,
            }))}
            optionFilterProp="name"
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            {action.name}
          </div>
        }
        footer={
          <button
            className={`w-full text-white rounded-lg p-2 mt-auto ${
              loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
            }`}
            onClick={saveOutputStructure}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        }
      >
        <div className="h-full bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {fieldSwitchCase(field)}
              </div>
            );
          })}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default AddSection;
