import { ConfigProvider, GetProp, Table, TableColumnsType, TableProps } from "antd";
import { ResponseConfiguration } from "../../../utils/interfaces";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTableInfoForWorkflow, getTableInfoForWorkflowV2 } from "@/utils/apis";
import { getV2 } from "@/utils/constants";

type Map = {
  [key: string]: string;
};

type Props = {
  map: Map;
  data: any[];
  columnIds: ResponseConfiguration[];
  importCSV: (rows: Map[], importOption: number) => void;
  duplicateMap: Record<string, boolean>;
  importState: number;
  importOption: number;
};

type TablePaginationConfig = Exclude<GetProp<TableProps, "pagination">, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
}

const FinalMap = ({ map, data, columnIds, duplicateMap, importCSV, importState, importOption }: Props) => {
  const { id } = useParams();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      total: 0,
    },
  });

  const handleTableChange = (page: number, pageSize: number) => {
    setTableParams((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          current: page,
          pageSize: pageSize,
        },
      };
    });
  };

  const [columns, setColumns] = useState<TableColumnsType<any[]>>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id === undefined) return;
    const preventDuplicates = async () => {
      setLoading(true);
      const v2 = getV2();
      const response = v2
        ? await getTableInfoForWorkflowV2(id, 0, 10000, 1)
        : await getTableInfoForWorkflow(id, 0, 10000, true, "");
      const action = response.responses.filter((item: { actionId: string }) => item.actionId === "id1");
      if (action.length === 0) return;

      const columns = action[0].response
        .filter((item: { responseId: string }) => duplicateMap[item.responseId])
        .map((item: { name: string; responseId: any }) => {
          return {
            [item.name]: item.responseId,
          };
        });

      const row_data: Record<string, string[]> = {};
      Object.values(response.data).forEach((item) => {
        (item as { id1: Record<string, any> })["id1"].response.forEach(
          (response: { responseId: string; value: any }) => {
            if (Object.keys(duplicateMap).includes(response.responseId))
              if (row_data[response.responseId] === undefined) row_data[response.responseId] = [response.value];
              else row_data[response.responseId].push(response.value);
          }
        );
      });

      const cols = Object.entries(map).filter((item) => item[1] !== "");

      const r = data.map((row) => {
        const each = cols.map((item) => {
          return {
            [columnIds.find((col) => col.responseId === item[1])?.name || ""]: row[item[0]],
          };
        });
        return each.reduce((acc, val) => ({ ...acc, ...val }), {});
      });

      const filtered = [];
      const filtered_data: Record<string, string[]> = {};

      for (let i = 0; i < r.length; i++) {
        const row = r[i];
        let isDuplicate = false;
        Object.entries(row).forEach(([key, value]) => {
          const column = columns.find((item: Record<string, string>) => key in item);
          if (!column) return;
          if (row_data[column[key]] && row_data[column[key]].includes(value)) {
            isDuplicate = true;
          } else if (filtered_data[column[key]] && filtered_data[column[key]].includes(value)) {
            isDuplicate = true;
          }
        });

        if (!isDuplicate) {
          filtered.push(row);
          Object.entries(row).forEach(([key, value]) => {
            const column = columns.find((item: Record<string, string>) => key in item);
            if (!column) return;
            if (filtered_data[column[key]] === undefined) {
              filtered_data[column[key]] = [value];
            } else {
              filtered_data[column[key]].push(value);
            }
          });
        }
      }
      setRows(filtered);
      setLoading(false);

      setColumns(
        cols.map((item) => {
          return {
            title: columnIds.find((col) => col.responseId === item[1])?.name || "",
            dataIndex: columnIds.find((col) => col.responseId === item[1])?.name || "",
            key: item[1],
            ellipsis: true,
            width: 200,
          };
        })
      );
    };

    void preventDuplicates().catch(console.error);
  }, [map, data, columnIds, id, duplicateMap]);

  useEffect(() => {
    if (importState) importCSV(rows, importOption);
  }, [importState, rows]);

  useEffect(() => {
    setTableParams((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          total: rows.length,
        },
      };
    });
  }, [rows]);

  return (
    <div className="relative w-full h-full overflow-auto py-2">
      <ConfigProvider>
        <Table
          columns={columns}
          dataSource={rows}
          showHeader
          bordered
          pagination={{
            ...tableParams.pagination,
            onChange: handleTableChange,
          }}
          loading={loading}
        />
      </ConfigProvider>
    </div>
  );
};

export default FinalMap;
