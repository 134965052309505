import { Modal } from "antd";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import DeleteIcon from "../../assets/SVGs/DeleteIcon.svg";
import { deleteWorkflow } from "@/utils/apis";

type Props = {
  modal: boolean;
  close: () => void;
  workflow: any;
  isV2: boolean;
};

const DeleteWorkflow = ({ modal, close, workflow, isV2 }: Props) => {
  const handleDelete = async () => {
    await deleteWorkflow(workflow.id, isV2);
    window.location.reload();
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="50%">
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="workflow-name" className="text-[22px] font-semibold text-gray-800">
            <div>
              Are you Sure you want to delete this workflow (
              <span className="text-red-600">{workflow.publishedWorkflowConfig.name}</span>
              )?
            </div>
          </label>
        </div>
        <div className="flex gap-x-3 items-center mt-6">
          {/* <LoaderButton
            disabled={false}
            error=""
            loaderText="Deleting Workflow..."
            text="Delete Workflow"
            onClickCallback={handleDelete}
            btnClasses="w-fit bg-red-600 text-white"
          /> */}
          <div className="flex border border-red-600 px-4 gap-x-3 rounded-md group hover:bg-red-300 ">
            <img src={DeleteIcon} alt="deleteWorkflow" width={20} />
            <LoaderButtonBlack
              disabled={false}
              error=""
              loaderText="Deleting Workflow..."
              text="Delete Workflow"
              onClickCallback={handleDelete}
              btnClasses={"w-fit bg-white text-red-800 px-0 group-hover:bg-red-300 "}
            />
          </div>
          <button onClick={close} className="w-fit text-[22px] font-semibold py-3 px-6 text-black rounded-md">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWorkflow;
