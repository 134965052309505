import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/Command";
import { MultiSelectValues } from "../filter.schema";
import { LuCheck } from "react-icons/lu";

export function SearchFilterValuePreviewSelect({
  value,
  availableValues,
}: {
  value: string;
  availableValues: MultiSelectValues;
}) {
  const name = availableValues.find((item) => item.id === value)?.name;
  return <p className="text-sm">{name}</p>;
}

export function SearchFilterFormSelect({
  value,
  onChangeValue,
  availableValues,
}: {
  onChangeValue: (value: string) => void;
  availableValues: MultiSelectValues;
  value: string;
}) {
  const onChangeCondition = (newValue: string) => {
    if (newValue == value) return;
    onChangeValue(newValue);
  };
  return (
    <Command>
      {availableValues.length > 5 && <CommandInput placeholder="Type filter..." />}
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {availableValues.map((item) => (
            <CommandItem key={item.id} onSelect={() => onChangeCondition(item.id)} disabled={value == item.id}>
              {value == item.id && <LuCheck className="h-4 w-4 mr-2" />}
              {value != item.id && <div className="h-4 w-4 mr-2" />}
              <span>{item.name}</span>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
