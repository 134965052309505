import { Handle, NodeProps, Position, useHandleConnections } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import { CELL_STATUS_SYMBOL } from "@/utils/constants";

export function ActionNode({ data }: NodeProps<any>) {
  const connections = useHandleConnections({
    type: "target",
  });

  return (
    <div className="nodrag w-[76vw]">
      {data.type !== "input" && <Handle type="target" position={Position.Top} isConnectable={connections.length < 1} />}
      <div className="relative group p-1">
        <div className="flex bg-white justify-between items-start sm:items-center transition-opacity duration-300 w-full max-w-full py-3 px-4 md:p-6 border border-grey-400 rounded-2xl cursor-pointer relative z-1 hover:border-primary hover:bg-gray-50">
          <div className="flex items-center justify-start gap-4 w-[35%]">
            <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
              {data.logo !== "img:link" ? <img src={data.logo as string} alt="icon" /> : <DefaultAction />}
            </div>
            <div className="flex w-fit items-center justify-between overflow-hidden">
              <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                {data.name as string}
              </label>
            </div>
          </div>
          {data?.actionCounts && (
            <div className="flex items-center justify-center gap-2 text-gray-600 font-light text-sm">
              <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                {(data?.actionCounts?.payloadFilled > 0 || data?.actionCounts?.checkingNextSource > 0) &&
                  CELL_STATUS_SYMBOL.payloadFilled}
                &nbsp;
                {data?.actionCounts?.payloadFilled || data?.actionCounts?.checkingNextSource}
                &nbsp;In Progress
              </div>
              <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                {CELL_STATUS_SYMBOL.pending}&nbsp;
                {data?.actionCounts?.pending}
                &nbsp;Pending
              </div>
              <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                {CELL_STATUS_SYMBOL.completed}&nbsp;
                {data?.actionCounts?.completed}&nbsp;Completed
              </div>
              <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                {CELL_STATUS_SYMBOL.failed}&nbsp;
                {data?.actionCounts?.failed}&nbsp;Failed
              </div>
              <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                {CELL_STATUS_SYMBOL.conditionNotMet}&nbsp;
                {data?.actionCounts?.conditionNotMet}&nbsp;Condition Not Met
              </div>
            </div>
          )}
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
}
