import { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  workflow: any;
};

const DraftCard = ({ workflow }: Props) => {
  const [more, setMore] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="bg-white flex flex-col gap-4 p-8 rounded-lg shadow justify-between">
      <div className="flex gap-3">
        {workflow.actions.map((action: string, idx: number) => {
          if (!action.includes("https")) return;
          else return <img className="w-10 h-10 rounded-full" src={action} alt={action} key={idx} />;
        })}
      </div>
      <div className="text-2xl font-semibold">{workflow.name}</div>
      <div>
        <p className={`w-full text-sm font-light transition-all ${more ? "line-clamp-none" : "line-clamp-3"}`}>
          {workflow.description}
        </p>
        <button
          onClick={() => {
            setMore(!more);
          }}
          className="text-primary font-bold mt-1 text-sm hover:underline self-start"
        >
          Read {more ? "less" : "more"}
        </button>
      </div>
      <div>
        <p className="font-bold text-gray-500">Inputs:</p>
        <section className="w-full flex gap-2 flex-wrap">
          {workflow.inputs.map((input: string, idx: number) => (
            <div
              className="border-[0.5px] border-gray-200 text-sm px-1 rounded text-gray-500 font-semibold w-fit hover:bg-gray-50 transition"
              key={idx}
            >
              {input}
            </div>
          ))}
        </section>
      </div>
      <button
        className="bg-primary p-2 text-white rounded-lg font-semibold hover:bg-dark transition-all"
        onClick={() => navigate(`/builder/${workflow.id}`)}
      >
        Edit
      </button>
    </div>
  );
};

export default DraftCard;
