interface Props {
  size?: string;
  color?: string;
}

const Spinner = ({ size, color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        background: "none",
        display: "block",
        shapeRendering: "auto",
      }}
      width={size || "64px"}
      height={size || "64px"}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke={color || "#ffffff"}
        strokeWidth={6}
        r={37}
        strokeDasharray="174.35839227423352 60.119464091411174"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
      {/* [ldio] generated by https://loading.io/ */}
    </svg>
  );
};

export default Spinner;
