import { Drawer, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa";
import { CELL_STATUS_SYMBOL, CELL_STATUS_TYPE } from "@/utils/constants";
import Coins from "@/assets/SVGs/buildTab/Coins";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: any;
  error: any;
};

const WaterfallViewer = ({ open, close, data, title, credits, error }: Props) => {
  const [copied, setCopied] = useState(false);
  const [apis, setApis] = useState<any[]>([]);
  const [creds, setCreds] = useState<any>(credits);
  // const [totalCost, setTotalCost] = useState<number>(0);
  let totalCost = 0;
  const stepDownResponses = data.stepDownResponse;

  useEffect(() => {
    const config = data.action.payloadConfiguration.find(
      (conf: any) => conf.stepDownSearchConfig
    )?.stepDownSearchConfig;
    const stepApis =
      data.action.payloadStructure.find((payload: any) => payload.stepDownAPIs)?.stepDownAPIs ||
      data.action?.stepDownAPIs;
    if (!config || !stepApis) return;
    /**
     * @todo: First filter out the stepApis based on the config, then sort them based on the order.
     */

    const tempCreds: any = credits;
    config.forEach((c: any) => {
      if (c.connection) tempCreds[c.apiId] = "CUSTOM";
    });
    setCreds(tempCreds);

    if (!stepDownResponses) {
      setApis([]);
      return;
    }
    const modifiedProviders = stepApis.filter((api: any) => Object.keys(stepDownResponses).includes(api.apiId));
    const sortedProviders = modifiedProviders.sort((a: any, b: any) => {
      const aOrder = config.find((c: any) => c.apiId === a.apiId)?.order;
      const bOrder = config.find((c: any) => c.apiId === b.apiId)?.order;
      return aOrder - bOrder;
    });

    setApis(sortedProviders);
  }, [data]);

  const updateCost = (cost: any) => {
    totalCost += parseFloat(cost);
    totalCost = Math.round(totalCost * 100) / 100;
  };

  const handleCopy = (email: string) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const renderStatus = (apiId: string, index: number) => {
    if (!stepDownResponses) return null;
    const response = Object.keys(stepDownResponses).find((key) => key === apiId);

    if (response) {
      const { error } = stepDownResponses[response];
      if (error || typeof stepDownResponses[response] === "string") {
        return (
          <span className="flex items-center ml-2 text-red-500">
            {CELL_STATUS_SYMBOL.failed} {CELL_STATUS_TYPE.FAILED}
          </span>
        );
      } else {
        return (
          <span className="flex items-center ml-2 text-green-500 gap-2">
            {CELL_STATUS_SYMBOL.completed} {stepDownResponses[apis[index].apiId]?.email}
            <span className="text-xs">
              <div className="flex items-center justify-center gap-1 rounded-sm p-1">
                {creds[apiId] !== "CUSTOM" && <Coins width="18" height="22" />}
                <span className="font-bold text-md text-black">
                  {creds[apiId] === "CUSTOM" ? "Custom API Key" : creds[apiId]}
                  {creds[apiId] !== "CUSTOM" && updateCost(creds[apiId])}
                </span>
              </div>
            </span>
          </span>
        );
      }
    }

    return <span className="flex items-center ml-2 text-gray-500">{CELL_STATUS_SYMBOL.pending} Pending</span>;
  };

  if (!data) {
    return (
      <Drawer title={title()} placement="right" onClose={close} open={open} width="50%">
        <div className="p-5">No data available.</div>
      </Drawer>
    );
  }

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="85%">
      <div className="p-5">
        <div className="flex items-center mb-4">
          <h2 className="text-sm font-bold mr-2">Email enrichment detail for:</h2>
          <span className="font-normal">{data.email}</span>
          <Tooltip
            placement="bottomLeft"
            title={copied ? "Copied!" : "Copy to clipboard"}
            arrow={false}
            overlayClassName="border border-gray-200 rounded-md"
            overlayInnerStyle={{
              fontWeight: 600,
              backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
              color: `${copied ? "#f3f4f6" : "#6c727f"}`,
              fontSize: "0.75rem",
            }}
          >
            <button
              onClick={() => handleCopy(data.email)}
              title="Copy"
              className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2 ml-2"
            >
              <FaRegCopy className="text-gray-600" />
            </button>
          </Tooltip>
        </div>
        {/* @todo: If status -> completed, don't show any providers after that */}
        {open && apis && apis.length > 0 ? (
          <div className="border border-gray-300 rounded-lg p-4 bg-gray-100 flex flex-col">
            <div className="flex items-center mb-4 space-x-3">
              <span className="font-semibold mr-2">First we tried</span>
              <FaArrowRightLong />
              <img src={apis[0].logo} alt={apis[0].apiName} className="w-8 h-8 mr-2" />
              <span>{apis[0].apiName}</span>
              <FaArrowRightLong />
              {renderStatus(apis[0].apiId, 0)}
              {typeof stepDownResponses[apis[0].apiId] !== "string" && stepDownResponses[apis[0].apiId]?.verifiedBy && (
                <div className="flex items-center justify-center gap-x-3">
                  <FaArrowRightLong />
                  <img
                    src={
                      stepDownResponses[apis[0].apiId]?.verifiedBy?.logo ||
                      "https://placehold.co/50x50?text=Email+Validator"
                    }
                    alt={
                      stepDownResponses[apis[0].apiId]?.verifiedBy?.name || stepDownResponses[apis[0].apiId]?.verifiedBy
                    }
                    className="w-8 h-8"
                  />
                  <p>
                    {stepDownResponses[apis[0].apiId]?.verifiedBy?.name || stepDownResponses[apis[0].apiId]?.verifiedBy}
                  </p>
                  <div className="flex items-center justify-center gap-1 rounded-sm p-1">
                    <Coins width="18" height="22" />
                    <p className="font-bold text-md text-black">
                      {stepDownResponses[apis[0].apiId]?.verifiedBy?.credits || 1}
                    </p>
                    {stepDownResponses[apis[0].apiId]?.verifiedBy?.credits &&
                      updateCost(stepDownResponses[apis[0].apiId]?.verifiedBy?.credits)}
                  </div>
                  <div>
                    {stepDownResponses[apis[0].apiId]?.emailType !== "VERIFIED" && (
                      <span className="text-red-500">
                        {CELL_STATUS_SYMBOL.failed} {stepDownResponses[apis[0].apiId]?.emailStatus}(Not-verified)
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {apis.slice(1).map((api: any, ind: number) => (
              <div key={api.apiId} className="flex items-center mb-4 space-x-3">
                <span className="font-semibold">Then we tried</span>
                <FaArrowRightLong />
                <img src={api.logo} alt={api.apiName} className="w-8 h-8" />
                <span>{api.apiName}</span>
                <FaArrowRightLong />
                {renderStatus(api.apiId, ind + 1)}
                {typeof stepDownResponses[api.apiId] !== "string" && stepDownResponses[api.apiId]?.verifiedBy && (
                  <div className="flex items-center justify-center gap-x-3">
                    <FaArrowRightLong />
                    <img
                      src={
                        stepDownResponses[api.apiId]?.verifiedBy?.logo ||
                        "https://placehold.co/50x50?text=Email+Validator"
                      }
                      alt={stepDownResponses[api.apiId]?.verifiedBy?.name || stepDownResponses[api.apiId]?.verifiedBy}
                      className="w-8 h-8"
                    />
                    <p>{stepDownResponses[api.apiId]?.verifiedBy?.name || stepDownResponses[api.apiId]?.verifiedBy}</p>
                    <div className="flex items-center justify-center gap-1 rounded-sm p-1">
                      <Coins width="18" height="22" />
                      <p className="font-bold text-md text-black">
                        {stepDownResponses[api.apiId]?.verifiedBy?.credits || 1}
                      </p>
                      {stepDownResponses[api.apiId]?.verifiedBy?.credits &&
                        updateCost(stepDownResponses[api.apiId]?.verifiedBy?.credits)}
                    </div>
                    <div>
                      {stepDownResponses[api.apiId]?.emailType !== "VERIFIED" && (
                        <span className="text-red-500">
                          {CELL_STATUS_SYMBOL.failed} {stepDownResponses[api.apiId]?.emailStatus}(Not-verified)
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <span className="font-bold flex items-center gap-x-2">
              Total cost: <Coins width="18" height="22" />
              {totalCost}
            </span>
            {error && <div className="text-red-500 mt-3 font-bold">Error: {error}</div>}
          </div>
        ) : (
          <div className="border border-gray-300 rounded-lg p-4 bg-gray-100 flex flex-col">
            <span className="font-semibold">Used Data Value specified in the Condition</span>
            {error && <div className="text-red-500 mt-3 font-bold">Error: {error}</div>}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default WaterfallViewer;
