import { App, Drawer, message } from "antd";
import { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
};

const WellsViewer = ({ open, close, data, title }: Props) => {
  const [newData, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const fields = [
    { label: "Well Id", index: 0 },
    { label: "Date Created", index: 1 },
    { label: "Date Last Modified", index: 2 },
    { label: "Well Name", index: 3 },
    { label: "UWI", index: 4 },
    { label: "Well Type", index: 5 },
    { label: "Well Status", index: 6 },
    { label: "Current Operator", index: 7 },
    { label: "Original Operator", index: 8 },
    { label: "Field", index: 9 },
    { label: "County", index: 10 },
    { label: "State", index: 11 },
    { label: "Country", index: 12 },
    { label: "Well Bore Profile", index: 13 },
    { label: "Latitude", index: 14 },
    { label: "Longitude", index: 15 },
    { label: "BH Latitude", index: 16 },
    { label: "BH Longitude", index: 17 },
    { label: "Permit Date", index: 18 },
    { label: "Spud Date", index: 19 },
    { label: "Completion Date", index: 20 },
    { label: "First Prod Date", index: 21 },
    { label: "Measured Depth", index: 22 },
    { label: "True Vertical Depth", index: 23 },
    { label: "Lateral Length", index: 24 },
    { label: "Basin", index: 25 },
    { label: "Play", index: 26 },
    { label: "Grid 1", index: 27 },
    { label: "Grid 2", index: 28 },
    { label: "Grid 3", index: 29 },
    { label: "Grid 4", index: 30 },
    { label: "Grid 5", index: 31 },
    { label: "State Id", index: 32 },
    { label: "State Id Desc", index: 33 },
    { label: "Thermal Maturity", index: 34 },
    { label: "Simple Id", index: 35 },
    { label: "Plug Date", index: 36 },
    { label: "Permit Number", index: 37 },
    { label: "Lease", index: 38 },
    { label: "District", index: 39 },
    { label: "Lease Id", index: 40 },
    { label: "Footages", index: 41 },
    { label: "Quarter", index: 42 },
    { label: "Ground Elevation", index: 43 },
    { label: "Drilling Floor Elevation", index: 44 },
    { label: "Kelly Bushing Elevation", index: 45 },
    { label: "Comments", index: 46 },
    { label: "Test Date", index: 47 },
    { label: "Primary Formation", index: 48 },
    { label: "Internal Id", index: 49 },
    { label: "Date Catalogued", index: 50 },
    { label: "Reported Current Operator", index: 51 },
    { label: "Reported Original Operator", index: 52 },
    { label: "Reported Field", index: 53 },
    { label: "Reported Wellbore Profile", index: 54 },
    { label: "Well Number", index: 55 },
    { label: "Offshore Water Depth", index: 56 },
    { label: "KOP Latitude", index: 57 },
    { label: "KOP Longitude", index: 58 },
    { label: "Heel Latitude", index: 59 },
    { label: "Heel Longitude", index: 60 },
    { label: "Reported Well Status", index: 61 },
    { label: "Reported Well Type", index: 62 },
    { label: "Trajectory", index: 63 },
    { label: "Well Trajectory Grid North", index: 64 },
    { label: "Well Trajectory 3D Grid North", index: 65 },
    { label: "Well Trajectory True North", index: 66 },
    { label: "Well Trajectory 3D True North", index: 67 },
  ];

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="30%">
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="text-[20px] font-bold">Top 5 wells and their details</div>
          <hr />
          <div className="space-y-2">
            {newData &&
              newData["Top 5 wells and their details"] &&
              Object?.values(newData["Top 5 wells and their details"])?.map((well: any, idx: number) => (
                <div key={idx}>
                  <div
                    className={`${
                      activeIndices.includes(1001 + idx + 1)
                        ? ""
                        : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
                    } cursor-pointer mt-2 mb-2`}
                  >
                    <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001 + idx + 1)}>
                      <p className="text-[16px] font-bold flex items-center gap-x-2">
                        Well {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                        {activeIndices.includes(1001 + idx + 1) ? (
                          <UpArrowNew color="black" />
                        ) : (
                          <DownArrowNew color="black" />
                        )}
                      </p>
                      <p className="cursor-pointer flex items-center gap-x-2" onClick={(e) => handleCopyJSON(well, e)}>
                        Copy All <FaRegCopy className="text-gray-600" />
                      </p>
                    </div>

                    {activeIndices.includes(1001 + idx + 1) && (
                      <div className="space-y-2 mt-2 border p-2 rounded-sm">
                        {fields.map((field, fieldIdx) => (
                          <div key={fieldIdx} className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">{field.label}</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!well[field.index]?.value || well[field.index]?.value === "No data found"
                                  ? "\u00A0"
                                  : well[field.index]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(well[field.index]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className="text-[20px] font-bold">Total no. of wells</div>
          <hr />
          {newData && newData["Total no. of wells"] && (
            <div className={"bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm cursor-pointer mt-2 mb-2"}>
              <div className="flex items-center justify-between">
                <p className="text-[16px] flex items-center gap-x-2">{newData["Total no. of wells"]}</p>
                <p
                  className="cursor-pointer flex items-center gap-x-2"
                  onClick={(e) => handleCopyJSON(newData["Total no. of wells"], e)}
                >
                  Copy All <FaRegCopy className="text-gray-600" />
                </p>
              </div>
            </div>
          )}
        </div>
      </App>
    </Drawer>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default WellsViewer;
