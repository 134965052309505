import { useChat } from "@/contexts/ChatContext";

const SidePane = () => {
  const { selectedChat, currChatDataRef, setSelectedChat } = useChat();

  return (
    <section className="hidden lg:flex lg:flex-[0.3] xl:flex-[0.2] flex-col justify-center items-start p-4 lg:p-6 border-r border-gray-300">
      <div className="flex flex-col items-start gap-4 w-full h-full">
        <header className="font-semibold text-xl">Your chats</header>
        <section className="flex-grow w-full overflow-auto">
          <section className="flex flex-col gap-2 w-full">
            {Object.entries(currChatDataRef.current).map(([key, value]) => (
              <button
                className={`w-full border border-gray-300 rounded-lg flex p-4 flex-col items-start gap-2 hover:bg-superchat-blue/5 transition ${
                  key === selectedChat.id ? "bg-primary/10" : "bg-transparent"
                } `}
                key={key}
                onClick={() => {
                  setSelectedChat({ id: key, name: (value as any).chatName });
                }}
              >
                <span>{(value as any).chatName}</span>
                {/* <span className="tracking-widest font-semibold text-xs">
                  {itr.allData ? (
                    <span className="bg-superchat-blue px-1 py-0.5">
                      ALL DATA
                    </span>
                  ) : (
                    <span className="bg-yellow-200 px-1 py-0.5">
                      {itr.startRow} - {itr.finishRow}
                    </span>
                  )}
                </span> */}
              </button>
            ))}
          </section>
        </section>
      </div>
    </section>
  );
};

export default SidePane;
