import { useParams } from "react-router-dom";
import { ActionCounts, ResponseConfiguration } from "../../../../utils/interfaces";
import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../../../Loader";
import { LOADER_TYPES } from "../../../../utils/constants";
import dagre from "dagre";
import {
  addEdge,
  Background,
  BackgroundVariant,
  ConnectionLineType,
  Edge,
  Node,
  NodeProps,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { ActionNode } from "./ActionNode";
import { getDataInfoForWorkflow } from "@/utils/apis";

const nodeHeight = window.innerHeight * 0.16;
const nodeWidth = window.innerWidth * 0.6;

type Props = {
  actions: any[];
  template: ResponseConfiguration[];
  firstActionId: string;
};

const Run = ({ template, firstActionId }: Props) => {
  const [actionCounts, setActionCounts] = useState<ActionCounts>({});
  const [actions, setActions] = useState<any[]>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const initialPos = { x: 0, y: 0 };
  const nodeTypes = { actionNode: ActionNode as React.FC<NodeProps> };
  const { id } = useParams();
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    if (!actions || actions.length === 0 || !actionCounts) return;
    const initialNodes: Node[] = [newNode(firstActionId)];
    const initialEdges: Edge[] = [];
    const queue = [firstActionId];
    while (queue.length > 0) {
      const aid = queue.shift();
      if (!aid) continue;
      const act = actions.find((act) => act.id === aid);
      if (!act) continue;
      const childIds: string[] = act.nextAction.map((a: any) => {
        const childId = a && typeof a === "object" ? a.actionId : a;
        if (!childId) return "";
        initialEdges.push(newEdge(aid, childId));
        initialNodes.push(newNode(childId));
        return childId;
      });
      queue.push(...childIds.filter((id) => id !== ""));
    }
    const { nodes: finalNodes, edges: finalEdges } = getLayoutedElements(initialNodes, initialEdges);
    setNodes(finalNodes);
    setEdges(finalEdges);
  }, [actions, actionCounts]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getData();
  }, [refresh]);

  const getData = () => {
    if (!id) return;
    getDataInfoForWorkflow(id).then((data) => {
      setActionCounts(data.actionCounts);
      setActions(data.actions);
      setLoading(false);
      const t = setTimeout(() => {
        getData();
      }, 5000);
      timeoutRef.current = t;
      return () => clearTimeout(t);
    });
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const newNode = (id: string): Node => {
    const act = actions.find((act) => act.id === id);
    return {
      id,
      type: "actionNode",
      position: initialPos,
      data: {
        name: act.displayName || act.actionDetails.name,
        logo: act.actionDetails.logo,
        type: act.actionDetails.type,
        actionCounts: actionCounts[act.id],
        refresh: () => setRefresh(refresh + 1),
        template,
      },
    };
  };
  const newEdge = (source: string, target: string): Edge => ({
    id: `${source}-${target}`,
    source,
    target: target,
    style: { stroke: "#9CA3AF", strokeWidth: 2 },
  });

  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
    const newDagreGraph = new dagre.graphlib.Graph();
    newDagreGraph.setDefaultEdgeLabel(() => ({}));
    newDagreGraph.setGraph({ rankdir: "TB" });

    nodes.forEach((node) => {
      newDagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      newDagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(newDagreGraph);
    // setDagreGraph(newDagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = newDagreGraph.node(node.id);

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  if (loading) {
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow" />
      </div>
    );
  }
  return (
    // <DotsBg>/
    <div className="w-full h-full flex flex-col items-center gap-5">
      {/* {workflowActions.length > 0 &&
          workflowActions.map((act: any, idx: number) => {
            return (
              <div
                className="builder-step-card relative group px-12 py-3 w-4/5"
                key={idx}
              >
                <div className="flex bg-white justify-between items-start sm:items-center transition-opacity duration-300 w-full max-w-full py-3 px-4 md:p-6 border border-grey-400 rounded-2xl cursor-pointer relative z-1 hover:border-primary hover:bg-gray-50">
                  <div className="flex items-center justify-center gap-4">
                    <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
                      {act.actionDetails.logo !== "img:link" ? (
                        <img src={act.actionDetails.logo} alt="icon" />
                      ) : (
                        <DefaultAction />
                      )}
                    </div>
                    <div className="flex w-fit items-center justify-between overflow-hidden">
                      <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                        {act.displayName || act.actionDetails.name}
                      </label>
                    </div>
                  </div>
                  <div className="mr-auto pl-3 ml-3 border-l border-gray-300">
                    {act.actionDetails.type === "input" && (
                      <CSVInput
                        refresh={() => setRefresh(refresh + 1)}
                        template={template}
                        workflowId={id || ""}
                      >
                        <button className="text-primary border border-[#cccccc] px-2 py-1 rounded-md text-sm flex gap-2 items-center">
                          <FiDownload />
                          Import CSV
                        </button>
                      </CSVInput>
                    )}
                  </div>
                  {actionCounts[act.id] && (
                    <div className="flex items-center justify-center gap-2 text-gray-600 font-light text-sm">
                      <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                        {actionCounts[act.id].payloadFilled > 0 &&
                          CELL_STATUS_SYMBOL.payloadFilled}
                        &nbsp;
                        {actionCounts[act.id].payloadFilled}
                        &nbsp;In Progress
                      </div>
                      <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                        {CELL_STATUS_SYMBOL.pending}&nbsp;
                        {actionCounts[act.id].pending}
                        &nbsp;Pending
                      </div>
                      <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                        {CELL_STATUS_SYMBOL.completed}&nbsp;
                        {actionCounts[act.id].completed}&nbsp;Completed
                      </div>
                      <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
                        {CELL_STATUS_SYMBOL.failed}&nbsp;
                        {actionCounts[act.id].failed}&nbsp;Failed
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })} */}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        panOnScroll
        // panOnScrollMode={PanOnScrollMode.Vertical}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        // edgeTypes={edgeTypes}
        edgesFocusable={false}
        edgesReconnectable={false}
        fitView
        // minZoom={1}
        // maxZoom={1}
        connectionLineType={ConnectionLineType.Straight}
        onConnect={onConnect}
      >
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
    // </DotsBg>
  );
};

export default Run;
