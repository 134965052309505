import { useWorkflow } from "@/contexts/WorkflowContext";
import { App, message, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import Markdown from "react-markdown";

type Props = {
  keyName: string;
  value: any;
  open: boolean;
};

const LinkedinProfileKeyValue = ({ keyName, value, open }: Props) => {
  const [actValue, setActValue] = useState(value);
  const [isObject, setIsObject] = useState(value && typeof value === "object");
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { getDataStoreValue } = useWorkflow();

  useEffect(() => {
    if (isObject && actValue && actValue.path && actValue.path.length > 1) {
      getDataStoreValue(actValue.path[1]).then((res) => {
        setActValue(res);
      });
    }
  }, [value]);

  useEffect(() => {
    setIsOpen(false);
    setIsObject(actValue && typeof actValue === "object");
    setCopied(false);
  }, [open, actValue]);

  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(actValue).then(() => {
      setCopied(true);
    });
  }, [actValue]);

  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  return (
    <App>
      <div
        className={` rounded flex items-center flex-wrap gap-2
      [&_*]:max-w-full [&_*]:overflow-x-auto relative group  ${isObject ? "p-2 border border-gray-200" : ""} ${
        keyName === "Risk assessment doc" ? "bg-white" : isOpen ? "bg-white" : "bg-[#FAFAFA]"
      } `}
      >
        {isObject &&
          (!isOpen ? (
            <button onClick={() => setIsOpen(true)}>
              <IoIosArrowForward />
            </button>
          ) : (
            <button onClick={() => setIsOpen(false)}>
              <IoIosArrowDown />
            </button>
          ))}
        {!isObject ? (
          <div className="bg-white w-full flex flex-col">
            <span className="text-[#575757] text-[14px] self-start">{keyName}</span>
            <div className="bg-[#F2FBFF] w-full text-start px-2 py-1 flex items-center justify-between group">
              <div className="w-[95%]">
                <Markdown>{actValue?.toString() ?? "\u00A0"}</Markdown>
              </div>
              <p
                className="cursor-pointer hidden group-hover:block "
                onClick={(e) => handleCopyText(actValue?.toString() ?? "", e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between w-[90%]" onClick={() => setIsOpen((prev) => !prev)}>
            <p>
              <span className="font-semibold text-[14px] self-start">{keyName}</span>
              <span className="text-gray-500">
                {actValue.length !== undefined ? `[${actValue.length}]` : `{${Object.keys(actValue).length}}`}
              </span>
            </p>
            <p className="cursor-pointer flex items-center gap-x-2" onClick={(e) => handleCopyJSON(actValue, e)}>
              Copy All <FaRegCopy className="text-gray-600" />
            </p>
          </div>
        )}
        {keyName === "Work Email" && (
          <Tooltip
            placement="bottomRight"
            title={copied ? "Copied!" : "Copy to clipboard"}
            arrow={false}
            overlayClassName="border border-gray-200 rounded-md"
            overlayInnerStyle={{
              fontWeight: 600,
              backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
              color: `${copied ? "#f3f4f6" : "#6c727f"}`,
              fontSize: "0.75rem",
            }}
          >
            <button
              className="opacity-0 group-hover:opacity-100 transition absolute top-2 right-2 flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
              onClick={copyEmail}
            >
              <FaRegCopy className="text-gray-600" />
            </button>
          </Tooltip>
        )}
        {isOpen ? (
          <div className="flex flex-col gap-2 ml-6 w-full">
            {Object.entries(actValue).map(([key, val]: [string, any]) => (
              <LinkedinProfileKeyValue key={key} keyName={key} value={val} open={open} />
            ))}
          </div>
        ) : null}
      </div>
    </App>
  );
};

export default LinkedinProfileKeyValue;
