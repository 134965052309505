import { ReactNode, useEffect } from "react";
import { ConfigProvider, Select, Skeleton, Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { TrashIcon } from "../Tabs/Table";
import useStateRef from "react-usestateref";
import StepDownTree from "./ModalComponents/StepDownTree";
import Reset from "@/assets/SVGs/buildTab/Reset";
import { getInputStringFromType } from "./AddActionModal";
import Coins from "@/assets/SVGs/buildTab/Coins";
import { MdDragIndicator } from "react-icons/md";
import { RiKey2Fill } from "react-icons/ri";
import { useConnections } from "@/contexts/ConnectionContext";

type Props = {
  structureId: string;
  stepDownAPIs: any[];
  payloadConfig: any[];
  action: any;
  credits?: Record<string, number>;
  updateStepDownOrder: (structureId: string, apiId: string, dropApiId: string, below: boolean) => void;
  updateConnectionToStepDown: (structureId: string, apiId: string, value: boolean) => void;
  updateStepDownConfig: (structureId: string, removedApiId: string | null, addedApiId: string | null) => void;
  resetStepDownConfig: (structureId: string, config: any[]) => void;
  setIsConnectionExists: (value: boolean) => void;
};

const StepDownSearch = ({
  structureId,
  stepDownAPIs,
  payloadConfig,
  action,
  credits,
  updateStepDownOrder,
  updateConnectionToStepDown,
  updateStepDownConfig,
  resetStepDownConfig,
  setIsConnectionExists,
}: Props) => {
  const [_gData, setGData, gDataRef] = useStateRef<TreeDataNode[]>([]);
  const [_providers, setProviders, providersRef] = useStateRef<{
    selectedProviders: any[];
    unSelectedProviders: any[];
  }>({
    selectedProviders: [],
    unSelectedProviders: [],
  });
  const { getConnectionFromType } = useConnections();

  const getConnection = async (provider: any) => {
    if (!provider?.floqerApiKeyNotAllowed) return { connection: { userConnection: {} }, isConn: false };
    const connection = await getConnectionFromType(provider.apiName);
    const isConn =
      connection &&
      connection.userConnection &&
      connection.userConnection.value &&
      connection.userConnection.value.apiKey
        ? true
        : false;
    return { connection, isConn };
  };

  const initialiseProviders = async (payloadConfig: any[]) => {
    const selectedApiIds = payloadConfig.map((config) => config.apiId);

    let selectedProviders = await Promise.all(
      stepDownAPIs.map(async (api) => {
        if (selectedApiIds.includes(api.apiId)) {
          if (!api?.floqerApiKeyNotAllowed) {
            return api;
          } else if (api?.floqerApiKeyNotAllowed) {
            const conn = await getConnection(api);
            if (conn.isConn) {
              updateConnectionToStepDown(structureId, api.apiId, true);
              return api;
            }
          } else return null;
        }
      })
    );
    selectedProviders = selectedProviders.filter((api) => api);

    // awaiting it will slow down the rendering of providers
    Promise.all(
      selectedProviders.map(async (api) => {
        const conn = await getConnection(api);
        if (api.floqerApiKeyNotAllowed && conn.isConn) {
          setIsConnectionExists(true);
        } else if (api.floqerApiKeyNotAllowed && !conn.isConn) {
          setIsConnectionExists(false);
        }
      })
    );

    const unSelectedProviders = stepDownAPIs.filter(
      (stepDownApi) => !selectedProviders.some((selectedProvider) => selectedProvider?.apiId === stepDownApi.apiId)
    );

    setProviders({
      selectedProviders,
      unSelectedProviders,
    });

    // find the apis from payloadConfig through selected providers and sort them according to the order
    const orderedApis = selectedProviders
      .sort((a, b) => {
        const aOrder = payloadConfig.find((config) => config.apiId === a.apiId)?.order;
        const bOrder = payloadConfig.find((config) => config.apiId === b.apiId)?.order;
        return aOrder - bOrder;
      })
      .map((order: any) => {
        const provider = stepDownAPIs.find((api) => api.apiId === order.apiId);
        return {
          title: provider.apiName,
          key: provider.apiId,
          isLeaf: true,
          disabled: false,
        } as TreeDataNode;
      });

    setGData(orderedApis);
  };

  useEffect(() => {
    initialiseProviders(payloadConfig);
  }, []);

  const handleReset = () => {
    const initialPayload: any = action.payloadStructure.find((input: any) => input.payloadStructureId === structureId);
    const config: any = {
      payloadStructureId: initialPayload.payloadStructureId,
      inputString: getInputStringFromType(initialPayload.type),
    };
    config["stepDownSearchConfig"] = stepDownAPIs
      .filter((api: any) => !api?.floqerApiKeyNotAllowed && !api?.disabled)
      .map((api: any, idx: number) => ({
        apiId: api.apiId,
        order: idx + 1,
      }));
    setIsConnectionExists(true);
    initialiseProviders(config.stepDownSearchConfig);
    resetStepDownConfig(structureId, config.stepDownSearchConfig);
  };

  const onDelete = (apiId: string) => {
    if (gDataRef.current.length >= 2) {
      updateStepDownConfig(structureId, apiId, null);
      const updatedSelectedProviders = providersRef.current.selectedProviders.filter(
        (provider) => provider.apiId !== apiId
      );
      const deletedProvider = providersRef.current.selectedProviders.find((provider) => provider.apiId === apiId);
      if (deletedProvider) {
        if (deletedProvider.floqerApiKeyNotAllowed) {
          setIsConnectionExists(true);
        }
        const updatedUnSelectedProviders = [...providersRef.current.unSelectedProviders, deletedProvider];
        // Check if "new-provider" node exists
        const newProviderNodeIndex = gDataRef.current.findIndex((node) => node.key === "new-provider");
        if (newProviderNodeIndex !== -1) {
          // Update the options of the existing "new-provider" node
          gDataRef.current[newProviderNodeIndex] = {
            ...gDataRef.current[newProviderNodeIndex],
            title: (
              <div className="flex items-center justify-between w-full">
                <Select
                  placeholder="Choose the type of workflow"
                  className="w-full h-fit p-0 rounded-sm"
                  popupClassName="w-full h-fit p-0"
                  rootClassName="w-full h-fit"
                  value="Select Provider"
                  suffixIcon={<FaChevronDown size={16} color="rgb(156 163 175)" />}
                  options={updatedUnSelectedProviders.map((item) => ({
                    label: buildTitle(item, false),
                    value: item.apiId,
                  }))}
                  onChange={(value) => {
                    const selectedProvider = providersRef.current.unSelectedProviders.find(
                      (provider) => provider.apiId === value
                    );
                    if (selectedProvider) {
                      onAdd(selectedProvider);
                    }
                  }}
                />
                <button
                  onClick={() => onDelete("new-provider")}
                  className="p-1 m-1 disabled:cursor-not-allowed"
                  // disabled={providersRef.current.selectedProviders.length < 2}
                >
                  <TrashIcon className="text-red-500 w-4 h-4 m-1" />
                </button>
              </div>
            ),
          };
        }
        setProviders({
          selectedProviders: updatedSelectedProviders,
          unSelectedProviders: updatedUnSelectedProviders,
        });
      }
      const updatedGData = gDataRef.current.filter((item) => item.key !== apiId);
      setGData(updatedGData);
    }
  };

  const onAdd = async (provider: any) => {
    updateStepDownConfig(structureId, null, provider.apiId);
    if (provider.floqerApiKeyNotAllowed) updateConnectionToStepDown(structureId, provider.apiId, true);
    // awating it will slow down the adding of provider
    Promise.all(
      [...providersRef.current.selectedProviders, provider].map(async (api: any) => {
        const conn = await getConnection(api);
        // if (conn.isConn) storeId = conn.connection.userConnection.id;
        if (api.floqerApiKeyNotAllowed && conn.isConn) {
          setIsConnectionExists(true);
        } else if (api.floqerApiKeyNotAllowed && !conn.isConn) {
          setIsConnectionExists(false);
        }
      })
    );

    setProviders({
      selectedProviders: [...providersRef.current.selectedProviders, provider],
      unSelectedProviders: providersRef.current.unSelectedProviders.filter((item) => item.apiId !== provider.apiId),
    });

    setGData((prevData) =>
      prevData
        // .filter((node) => node.key !== "new-provider") // Remove any existing "new-provider" nodes
        .map((node) =>
          node.key === "new-provider"
            ? {
                ...node,
                key: provider.apiId,
                title: provider.apiName,
                disabled: false,
                // connection: storeId,
              }
            : node
        )
    );
  };

  const buildTitle = (provider: any, deleteButton: boolean) => (
    <div className="flex items-center justify-between w-full rounded-sm">
      <div className="flex items-center gap-2 w-full">
        <div className="flex justify-between items-center gap-2 bg-white p-2 w-full">
          <div className="flex items-center gap-2 justify-end text-ellipsis">
            <img src={provider.logo} alt={provider.apiName} className="w-6 h-6" />
            <p>{provider.apiName}</p>
          </div>
          <div className="flex items-center gap-2 justify-end">
            {!provider?.floqerApiKeyNotAllowed && credits && credits[provider.apiId] && (
              <span className="text-sm">
                <div className="flex items-center justify-center gap-0.5 rounded-sm">
                  <Coins width="15" height="15" />
                  <span className="font-bold text-sm">{credits[provider.apiId]}</span>
                </div>
              </span>
            )}
            {provider?.apiKeyAllowed && !provider?.floqerApiKeyNotAllowed && <span className="text-xs">or</span>}
            {provider?.apiKeyAllowed && (
              <button className="flex items-center gap-1 bg-green-100 border border-green-700 text-green-700 text-xs p-1 rounded">
                <RiKey2Fill size={15} className="rotate-90" />
              </button>
            )}
          </div>
        </div>
      </div>
      {deleteButton && (
        <button onClick={() => onDelete(provider.apiId)} className="p-1 m-1 disabled:cursor-not-allowed">
          <TrashIcon className="text-red-500 w-4 h-4 m-1" />
        </button>
      )}
    </div>
  );

  const addProvidersDropdown = () => {
    const unSelectedOptions = providersRef.current.unSelectedProviders.map((item: any) => ({
      label: buildTitle(item, false),
      value: item.apiId,
    }));
    const newProviderNode = {
      title: (
        <div className="flex items-center justify-between w-full">
          <Select
            placeholder="Choose the type of workflow"
            className="w-full h-fit p-0 rounded-sm"
            popupClassName="w-full h-fit p-0"
            rootClassName="w-full h-fit"
            value="Select Provider"
            // suffixIcon={<FaChevronDown size={16} color="rgb(156 163 175)" />}
            options={unSelectedOptions}
            onChange={(value) => {
              const selectedProvider = providersRef.current.unSelectedProviders.find(
                (provider) => provider.apiId === value
              );
              if (selectedProvider) {
                onAdd(selectedProvider);
              }
            }}
          />
          <button onClick={() => onDelete("new-provider")} className="p-1 m-1 disabled:cursor-not-allowed">
            <TrashIcon className="text-red-500 w-4 h-4 m-1" />
          </button>
        </div>
      ),
      key: "new-provider", // Temporary key, will be replaced when provider is selected
      isLeaf: true,
      disabled: true,
    };
    setGData((prevData) => [...prevData, newProviderNode]);
  };

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;

    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        // if (data[i].children) {
        //   loop(data[i].children!, key, callback);
        // }
      }
    };
    const data = [...gDataRef.current];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    // if (!info.dropToGap) {
    // Drop on the content
    //   loop(data, dropKey, (item) => {
    // item.children = item.children || [];
    // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
    // item.children.unshift(dragObj);
    //   });
    // } else {
    let ar: TreeDataNode[] = [];
    let i: number;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      // Drop on the top of the drop node
      ar.splice(i!, 0, dragObj!);
      updateStepDownOrder(structureId, dragKey as string, dropKey as string, false);
    } else {
      // Drop on the bottom of the drop node
      ar.splice(i! + 1, 0, dragObj!);
      updateStepDownOrder(structureId, dragKey as string, dropKey as string, true);
    }
    // }
    setGData(data);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Tree: {
            colorBgBase: "#f0f2f5",
          },
        },
      }}
    >
      <Skeleton active loading={gDataRef.current.length === 0}>
        <Tree
          className="draggable-tree bg-[#F6F6F6] p-4 rounded-sm"
          draggable={{
            icon: <MdDragIndicator size={24} color="black" />,
          }}
          titleRender={(node) => {
            if (node.key === "new-provider") {
              return node.title as ReactNode;
            }
            return (
              <StepDownTree
                provider={providersRef.current.selectedProviders.find((provider) => provider.apiId === node.key)}
                payload={payloadConfig.find((config) => config.apiId === node.key)}
                credits={credits?.[node.key.toString()]}
                deleteButton={true}
                onDelete={onDelete}
                updateConnectionToStepDown={(apiId: string, value: boolean) =>
                  updateConnectionToStepDown(structureId, apiId, value)
                }
                setIsConnectionExists={setIsConnectionExists}
              />
            );
          }}
          blockNode
          onDrop={onDrop}
          treeData={gDataRef.current}
        />
      </Skeleton>
      <div className="flex justify-start mx-2 gap-4">
        <button
          className={`text-[16px] flex items-center gap-2 rounded-sm px-3 py-1.5 font-semibold 
            w-fit justify-center z-10 transition-all duration-200 ease-in-out
            ${
              providersRef.current.unSelectedProviders.length === 0
                ? "text-gray-400 bg-gray-200 border-gray-300 cursor-not-allowed"
                : "text-[#1c1c1c] border border-gray-500 bg-white hover:bg-gray-200"
            }`}
          onClick={
            gDataRef.current.findIndex((node) => node.key === "new-provider") === -1 ? addProvidersDropdown : undefined
          }
          // disabled={payloadConfig.length === stepDownAPIs.length}
          disabled={providersRef.current.unSelectedProviders.length === 0}
        >
          <FaPlus className={payloadConfig.length === stepDownAPIs.length ? "text-gray-400" : "text-[#1c1c1c]"} />
          Add provider
        </button>
        <button
          className="text-[16px] flex items-center gap-2 rounded-sm px-3 py-1.5 font-semibold text-[#1c1c1c] bg-white hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center z-10"
          onClick={handleReset}
        >
          <Reset width="14" height="14" />
          Reset to default
        </button>
      </div>
    </ConfigProvider>
  );
};

export default StepDownSearch;
