import { useEffect, useState } from "react";
import "./index.css";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { useWorkflow } from "../../contexts/WorkflowContext";
import Loader from "../Loader";
import { LOADER_TYPES, TabEnum, setStateType } from "../../utils/constants";
import { ResponseConfiguration } from "../../utils/interfaces";
import Run from "./Tabs/Run/Run";
import TableTab from "./Tabs/Table";
import TableChat from "./Tabs/TableChat";
import ReactFlowBuild from "@/Components/Workflow/Tabs/Build/ReactFlowBuild";
import TableAG from "./Tabs/Table/TableAG";

const tabMapper: { [key: string]: string } = {
  build: "Build",
  run: "Run",
  table: "Table",
  chat: "Chat",
};

interface TabButtonProps {
  toSelect: TabEnum;
  selected: TabEnum;
  setSelected: setStateType<TabEnum>;
  beta?: boolean;
}

const TabButton: React.FC<TabButtonProps> = ({ toSelect, selected, setSelected, beta }) => {
  return (
    <button
      className={` h-full flex justify-center items-center border-b-2 gap-1 ${
        selected === toSelect ? " border-primary " : "border-transparent"
      }`}
      onClick={() => setSelected(toSelect)}
    >
      <span
        className={`h-fit w-fit min-w-max rounded-md p-2 flex items-center hover:bg-primary/10 justify-center transition ${
          selected === toSelect ? "bg-primary/10" : "bg-transparent"
        } `}
      >
        {tabMapper[toSelect] || ""}
      </span>
      {beta && (
        <span className="bg-gradient-to-r from-[#D4D0FF] from-0 to-[#FAF2FF] to-100% font-semibold text-[10px] tracking-widest px-1 py-0.5 rounded-md text-gray-600">
          BETA
        </span>
      )}
    </button>
  );
};

const Workflow = () => {
  const [selected, setSelected] = useState<TabEnum>(TabEnum.build);
  const [loading, setLoading] = useState(true);
  const [workflow, setWorkflow] = useState<any>({});
  const [input, setInput] = useState<ResponseConfiguration[]>([]);
  const [v2, setV2] = useState(false);
  const { getAllWorkflowActionsDetails, actions } = useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get("action");
    const v2 = searchParams.get("v2");
    setV2(v2 === "true");
    if (action && action !== "") {
      setSelected(action as TabEnum);
    } else {
      window.history.pushState({}, "", `${window.location.pathname}?action=build&v2=${v2}`);
      setSelected(TabEnum.build);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const v2 = searchParams.get("v2");
    setV2(v2 === "true");
    if (selected !== ("" as TabEnum))
      window.history.pushState({}, "", `${window.location.pathname}?action=${selected}&v2=${v2}`);
  }, [selected]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAllWorkflowActionsDetails(id)
      .then((data) => {
        setWorkflow(data.workflowDetails);
        const inp = data.actions.filter((act: any) => act.actionDetails.type === "input");
        if (inp.length > 0) setInput(inp[0].responseConfiguration || []);
      })
      .catch((err) => console.error("Error fetching workflow details", err))
      .finally(() => setLoading(false));
  }, []);

  const switchComponent = () => {
    switch (selected) {
      case TabEnum.build:
        return <ReactFlowBuild firstActionId={workflow.draftWorkflowConfig?.firstAction} workflow={workflow} />;
      case TabEnum.run:
        return <Run actions={actions} template={input} firstActionId={workflow.publishedWorkflowConfig.firstAction} />;
      case TabEnum.table: {
        if (v2) return <TableAG setTab={setSelected} />;
        return <TableTab setTab={setSelected} />;
      }
      case TabEnum.chat:
        return <TableChat userWorkflowId={id || ""} />;
      default:
        return <ReactFlowBuild firstActionId={workflow.draftWorkflowConfig.firstAction} workflow={workflow} />;
    }
  };

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow" />
      </div>
    );

  return (
    // <DotsBg>
    <div className="w-full flex h-full">
      <div className="flex flex-col w-full items-center z-10">
        <div className="flex gap-4 items-center sticky top-0 justify-center p-4 bg-white w-full z-[100] border-b-[2px] border-gray-300 ">
          <section className="absolute bg-transparent inset-0 h-full w-full flex items-center justify-start p-4">
            <Link
              to="/workflows"
              className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
            >
              <MdOutlineKeyboardBackspace size={30} />
              <span>{workflow.name || workflow.draftWorkflowConfig?.name}</span>
            </Link>
          </section>
          <div className="h-14 rounded-2xl font-bold color-black z-10 px-6 bg-white shadow flex items-center justify-evenly gap-6 self-center w-fit">
            {Object.values(TabEnum).map((tab, idx) => {
              return (
                <TabButton
                  selected={selected}
                  setSelected={setSelected}
                  toSelect={tab}
                  key={idx}
                  beta={tab === TabEnum.chat}
                />
              );
            })}
          </div>
        </div>
        {switchComponent()}
      </div>
    </div>
    // </DotsBg>
  );
};

export default Workflow;
