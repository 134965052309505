/*global chrome*/
import { createContext, useContext } from "react";
import { getAllConnectionsURL, saveConnectionURL, saveOAuthConnectionURL } from "../utils/urls";
import { getBrowserTokens, handleUpdateTokens } from "../utils/functions";

export const ConnectionContext = createContext({
  getAllConnections: async () => {
    return {
      existingConnections: [] as any[],
      userConnections: [] as any[],
    };
  },
  saveConnection: async (_id: string, _connectionId: string, _value: any) => {
    return {
      existingConnections: [] as any[],
      userConnections: [] as any[],
      id: "",
    };
  },
  saveOAuthConnection: async (_connectionId: string, _config: any) => {},
  getConnectionFromType: async (_connectionName: string) => {
    return {
      connectionDoc: {} as any,
      userConnection: {} as any,
    };
  },
  deleteConnectionFromType: async (_connectionName: string) => {},
});

export const useConnections = () => useContext(ConnectionContext);

export default function ConnectionContextProvider({ children }: any) {
  const getAllConnections = async (): Promise<{
    existingConnections: any[];
    userConnections: any[];
  }> => {
    try {
      const url = getAllConnectionsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.connections;
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
      return {
        existingConnections: [],
        userConnections: [],
      };
    }
  };

  const saveConnection = async (
    id: string,
    connectionId: string,
    value: any
  ): Promise<{
    existingConnections: any[];
    userConnections: any[];
    id: string;
  }> => {
    try {
      const url = saveConnectionURL;
      // if(!value.apiKey) throw new Error("API Key is required");
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          id,
          connectionId,
          value,
        }),
      };

      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.connections;
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
      return {
        existingConnections: [],
        userConnections: [],
        id: "",
      };
    }
  };

  const saveOAuthConnection = async (connectionId: string, config: any) => {
    try {
      const url = saveOAuthConnectionURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          connectionId,
          config,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.connections;
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
      return {
        existingConnections: [],
        userConnections: [],
      };
    }
  };

  const getConnectionFromType = async (connectionName: string) => {
    try {
      const url = getAllConnectionsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(`${url}/${connectionName}`, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.connections;
    } catch (error) {
      console.log(error);
      return {
        connectionDoc: {},
        userConnections: {},
      };
    }
  };

  const deleteConnectionFromType = async (connectionName: string) => {
    try {
      const url = getAllConnectionsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "DELETE",
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(`${url}/${connectionName}`, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
    } catch (error) {
      console.log(error);
    }
  };

  const value = {
    getAllConnections,
    saveConnection,
    saveOAuthConnection,
    getConnectionFromType,
    deleteConnectionFromType,
  };

  return <ConnectionContext.Provider value={value}>{children}</ConnectionContext.Provider>;
}
