import LoaderButton from "@/Components/LoaderButton";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { getUserWorkflows, getWorkflowDetailsWithActions, publishDraft2 } from "@/utils/apis";
import { ResponseConfiguration } from "@/utils/interfaces";
import { Modal } from "antd";
import { FaPlus } from "react-icons/fa";

type CreateWorkflowModalProps = {
  modal: boolean;
  createWorkflowParams: { name: string; type: string };
  responses: ResponseConfiguration[];
  payloads: any[];
  parentWorkflowId: any;
  setPayloads: React.Dispatch<React.SetStateAction<any[]>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedWorkflow: React.Dispatch<React.SetStateAction<string>>;
  setCreateWorkflowParams: React.Dispatch<React.SetStateAction<{ name: string; type: string }>>;
  setWorkflows: React.Dispatch<React.SetStateAction<any>>;
};

export const CreateWorkflowModal = ({
  modal,
  createWorkflowParams,
  payloads,
  parentWorkflowId,
  setPayloads,
  setModal,
  setSelectedWorkflow,
  setCreateWorkflowParams,
  setWorkflows,
}: CreateWorkflowModalProps) => {
  const { saveAnotherWorkflowActions, saveWorkflowActions, createNewWorkflow } = useWorkflow();

  const cleanupModalState = () => {
    setCreateWorkflowParams({
      name: "",
      type: "v2",
    });
    setModal(false);
  };

  return (
    <Modal
      centered
      open={modal}
      onCancel={cleanupModalState}
      width={500}
      className="rounded-lg overflow-hidden"
      title={
        <div className="px-2 py-1">
          <div className="flex gap-2 items-center text-xl">
            <FaPlus className="text-primary" />
            <span className="font-semibold">Create Workflow</span>
          </div>
          <p className="text-sm text-gray-500 mt-1">Configure your new workflow settings</p>
        </div>
      }
      footer={
        <div className="flex gap-3 justify-between px-2 py-1">
          <button
            className="w-full px-6 py-2 rounded-lg border border-gray-300 font-medium hover:bg-gray-50 transition-colors"
            onClick={cleanupModalState}
          >
            Cancel
          </button>
          <LoaderButton
            text="Create Workflow"
            loaderText="Creating..."
            btnClasses="w-full px-6 py-2 rounded-lg bg-primary hover:bg-primary/90 text-white font-medium transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            onClickCallback={async () => {
              if (!createWorkflowParams.name.trim()) {
                alert("Workflow Name cannot be empty");
                return;
              }
              // const isV2 = createWorkflowParams.type === "v2" ? true : false;
              // we are only creating v2 workflows
              const isV2 = true;
              const id = await createNewWorkflow(createWorkflowParams.name, "csv", "root", {}, isV2);

              let wfs: any[] = [];
              if (id) {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === "workflow");
                temp[index].inputString = id;
                setPayloads(temp);
                // show workflows dropdown in loading state, while fetching the updated workflows
                setWorkflows({ loading: true, workflows: [] });
                getUserWorkflows()
                  .then((data: any) => {
                    const firebase = data.workflows;
                    const supabase = data.workflowsV2;
                    const concatted = (firebase?.workflows || [])
                      .concat(supabase?.workflows || [])
                      .map((workflow: any) => {
                        if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id))
                          workflow.supabase = false;
                        else workflow.supabase = true;
                        return workflow;
                      });
                    wfs = concatted;
                  })
                  .catch(() => setWorkflows([]));
                const responseData = await getWorkflowDetailsWithActions(id, isV2);
                const action = responseData.response.actions.find((action: any) => action.id === "id1");
                action.responseConfiguration = [];
                await saveWorkflowActions(parentWorkflowId);
                await saveAnotherWorkflowActions(id || "", [action], isV2);
                await publishDraft2(id, isV2);
                // setting the workflows and selected workflow after everything is done helps in keeping the dropdown state in sync
                // and also helps in fetching the inputs.fields for selected workflow after that workflow is fully published,
                // otherwise it will fetch default respConfig of newly created input action
                // setWorkflows(wfs);
                setWorkflows({ loading: false, workflows: wfs });
                setSelectedWorkflow(id);
              }
              cleanupModalState();
            }}
            error=""
            disabled={!createWorkflowParams.name.trim()}
          />
        </div>
      }
    >
      <div className="px-3 py-3">
        <div className="space-y-6">
          {/* Workflow Name Section */}
          <div className="space-y-1.5">
            <label className="text-sm font-medium text-gray-700">
              Workflow Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter workflow name"
              value={createWorkflowParams.name || ""}
              onChange={(e) => setCreateWorkflowParams((prev) => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary transition-colors outline-none"
            />
          </div>

          {/* Workflow Type Section */}
          {/* <div className="space-y-1.5">
            <label className="text-sm font-medium text-gray-700">Workflow Type</label>
            <div className="relative">
              <Select
                placeholder="Select workflow type"
                onChange={(value) => setCreateWorkflowParams((prev) => ({ ...prev, type: value }))}
                defaultValue={"v1"}
                options={[
                  { label: "Workflow", value: "v1" },
                  { label: "Workflow(Beta)", value: "v2" },
                ]}
                className="w-full"
              />
            </div>
          </div> */}

          {/* Workflow folder section */}
        </div>
      </div>
    </Modal>
  );
};
