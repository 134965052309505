import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import Loader from "../../Components/Loader";
import { LOADER_TYPES } from "../../utils/constants";

const SignUpOrg = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [organization, setOrganization] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { createOrg } = useUser();

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (password !== confirmPassword) {
        alert("Passwords do not match. Please try again.");
        setLoading(false);
        return;
      }
      await createOrg(organization, email, password);
      navigate("/pricings");
    } catch (error: any) {
      console.error(error.message);
      alert(error.message + " Please try again.");
      setEmail("");
      setPassword("");
      setOrganization({ name: "", description: "" });
    }
    setLoading(false);
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center border border-[#ccc] shadow-md rounded-2xl p-10 gap-4">
        <h2 className="text-3xl font-bold">
          <span className="text-primary">Organization</span> signup portal
        </h2>

        <p>Use your work email to sign up for your team workspace</p>

        <form className="flex flex-col justify-start items-start gap-4" onSubmit={handleSignUp}>
          <div className="w-full">
            <label>Organization Name:</label>
            <input
              className="w-full rounded-md p-2"
              type="text"
              value={organization.name}
              autoComplete="off"
              onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
              placeholder="Company Inc."
            />
          </div>

          <div className="w-full">
            <label>Organization Description:</label>
            <textarea
              className="w-full rounded-md p-2"
              value={organization.description}
              autoComplete="off"
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  description: e.target.value,
                })
              }
              placeholder="Description of your organization"
            />
          </div>
          <div className="w-full">
            <label>Admin Email:</label>
            <input
              className="w-full rounded-md p-2"
              type="email"
              value={email}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="yourname@company.com"
            />
          </div>

          <div className="w-full">
            <label>Admin Password:</label>
            <input
              className="w-full rounded-md p-2"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
              placeholder="Enter your password"
            />
          </div>
          <div className="w-full">
            <label>Admin Confirm Password:</label>
            <input
              className="w-full rounded-md p-2"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassowrd(e.target.value)}
              required
              autoComplete="off"
              placeholder="Enter your password"
            />
          </div>
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader payload="Signing Up" loaderType={LOADER_TYPES.loading} />
            </div>
          ) : (
            <button className="w-full p-3 bg-primary text-white rounded-lg" type="submit">
              Sign up
            </button>
          )}
        </form>
        <p className="w-full text-center">
          Already part of an organization?{" "}
          <Link to="/" className="text-[#5452f6]">
            Login here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpOrg;
