import { getV2, isV2 } from "./constants";
import { getBrowserTokens, handleUpdateTokens } from "./functions";
import {
  COMPANY_SEARCH_IMPORT_URL,
  COMPANY_SEARCH_IMPORT_URL_V2,
  COMPANY_SEARCH_LIST_FETCH_URL,
  COMPANY_SEARCH_LIST_FETCH_URL_V2,
  COMPANY_SEARCH_PREVIEW_URL,
  COMPANY_SEARCH_PREVIEW_URL_V2,
  deleteTableRecordsURL,
  deleteTableRecordsURLV2,
  deleteWorkflowFolderURL,
  deleteWorkflowFolderURLV2,
  deleteWorkflowURL,
  deleteWorkflowURLV2,
  duplicateWorkflowForUserURL,
  duplicateWorkflowForUserURLV2,
  getAllWorkflowActionsDetailsURL,
  getAllWorkflowActionsDetailsURLV2,
  getDataInfoForWorkflowURL,
  getDataInfoForWorkflowURLV2,
  getTableInfoForWorkflowURL,
  getTableInfoForWorkflowURLV2,
  getUserWorkflowsURL,
  getUserWorkflowsURLV2,
  moveWorkflowURL,
  moveWorkflowURLV2,
  publishDraftURL,
  publishDraftURLV2,
  renameWorkflowFolderURL,
  renameWorkflowFolderURLV2,
  renameWorkflowURL,
  renameWorkflowURLV2,
  revertDraftURL,
  revertDraftURLV2,
  savePaginationURL,
  savePaginationURLV2,
  triggerEngagebayPullContactsURL,
  triggerEngagebayPullContactsURLV2,
  triggerSalesforcePullDataURL,
  triggerSalesforcePullDataURLV2,
} from "./urls";

export const getDataInfoForWorkflow = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? getDataInfoForWorkflowURLV2 : getDataInfoForWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getTableInfoForWorkflow = async (
  userWorkflowId: string,
  sectionNo: number,
  pageSize: number,
  next: boolean,
  dataId: string
): Promise<any> => {
  try {
    const url = getTableInfoForWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section: sectionNo,
        pageSize,
        next,
        dataId,
      }),
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getTableInfoForWorkflowV2 = async (
  userWorkflowId: string,
  sectionNo: number,
  pageSize: number | null,
  pageNo: number,
  filters?: any,
  sorters: any = [],
  searchedText?: string | null
): Promise<any> => {
  try {
    const url = getTableInfoForWorkflowURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section: sectionNo,
        pageSize,
        pageNo,
        filters,
        sorters,
        search_text: searchedText || null,
      }),
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const deleteRecords = async (workflowId: string, dataIds: string[]) => {
  try {
    const v2 = getV2();
    const url = v2 ? deleteTableRecordsURLV2 : deleteTableRecordsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        dataIds,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(`Failed to mark action as reviewed --> ${JSON.stringify(responseData.error)}`);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (err) {
    console.error(err);
  }
};

export const triggerSalesforcePullData = async (userWorkflowId: string) => {
  try {
    const v2 = getV2();
    const url = v2 ? triggerSalesforcePullDataURLV2 : triggerSalesforcePullDataURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const triggerEngagebayPullContacts = async (userWorkflowId: string) => {
  try {
    const v2 = getV2();
    const url = v2 ? triggerEngagebayPullContactsURLV2 : triggerEngagebayPullContactsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const revertDraft = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? revertDraftURLV2 : revertDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to revert workflow");
  }
};

export const publishDraft = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? publishDraftURLV2 : publishDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const publishDraft2 = async (userWorkflowId: string, isV2: boolean): Promise<any> => {
  try {
    // const v2 = getV2();
    const url = isV2 ? publishDraftURLV2 : publishDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getUserWorkflows = async (includeActions = false): Promise<any> => {
  try {
    const url = new URL(getUserWorkflowsURL);
    url.searchParams.append("includeActions", includeActions.toString());

    const urlV2 = new URL(getUserWorkflowsURLV2);
    urlV2.searchParams.append("includeActions", includeActions.toString());

    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    // const response = await fetch(url, options);
    const [response, responseV2] = await Promise.all([fetch(url, options), fetch(urlV2, options)]);
    // const responseData = await response.json();
    const [responseData, responseDataV2] = await Promise.all([response.json(), responseV2.json()]);
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return {
      workflows: responseData,
      workflowsV2: isV2
        ? responseDataV2
        : {
            workflows: [],
            workflowFolders: [],
          },
    };
  } catch (error) {
    console.log(error);
    return { workflows: [], workflowsV2: [] };
  }
};

export const getWorkflowDetailsWithActions = async (userWorkflowId: string, v2: boolean = false): Promise<any> => {
  try {
    const url = v2 ? getAllWorkflowActionsDetailsURLV2 : getAllWorkflowActionsDetailsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow actions");
  }
};

export const deleteWorkflow = async (workflowId: string, v2: boolean): Promise<string> => {
  try {
    const url = v2 ? deleteWorkflowURLV2 : deleteWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to delete workflow");
  }
};

export const deleteWorkflowFolder = async (folderId: string): Promise<string> => {
  try {
    const v2 = getV2();
    const url = v2 ? deleteWorkflowFolderURLV2 : deleteWorkflowFolderURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(`${url}/${folderId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to delete workflow");
  }
};

export const moveWorkflow = async (
  workflowId: string,
  oldWorkflowFolderId: string,
  newWorkflowFolderId: string
): Promise<string> => {
  try {
    const v2 = getV2();
    const url = v2 ? moveWorkflowURLV2 : moveWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        oldWorkflowFolderId,
        newWorkflowFolderId,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to move workflow");
  }
};

export const duplicateWorkflow = async (
  workflowId: string,
  newName: string,
  folder: string,
  v2: boolean
): Promise<string> => {
  try {
    const url = v2 ? duplicateWorkflowForUserURLV2 : duplicateWorkflowForUserURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
        folder,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newWorkflowId;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to copy workflow");
  }
};

export const renameWorkflow = async (workflowId: string, newName: string, v2: boolean): Promise<void> => {
  try {
    const url = v2 ? renameWorkflowURLV2 : renameWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to rename workflow");
  }
};
export const renameWorkflowFolder = async (folderId: string, newName: string): Promise<void> => {
  try {
    const v2 = getV2();
    const url = v2 ? renameWorkflowFolderURLV2 : renameWorkflowFolderURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
      }),
    };
    const response = await fetch(`${url}/${folderId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newWorkflowId;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to Rename Folder");
  }
};

export const savePagination = async (workflowId: string, pagination: number) => {
  try {
    const v2 = getV2();
    const url = v2 ? savePaginationURLV2 : savePaginationURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        pagination,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to save pagination");
  }
};

export const fetchTSLists = async (type: string, payload: any, v2: boolean = false): Promise<any[]> => {
  try {
    let url = v2 ? COMPANY_SEARCH_LIST_FETCH_URL_V2 : COMPANY_SEARCH_LIST_FETCH_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
    };
    const finalURL = new URL(`${url}/${type}`);
    // Loop through the parameters object and append each one to the URL
    for (const [key, value] of Object.entries(payload)) {
      if (value != undefined && value != null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            finalURL.searchParams.append(key, String(item));
          });
        } else {
          finalURL.searchParams.append(key, String(value));
        }
      }
    }
    const res = await fetch(finalURL, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const generateTSPreview = async (filters: any, v2: boolean = false): Promise<any> => {
  try {
    const url = v2 ? COMPANY_SEARCH_PREVIEW_URL_V2 : COMPANY_SEARCH_PREVIEW_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        filters,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const importTSCompanies = async (
  worfklowId: string,
  filters: any,
  count: number,
  total: number,
  v2: boolean = false
): Promise<any> => {
  try {
    const url = v2 ? COMPANY_SEARCH_IMPORT_URL_V2 : COMPANY_SEARCH_IMPORT_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        filters,
        userWorkflowId: worfklowId,
        count,
        total,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};
