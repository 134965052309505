import { Drawer } from "antd";
import KeyValue from "./KeyValue";
import { useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: number | { [key: string]: number };
};

const JsonViewer = ({ open, close, data, title, credits }: Props) => {
  const [newData, setNewData] = useState<any>(data);
  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="30%">
      <div className="flex flex-col gap-2">
        {Object.entries(newData).length > 0 ? (
          Object.entries(newData).map(([key, value]: [string, any]) => (
            <KeyValue key={key} keyName={key} value={value} open={open} />
          ))
        ) : (
          <KeyValue keyName="status" value="Completed" open={open} />
        )}
      </div>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{typeof credits === "number" ? credits : Object.values(credits)[0]}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </Drawer>
  );
};

export default JsonViewer;
