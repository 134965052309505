import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

interface Props {
  dataset: any[];
}

const BarChartComponent: React.FC<Props> = ({ dataset }) => {
  const valueFormatter = (_: number | null, metadata: any) => {
    const data = dataset[metadata?.dataIndex];
    if (!data) {
      return;
    }

    const keys = Object.keys(data);
    const filteredKeys = keys.filter((key) => key !== "date" && key !== "credits");

    if (!filteredKeys?.length) return <div>No credits used</div>;

    return (
      <div>
        {filteredKeys?.map((key, index) => {
          return (
            <div key={index}>
              {key}: {data[key]}
            </div>
          );
        })}
      </div>
    );
  };

  const chartSetting = {
    yAxis: [
      {
        label: "Credits used",
      },
    ],
    series: [{ dataKey: "credits", valueFormatter }],
    height: 300,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      {/* @ts-ignore */}
      <BarChart
        dataset={dataset}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "date",
            tickPlacement: "middle",
            tickLabelPlacement: "middle",
          },
        ]}
        colors={["#5750FF"]}
        {...chartSetting}
      />
    </div>
  );
};

export default BarChartComponent;
