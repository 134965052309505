interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const Reset = ({ width, height, className }: Props) => {
  return (
    <svg
      width={width || "12"}
      height={height || "23"}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5685 4.45484L12.9497 3.87903C10.2161 1.33496 5.78392 1.33496 3.05025 3.87903C0.316583 6.42311 0.316582 10.5479 3.05025 13.0919C5.78392 15.636 10.2161 15.636 12.9497 13.0919C14.5395 11.6125 15.2047 9.59845 14.9455 7.6716M13.5685 4.45484H9.85616M13.5685 4.45484V1"
        stroke="#202020"
        strokeWidth="1.75"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Reset;
