import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Logo from "./Group/Logo";
import Settings from "./Group/Settings";
import WorkflowLibs from "./Group/WorkflowLibs";
import { useUser } from "../../contexts/UserContext";
import Workflows from "./Group/Workflows";
import Connections from "./Group/Connections";
import Credits from "./Group/Credits";
import { Divider } from "antd";

const NavIndex = () => {
  const { collapsed, setCollapsed } = useUser();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className="flex flex-col justify-between h-full border-r-2 border-[#cccccc]"
      style={{
        transitionProperty: "all",
      }}
    >
      <div className="flex flex-col items-center h-[100%] 2xl:p-4 xl:p-3 lg:p-2 md:p-1 p-0.5 gap-10">
        <Logo />
        <WorkflowLibs />
        <Workflows />
        <Connections />
        <Divider className="bg-[#cccccc] mt-auto" />
        <Credits />
        <Settings />
      </div>
      <div className="pr-4 mt-auto">
        <button
          onClick={toggleSidebar}
          className="hamburger p-4 rounded-xl bg-white hover:bg-superchat-blue transition"
          style={{
            transitionProperty: "width, margin",
          }}
        >
          {collapsed ? (
            <FaArrowRight size={20} className="icon text-[#5a5a5a]" />
          ) : (
            <FaArrowLeft size={20} className="icon text-[#5a5a5a]" />
          )}
        </button>
      </div>
    </div>
  );
};

export default NavIndex;
