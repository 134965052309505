import React, { useState } from "react";
import { Input, Button, Table } from "antd";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useWorkflow } from "@/contexts/WorkflowContext";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";

interface SalesNavigatorProps {
  workflowName: string;
  selectedSource: string;
  selectedFolder: string;
  stepsClose: React.Dispatch<React.SetStateAction<boolean>>;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  v2?: boolean;
}

const SalesNavigatorComponent = ({
  workflowName,
  selectedSource,
  selectedFolder,
  stepsClose,
  close,
  v2,
}: SalesNavigatorProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { createNewWorkflow, importSalesNavigatorData } = useWorkflow();
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [url, setUrl] = useState("");
  const [maxCount, setMaxCount] = useState<number>();
  const { getSalesNavigatorPreview } = useWorkflow();
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<number>();
  const [error, setError] = useState("");

  const convertParamToNumber = (param: string): number => {
    const numberPart = param.replace(/[a-zA-Z\+]/g, "");
    let unit = param.replace(/\d+/g, "");
    unit = unit.replace(".", "");
    unit = unit.replace("+", "");
    const number = parseInt(numberPart);
    switch (unit) {
      case "k":
        return number * 1000;
      case "K":
        return number * 1000;
      case "m":
        return number * 1000000;
      case "M":
        return number * 1000000;
      default:
        return number;
    }
  };

  const generatePreview = async () => {
    setLoading(true);
    try {
      const { data } = await getSalesNavigatorPreview(url, v2 ?? false);
      if (!data || data["error"]) {
        setError(data["error"] ?? "Something went wrong. Please try again later.");
        setLoading(false);
        return;
      }
      setPreviewData(data["profiles"] || data["companies"] || []);
      const totalItemsStr = data["total_display_count"];
      setTotalItems(convertParamToNumber(totalItemsStr));
      setPreviewGenerated(true);
      setLoading(false);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  const isAtomic = (value: any) => {
    return typeof value !== "object" && value !== null;
  };

  const columns = React.useMemo(() => {
    if (previewData.length === 0) return [];
    const keys = Object.keys(previewData[0]);
    return keys
      .filter((key) => isAtomic(previewData[0][key]))
      .map((key) => ({
        title: key,
        dataIndex: key,
        key,
        render: (text: any) => <span className="line-clamp-1 min-w-72">{text}</span>,
      }));
  }, [previewData]);

  const data = React.useMemo(() => {
    return previewData.map((item, index) => {
      const obj: any = {};
      Object.keys(item).forEach((key) => {
        if (isAtomic(item[key])) {
          obj[key] = item[key];
        }
      });
      return {
        key: index,
        ...obj,
      };
    });
  }, [previewData]);

  return (
    <div className="flex flex-col overflow-hidden h-screen">
      <div className="flex flex-row w-full items-center justify-between border-b py-3 px-10">
        <button
          onClick={() => close(false)}
          className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
        >
          <MdOutlineKeyboardBackspace size={30} />
          <span>Back</span>
        </button>
        <FaX
          className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer transition-all duration-300 ease-in-out"
          onClick={() => stepsClose(false)}
        />
      </div>
      <div className="px-10 py-5 flex flex-col space-y-6 overflow-y-auto h-full">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
          <div className="flex-1">
            <label htmlFor="searchURL" className="block mb-2 text-xl font-semibold text-black">
              Sales Navigator Search URL
            </label>
            <Input
              placeholder="https://www.linkedin.com/sales/people/293202-johndoe"
              size="large"
              className="h-14"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="flex-1">
            <label htmlFor="maxCount" className="block mb-2 text-xl font-semibold text-black">
              Max Count <span className="text-gray-400">(Optional)</span>
            </label>
            <Input
              type="number"
              size="large"
              className="h-14"
              value={maxCount === null ? undefined : maxCount}
              onChange={(e) => setMaxCount(Number(e.target.value))}
            />
          </div>
        </div>

        {!previewGenerated && (
          <>
            <div className="text-black">
              <p className="font-bold">When using this source, please keep in mind:</p>
              <ul className="list-disc list-inside mt-3 text-sm">
                <li>Your URL must be a Sales Navigator People Search URL, not a Company Search URL.</li>
                <li>Your People Search filters cannot contain a Lead List saved to your personal account.</li>
                <li>
                  While the data source is processing (usually 30 mins, but may take up to 90 mins), you can do other
                  things in Floqer or even close out of the window. The results will be automatically added when ready.
                </li>
              </ul>
            </div>
            <div className="flex justify-start">
              <Button
                onClick={generatePreview}
                disabled={loading || !url}
                type="primary"
                size="large"
                className={`bg-[#4F3FFF] flex flex-row items-center justify-center gap-2 rounded-none text-xl h-12 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-[#4F3FFF] disabled:text-white`}
              >
                {loading && <SpinnerStatus white />}
                {loading ? "Generating Preview..." : "Generate Preview"}
              </Button>
            </div>
          </>
        )}

        {previewGenerated && (
          <div className="flex justify-start">
            <Button
              onClick={generatePreview}
              disabled={loading || !url}
              type="primary"
              size="large"
              className={`bg-[#4F3FFF] flex flex-row items-center justify-center gap-2 rounded-none text-xl h-12 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-[#4F3FFF] disabled:text-white`}
            >
              {loading && <SpinnerStatus white />}
              {loading ? "Generating Preview..." : "Regenerate Preview"}
            </Button>
          </div>
        )}

        {previewGenerated && (
          <div className="mt-6">
            <Table
              bordered
              rowClassName="bg-[#f7fdff] cursor-pointer"
              columns={columns}
              dataSource={data}
              pagination={false}
              rowSelection={{ type: "checkbox" }}
              title={() => (
                <span className="text-xl font-semibold bg-[#f7fdff] text-black">
                  Preview of Data {`(${previewData.length} of ${totalItems ?? 0})`}
                </span>
              )}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row items-center justify-between w-full bg-white p-4 border-t border-gray-200 min-h-[80px]">
        {previewGenerated && (
          <div className="flex flex-row items-center justify-between">
            <>
              <LoaderButton
                disabled={false}
                error=""
                loaderText="Importing..."
                text={`Import ${Math.min(maxCount === undefined ? (totalItems ?? 0) : maxCount, totalItems ?? 0)} Items`}
                onClickCallback={async () => {
                  const id = await createNewWorkflow(
                    workflowName,
                    selectedSource,
                    selectedFolder,
                    {
                      salesNavigatorUrl: url,
                      maxCount: maxCount,
                    },
                    v2
                  );
                  if (id === "") {
                    setError("Failed to create workflow");
                    return;
                  }
                  try {
                    await importSalesNavigatorData(
                      id,
                      url,
                      (totalItems ?? 0).toString(),
                      maxCount === undefined ? null : maxCount,
                      v2
                    );
                  } catch (e) {
                    setError((e as Error).message);
                  }
                  if (v2) {
                    navigate(`/workflow/${id}?v2=${v2}`);
                  } else {
                    navigate(`/workflow/${id}`);
                  }
                }}
                btnClasses="text-white text-md bg-[#4F3FFF] hover:bg-[#4F3FFF] rounded-none"
              />
              <div className="w-[0.5px] h-12 bg-gray-200 mx-4" />
              <span className="text-xl text-[#777777] font-semibold">
                {`Cost: ${Math.min(maxCount === undefined ? (totalItems ?? 0) : maxCount, totalItems ?? 0)} credits`}
              </span>
            </>
          </div>
        )}
        <span className="text-red-500 font-semibold">
          {error && "Invalid: "}
          {error}
        </span>
      </div>
    </div>
  );
};

export default SalesNavigatorComponent;
