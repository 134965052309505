import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import { useCallback, useEffect } from "react";
import { CustomTag } from "./CustomTag";
import Variables from "./Variables";
import StarterKit from "@tiptap/starter-kit";
import { ErrorTag } from "./ErrorTag";
import { keyDownArr1, keyDownArr2 } from "@/utils/constants";
// import Markdown from "react-markdown";

type Props = {
  content: any;
  setContent: (content: any, text: string) => void;
  placeholder?: string;
  refresh: number;
  variables: any[];
};

const TiptapField = ({ content, setContent, placeholder, refresh, variables }: Props) => {
  const extensions = [
    StarterKit.configure({
      codeBlock: false,
      code: false,
    }),
    CustomTag,
    ErrorTag,
    Placeholder.configure({
      placeholder: placeholder || "Type something...",
    }),
  ];
  const editor = useEditor(
    {
      extensions: extensions,
      content: content,
      onUpdate: ({ editor }) => {
        setContent(editor.getJSON(), editor.getText());
      },
    },
    [refresh]
  );

  editor?.setOptions({
    editorProps: {
      handleKeyDown(_view, event) {
        if (event.code === "Tab") {
          event.preventDefault();
          editor?.commands.insertContent("\t");
        } else if (keyDownArr2.includes(event.code)) {
          event.stopPropagation();
        } else if (
          (event.code[0] === "D" || keyDownArr1.includes(event.code)) &&
          event.ctrlKey === false &&
          event.metaKey === false &&
          event.altKey === false
        ) {
          event.preventDefault();
          handleSpecialKeys(event);
        }
      },
    },
  });

  const handleSpecialKeys = useCallback(
    (event: any) => {
      const capsOn = event.getModifierState("CapsLock");
      const shiftPressed = event.getModifierState("Shift");
      if (shiftPressed) {
        event.stopPropagation();
      }
      if (event.code[0] === "D" || event.code === "Slash") {
        editor?.commands.insertContent(event.key);
      } else {
        let isCap = shiftPressed ? !capsOn : capsOn;
        if (isCap) editor?.commands.insertContent(event.code[3]);
        else editor?.commands.insertContent(event.key);
      }
    },
    [editor]
  );

  const getParentAction = (variableId: any) => {
    // variables.forEach((action) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return action.name;
      }
    }
    return "";
  };

  const checkValidNode = (node: any) => {
    if (node.type === "customTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (!parentAction) node.type = "errorTag";
      else node.attrs.parent = parentAction;
    } else if (node.content) {
      node.content.forEach((node: any) => {
        checkValidNode(node);
      });
    }
  };

  useEffect(() => {
    const newContent = content;
    if (!editor) return;
    if (newContent !== "" && newContent.type === "doc" && variables.length > 0)
      newContent.content.forEach((node: any) => {
        checkValidNode(node);
      });
    editor?.commands.setContent(newContent);
  }, [editor, variables]);

  const addVariable = (variable: any, actionName: string) => {
    if (editor?.isFocused) {
      editor?.commands.insertContentAt(editor?.state.selection.$anchor.pos, {
        type: "customTag",
        attrs: {
          id: variable.responseId,
          parent: actionName,
          label: variable.name,
        },
      });
    } else
      editor
        ?.chain()
        .focus()
        .insertContent({
          type: "customTag",
          attrs: {
            id: variable.responseId,
            parent: actionName,
            label: variable.name,
          },
        })
        .run();
    editor?.commands.focus();
  };

  return (
    <div className="w-full flex flex-col gap-6">
      <EditorContent editor={editor} />
      {variables.length > 0 && <Variables variables={variables} addVariable={addVariable} />}
    </div>
  );
};

export default TiptapField;
