import { useEffect } from "react";
import Loader from "../../Components/Loader";
import { LOADER_TYPES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useConnections } from "@/contexts/ConnectionContext";

const HubSpotRedirect = () => {
  const { saveOAuthConnection } = useConnections();
  const navigate = useNavigate();
  useEffect(() => {
    const func = async () => {
      try {
        const url = window.location.href;
        const code = url.substring(url.indexOf("code=") + 5).split("&")[0];
        await saveOAuthConnection("hubspot", { code });
      } catch (error: any) {
        alert(error.message);
      }
      navigate("/connections");
    };
    func();
  }, []);
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loader payload={"Logging in with HubSpot"} loaderType={LOADER_TYPES.loading} />
    </div>
  );
};

export default HubSpotRedirect;
