import { finalConditions, getIconFromType, inputTypeFromVariableType, setStateType } from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { NextActionConfiguration, ResponseStructure } from "../../../utils/interfaces";
import { ConfigProvider, Drawer, Select } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import DeleteAction from "@/assets/SVGs/DeleteAction";

type Props = {
  source: string;
  target: string | null;
  modal: boolean;
  setModal: setStateType<boolean>;
  nextActionConfiguration: NextActionConfiguration;
  variables: any[];
};

const ConditionalModal = ({ source, target, modal, setModal, nextActionConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState("30%");
  const [name, setName] = useState(nextActionConfiguration.name || "Conditional Configuration");
  const [changeName, setChangeName] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [conditions, setConditions] = useState<any[]>(nextActionConfiguration.conditions || []);
  const { updateNextAction, saveWorkflowActions, setPublishWarning } = useWorkflow();
  const { id } = useParams();

  const setWidthPercent = useCallback(() => {
    try {
      if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [drawerWidth]);

  useEffect(() => {
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, []);

  useEffect(() => {
    setConditions(
      nextActionConfiguration.conditions || [
        {
          variable: "",
          condition: "",
          variableType: "",
          values: [],
        },
      ]
    );
  }, [nextActionConfiguration]);

  useEffect(() => {
    if (conditions !== (nextActionConfiguration.conditions || [])) setChanged(true);
    else setChanged(false);
  }, [conditions]);

  useEffect(() => {
    if (!variables) return;
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        setOptions((prev) => [...prev, option]);
      });
    });
  }, [variables]);

  const saveOutputStructure = async () => {
    setLoading(true);
    updateNextAction(source, target, name, conditions);
    await saveWorkflowActions(id || "");
    setModal(false);
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
        }}
        className="ml-auto !font-['Inter'] !p-0 "
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !changed ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || !changed}
            >
              {loading ? "Saving..." : !changed ? "Saved" : "Save"}
            </button>
          </div>
        }
        // check if the credits is a number , if (number) display here
        title={
          <div className="flex items-center gap-2">
            {/* <img src={action.logo} alt={condition.name} className="w-6 h-6" /> */}
            <span
              id="actionName"
              contentEditable={changeName}
              className={changeName ? "border border-black px-2" : ""}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setLoading(true);
                // updateActionName(
                //   action.id,
                //   e.target.textContent || action.name
                // );
                setName(e.target.textContent);
                setChangeName(false);
                await saveWorkflowActions(id || "");
                setChanged(false);
                setLoading(false);
                setPublishWarning(true);
              }}
            >
              {name || ""}
            </span>
            <button
              onClick={() => {
                setChangeName(!changeName);
                const p = document.getElementById("actionName");
                if (!p) return;
                setTimeout(function () {
                  const range = document.createRange();
                  const selection = window.getSelection();
                  range.selectNodeContents(p);
                  range.collapse(false); // <-- Set the cursor at the end of the selection
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  p.focus();
                }, 0);
              }}
              className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                changeName ? "opacity-50" : "opacity-100"
              }`}
            >
              <FaRegEdit />
            </button>
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {conditions &&
            conditions?.map((conditionGroup: any, parentIdx: number) => (
              <div key={parentIdx} className="flex flex-col gap-2 bg-gray-100/20 p-2 border border-1 rounded-md">
                <div className="flex gap-2 w-full items-center">
                  {parentIdx !== 0 && (
                    <div className="flex gap-2 w-full items-center">
                      <p className="tracking-primary font-semibold">Operator</p>
                      <Select
                        showSearch
                        value={conditionGroup.operator || "AND"}
                        onChange={(value) => {
                          const temp = [...conditions];
                          temp[parentIdx].operator = value;
                          setConditions(temp);
                        }}
                        options={[
                          {
                            label: "AND",
                            value: "AND",
                          },
                          {
                            label: "OR",
                            value: "OR",
                          },
                        ]}
                      />
                    </div>
                  )}
                  <button
                    className="text-red-500 ml-auto"
                    onClick={() => {
                      const temp = [...conditions];
                      temp.splice(parentIdx, 1);
                      setConditions(temp);
                    }}
                  >
                    <DeleteAction />
                  </button>
                </div>
                {conditionGroup?.conditions?.map((field: any, idx: number) => (
                  <div key={idx} className="flex gap-4 p-4 border border-gray-300 rounded-lg">
                    <div className="w-[90%] flex flex-col">
                      {idx !== 0 && (
                        <div className="flex gap-2 w-full items-center">
                          <p className="tracking-primary font-semibold">Operator</p>
                          <Select
                            showSearch
                            value={field.operator || "AND"}
                            onChange={(value) => {
                              const temp = [...conditions];
                              temp[parentIdx].conditions[idx].operator = value;
                              setConditions(temp);
                            }}
                            options={[
                              {
                                label: "AND",
                                value: "AND",
                              },
                              {
                                label: "OR",
                                value: "OR",
                              },
                            ]}
                          />
                        </div>
                      )}
                      <div className="flex gap-2 w-full">
                        <p className="tracking-primary font-semibold">If</p>
                        <div className="w-full flex flex-col gap-2">
                          <Select
                            showSearch
                            allowClear
                            className="w-[95%]"
                            value={field.variable}
                            onClear={() => {
                              const temp = [...conditions];
                              temp[parentIdx].conditions[idx] = {
                                variable: "",
                                condition: "",
                                variableType: "",
                                values: [],
                                operator: temp[parentIdx].conditions[idx].operator,
                              };
                              setConditions(temp);
                            }}
                            onChange={(value) => {
                              const temp = [...conditions];
                              const option = options.find((option) => option.id === value);
                              if (field.variableType === option.type) temp[parentIdx].conditions[idx].variable = value;
                              else
                                temp[parentIdx].conditions[idx] = {
                                  variable: value,
                                  condition: "",
                                  variableType: option.type,
                                  values: [],
                                  operator: temp[parentIdx].conditions[idx].operator,
                                };
                              setConditions(temp);
                            }}
                            options={options.map((option, index: number) => ({
                              label: (
                                <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                                  <img src={option.logo} alt="logo" className="w-6 h-6" />
                                  <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                                    {option.actionName}
                                  </span>
                                  <p className="">{">"}</p>
                                  <p className="flex items-center gap-2 justify-center">
                                    <div className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</div>
                                    {option.name}
                                  </p>
                                </div>
                              ),
                              value: option.id,
                              name: `${option.actionName} - ${option.name}`,
                            }))}
                            optionFilterProp="name"
                          />
                          {field.variableType !== "" && (
                            <Select
                              showSearch
                              allowClear
                              className="w-[95%]"
                              value={field.condition}
                              onChange={(value) => {
                                const temp = [...conditions];
                                temp[parentIdx].conditions[idx].condition = value;
                                setConditions(temp);
                              }}
                              options={finalConditions(field.variableType) || []}
                            />
                          )}
                          {!(
                            field.condition === "" ||
                            field.condition === "is empty" ||
                            field.condition === "is not empty"
                          ) && (
                            <input
                              type={inputTypeFromVariableType(field.variableType)}
                              placeholder="value..."
                              className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                              value={field.values?.[0] || ""}
                              onChange={(e) => {
                                const temp = [...conditions];
                                temp[parentIdx].conditions[idx].values[0] = e.target.value;
                                setConditions(temp);
                              }}
                            />
                          )}
                          {field.condition === "is between" && (
                            <>
                              <p>To:</p>
                              <input
                                type={inputTypeFromVariableType(field.variableType)}
                                placeholder="value 2..."
                                className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                                value={field.values?.[1] || ""}
                                onChange={(e) => {
                                  const temp = [...conditions];
                                  temp[parentIdx].conditions[idx].values[1] = e.target.value;
                                  setConditions(temp);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {idx !== 0 && (
                      <button
                        className="text-red-500 ml-auto"
                        onClick={() => {
                          const temp = [...conditions];
                          temp[parentIdx].conditions.splice(idx, 1);
                          setConditions(temp);
                        }}
                      >
                        <DeleteAction />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  className="border w-fit border-black rounded-lg p-2"
                  onClick={() => {
                    const temp = [...conditions];
                    temp[parentIdx].conditions.push({
                      variable: "",
                      condition: "",
                      variableType: "",
                      values: [],
                      operator: "AND",
                    });
                    setConditions(temp);
                  }}
                >
                  + Add Sub-Condition
                </button>
              </div>
            ))}
          <button
            className="border w-fit border-black rounded-lg p-2"
            onClick={() => {
              const temp = [...conditions];
              temp.push({
                conditions: [
                  {
                    variable: "",
                    condition: "",
                    variableType: "",
                    values: [],
                    operator: "AND",
                  },
                ],
              });
              setConditions(temp);
            }}
          >
            + Add condition
          </button>

          {/* {conditions &&
            conditions.map((field: Condition, idx) => {
              return (
                <div key={idx} className="flex gap-4 p-4 border border-gray-300 rounded-lg">
                  <div className="w-[90%] flex flex-col">
                    {idx !== 0 && (
                      <div className="flex gap-2 w-full items-center">
                        <p className="tracking-primary font-semibold">Operator</p>
                        <Select
                          showSearch
                          value={field.operator || "AND"}
                          onChange={(value) => {
                            const temp = [...conditions];
                            temp[idx].operator = value;
                            setConditions(temp);
                          }}
                          options={[
                            {
                              label: "AND",
                              value: "AND",
                            },
                            {
                              label: "OR",
                              value: "OR",
                            },
                          ]}
                        />
                      </div>
                    )}
                    <div className="flex gap-2 w-full">
                      <p className="tracking-primary font-semibold">If</p>
                      <div className="w-full flex flex-col gap-2">
                        <Select
                          showSearch
                          allowClear
                          className="w-[95%]"
                          value={field.variable}
                          onClear={() => {
                            const temp = [...conditions];
                            temp[idx] = {
                              variable: "",
                              condition: "",
                              variableType: "",
                              values: [],
                              operator: temp[idx].operator,
                            };
                            setConditions(temp);
                          }}
                          onChange={(value) => {
                            const temp = [...conditions];
                            const option = options.find((option) => option.id === value);
                            if (field.variableType === option.type) temp[idx].variable = value;
                            else
                              temp[idx] = {
                                variable: value,
                                condition: "",
                                variableType: option.type,
                                values: [],
                              };
                            setConditions(temp);
                          }}
                          options={options.map((option, index: number) => ({
                            label: (
                              <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                                <img src={option.logo} alt="logo" className="w-6 h-6" />
                                <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                                  {option.actionName}
                                </span>
                                <p className="">{">"}</p>
                                <p className="flex items-center gap-2 justify-center">
                                  
                                  <div className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</div>
                                  {option.name}
                                </p>
                              </div>
                            ),
                            value: option.id,
                            name: `${option.actionName} - ${option.name}`,
                          }))}
                          optionFilterProp="name"
                        />
                        {field.variableType !== "" && (
                          <Select
                            showSearch
                            allowClear
                            className="w-[95%]"
                            value={field.condition}
                            onChange={(value) => {
                              const temp = [...conditions];
                              temp[idx].condition = value;
                              setConditions(temp);
                            }}
                            options={finalConditions(field.variableType) || []}
                          />
                        )}
                        {!(
                          field.condition === "" ||
                          field.condition === "is empty" ||
                          field.condition === "is not empty"
                        ) && (
                          // <div className="flex  gap-2">
                          <input
                            type={inputTypeFromVariableType(field.variableType)}
                            placeholder="value..."
                            className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                            value={field.values[0]}
                            onChange={(e) => {
                              const temp = [...conditions];
                              temp[idx].values[0] = e.target.value;
                              setConditions(temp);
                            }}
                          />
                        )}
                        {field.condition === "is between" && (
                          <>
                            <p>To:</p>
                            <input
                              type={inputTypeFromVariableType(field.variableType)}
                              placeholder="value 2..."
                              className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                              value={field.values[1] || ""}
                              onChange={(e) => {
                                const temp = [...conditions];
                                temp[idx].values[1] = e.target.value;
                                setConditions(temp);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    className="text-red-500 ml-auto"
                    onClick={() => {
                      const temp = [...conditions];
                      temp.splice(idx, 1);
                      setConditions(temp);
                    }}
                  >
                    <DeleteAction />
                  </button>
                </div>
              );
            })} */}
          {/* <button
            className="border w-fit border-black rounded-lg p-2"
            onClick={() => {
              setConditions((prev) => [
                ...prev,
                {
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                },
              ]);
            }}
          >
            + Add condition
          </button> */}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default ConditionalModal;
