import { useEffect } from "react";
import { setStateType } from "@/utils/constants";
import Markdown from "react-markdown";
import { fetchDocContent } from "@/utils/functions";

interface Props {
  url: string;
  content: string;
  setContent: setStateType<string>;
}

const KeyValue = ({ url, content, setContent }: Props) => {
  useEffect(() => {
    if (!url) return;
    fetchDocContent(url)
      .then((res) => setContent(res))
      .catch((err) => console.error(err));
  }, [url]);

  return (
    // <div
    //   className="p-2 bg-gray-100 border border-gray-200 rounded flex flex-col items-start flex-wrap gap-2
    //   [&_*]:max-w-full [&_*]:overflow-x-auto relative group"
    // >
    // {/* <span className="font-semibold text-lg self-start">{keyName}</span> */}
    <span className="text-gray-500 text-start">
      <Markdown>{content}</Markdown>
    </span>
    // </div>
  );
};

export default KeyValue;
