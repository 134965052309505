import { mergeAttributes, Node } from "@tiptap/core";
import { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model";

export type ErrorTagOptions = {
  HTMLAttributes: Record<string, any>;
  /** @deprecated use renderText and renderHTML instead  */
  renderLabel?: (props: { options: ErrorTagOptions; node: ProseMirrorNode }) => string;
  renderText: (props: { options: ErrorTagOptions; node: ProseMirrorNode }) => string;
  renderHTML: (props: { options: ErrorTagOptions; node: ProseMirrorNode }) => DOMOutputSpec;
};

export const ErrorTag = Node.create<ErrorTagOptions>({
  name: "errorTag",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ node }) {
        return `{{${node.attrs.id}}}`;
      },
      renderHTML({ options, node }) {
        return [
          "span",
          {
            ...options.HTMLAttributes,
            "data-id": node.attrs.id,
            class: "bg-red-200 text-red-600 p-1 rounded-md",
            // style: "color: red",
          },
          `${node.attrs.label} - Missing Data`,
        ];
      },
    };
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
            style: "color: red",
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const html = this.options.renderHTML({
      options: this.options,
      node,
    });

    if (typeof html === "string") {
      return [
        "span",
        mergeAttributes(
          { "data-type": this.name },
          { style: "color: red" },
          this.options.HTMLAttributes,
          HTMLAttributes
        ),
        html,
      ];
    }
    return html;
  },

  renderText({ node }) {
    return this.options.renderText({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText("", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isMention;
        }),
    };
  },
});
