import React, { useEffect, useState } from "react";
import { CELL_STATUS_TYPE } from "@/utils/constants";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { Dropdown, MenuProps } from "antd";
import { IoReload } from "react-icons/io5";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

interface Props {
  actionId: string;
  userWorkflowId: string;
  section: number;
  style: any;
  showModalNearCell: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedRows: any;
  setSelectedRows: () => void;
  filters?: any;
  updateRows?: (selectedRows: any[] | "all") => void;
  // refresh?: () => void;
}

const RegenMenu: React.FC<Props> = ({
  actionId,
  userWorkflowId,
  section,
  style,
  showModalNearCell,
  selectedRows,
  setSelectedRows,
  updateRows,
  // refresh,
  filters = {},
}) => {
  const { retryAction, dataRef } = useWorkflow();
  const [open, setOpen] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [dataIds, setDataIds] = useState<string[]>([]);
  const [failedDataIds, setFailedDataIds] = useState<string[]>([]);

  useEffect(() => {
    const wrapperDataArr = dataRef.current.reduce((acc, curr) => {
      return [...acc, curr[actionId || ""]];
    }, []);
    setDataIds(() => dataRef.current.map((datas: any) => datas.key));
    setFailedDataIds(() =>
      wrapperDataArr
        .filter((cellData: any) => cellData && [CELL_STATUS_TYPE.FAILED].includes(cellData.status))
        .map((cellData: any) => cellData?.extra?.dataId)
    );
  }, [actionId]);

  const handleRegenerateAll = async () => {
    setLoadingAll(true);
    updateRows && updateRows("all");
    retryAction(actionId || "", userWorkflowId, [], dataIds, section, "all", filters)
      .catch((err) => {
        // refresh && refresh();
        setLoadingAll(false);
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingAll(false);
        // refresh && refresh();
      });
  };

  const handleRegenerateColumnAll = async () => {
    setLoadingAll(true);
    updateRows && updateRows("all");
    retryAction(actionId || "", userWorkflowId, [], dataIds, section, "singleColumn", filters)
      .catch((err) => {
        setLoadingAll(false);
        // refresh && refresh();
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingAll(false);
        // refresh && refresh();
      });
  };

  const handleRegenerateSelected = async () => {
    updateRows && updateRows(selectedRows);

    setLoadingAll(true);
    retryAction(actionId || "", userWorkflowId, selectedRows, selectedRows, section, "selected", filters)
      .catch((err) => {
        setLoadingAll(false);
        // refresh && refresh();
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingAll(false);
        setSelectedRows();
        // refresh && refresh();
      });
  };

  const handleRegenerateColumnSelected = async () => {
    setLoadingAll(true);
    updateRows && updateRows(selectedRows);
    retryAction(actionId || "", userWorkflowId, selectedRows, selectedRows, section, "singleColumnSelected", filters)
      .catch((err) => {
        setLoadingAll(false);
        // refresh && refresh();
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingAll(false);
        setSelectedRows();
        // refresh && refresh();
      });
  };

  const handleRegenerateFailed = async () => {
    updateRows && updateRows("all");

    setLoadingFailed(true);
    retryAction(actionId || "", userWorkflowId, [], failedDataIds, section, "failed", filters)
      .catch((err) => {
        setLoadingAll(false);
        // refresh && refresh();
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingFailed(false);
        // refresh && refresh();
      });
  };

  const handleRegenerateFailedColumn = async () => {
    setLoadingFailed(true);
    updateRows && updateRows("all");
    retryAction(actionId || "", userWorkflowId, [], failedDataIds, section, "failedSingle", filters)
      .catch((err) => {
        setLoadingAll(false);
        // refresh && refresh();
        console.error(err);
      })
      .finally(() => {
        setOpen(false);
        setLoadingFailed(false);
        // refresh && refresh();
      });
  };

  const items: MenuProps["items"] = [
    !selectedRows.length && {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          {loadingAll && <SpinnerStatus />}
          <span>Run all rows and continue</span>
        </div>
      ),
      onClick: handleRegenerateAll,
      disabled: loadingAll || loadingFailed,
    },
    selectedRows.length && {
      key: "3",
      label: (
        <div className={`flex items-center gap-2`}>
          {loadingFailed && <SpinnerStatus />}
          <span>Run {selectedRows.length} row(s) and continue</span>
        </div>
      ),
      onClick: handleRegenerateSelected,
      disabled: loadingFailed || loadingAll,
    },
    !selectedRows.length && {
      key: "2",
      label: (
        <div className="flex items-center gap-2">
          {loadingFailed && <SpinnerStatus />}
          <span>Run failed/pending rows and continue</span>
        </div>
      ),
      onClick: handleRegenerateFailed,
      disabled: loadingFailed || loadingAll,
    },
    !selectedRows.length && {
      key: "4",
      label: (
        <div className="flex items-center gap-2">
          {loadingFailed && <SpinnerStatus />}
          <span>Run all rows in this column</span>
        </div>
      ),
      onClick: handleRegenerateColumnAll,
      disabled: loadingFailed || loadingAll,
    },
    !selectedRows.length && {
      key: "6",
      label: (
        <div className="flex items-center gap-2">
          {loadingFailed && <SpinnerStatus />}
          <span>Run failed/pending rows in this column</span>
        </div>
      ),
      onClick: handleRegenerateFailedColumn,
      disabled: loadingFailed || loadingAll,
    },
    selectedRows.length && {
      key: "5",
      label: (
        <div className={`flex items-center gap-2`}>
          {loadingFailed && <SpinnerStatus />}
          <span>Run {selectedRows.length} row(s) in this column</span>
        </div>
      ),
      onClick: handleRegenerateColumnSelected,
      disabled: loadingFailed || loadingAll,
    },
  ];

  return (
    <Dropdown
      open={open}
      overlayStyle={{ ...style, height: 100 }}
      onOpenChange={(o) => {
        // if (loadingAll || loadingFailed) return;
        setOpen(o);
      }}
      trigger={["click"]}
      menu={{ items }}
    >
      <button
        type="button"
        onClick={(e) => {
          // setRegenId(() => actionId || "");
          setOpen(true);
          showModalNearCell(e);
        }}
        className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
      >
        {loadingAll || loadingFailed ? <SpinnerStatus /> : <IoReload size={"12px"} />}
      </button>
    </Dropdown>
  );
};

export default RegenMenu;
