import Loader from "@/Components/Loader";
import { LOADER_TYPES } from "@/utils/constants";

type Props = {
  modal: boolean;
};

const PasteRowsModal = ({ modal }: Props) => {
  return (
    modal && (
      <div className="h-full w-full bg-white/60 flex items-center justify-center absolute">
        <Loader loaderType={LOADER_TYPES.pasting} payload="data into the table" />
      </div>
    )
  );
};

export default PasteRowsModal;
