export const countriesDropdown = [
  {
    id: "AF",
    name: "Afghanistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1eb.svg",
  },
  {
    id: "AX",
    name: "\u00c5land Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fd.svg",
  },
  {
    id: "AL",
    name: "Albania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f1.svg",
  },
  {
    id: "DZ",
    name: "Algeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ff.svg",
  },
  {
    id: "AS",
    name: "American Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f8.svg",
  },
  {
    id: "AD",
    name: "Andorra",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e9.svg",
  },
  {
    id: "AO",
    name: "Angola",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f4.svg",
  },
  {
    id: "AI",
    name: "Anguilla",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ee.svg",
  },
  {
    id: "AQ",
    name: "Antarctica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f6.svg",
  },
  {
    id: "AG",
    name: "Antigua and Barbuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ec.svg",
  },
  {
    id: "AR",
    name: "Argentina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f7.svg",
  },
  {
    id: "AM",
    name: "Armenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f2.svg",
  },
  {
    id: "AW",
    name: "Aruba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fc.svg",
  },
  {
    id: "AU",
    name: "Australia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fa.svg",
  },
  {
    id: "AT",
    name: "Austria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f9.svg",
  },
  {
    id: "AZ",
    name: "Azerbaijan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ff.svg",
  },
  {
    id: "BS",
    name: "Bahamas",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f8.svg",
  },
  {
    id: "BH",
    name: "Bahrain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ed.svg",
  },
  {
    id: "BD",
    name: "Bangladesh",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e9.svg",
  },
  {
    id: "BB",
    name: "Barbados",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e7.svg",
  },
  {
    id: "BY",
    name: "Belarus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fe.svg",
  },
  {
    id: "BE",
    name: "Belgium",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ea.svg",
  },
  {
    id: "BZ",
    name: "Belize",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ff.svg",
  },
  {
    id: "BJ",
    name: "Benin",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ef.svg",
  },
  {
    id: "BM",
    name: "Bermuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f2.svg",
  },
  {
    id: "BT",
    name: "Bhutan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f9.svg",
  },
  {
    id: "BO",
    name: "Bolivia, Plurinational State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f4.svg",
  },
  {
    id: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f6.svg",
  },
  {
    id: "BA",
    name: "Bosnia and Herzegovina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e6.svg",
  },
  {
    id: "BW",
    name: "Botswana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fc.svg",
  },
  {
    id: "BV",
    name: "Bouvet Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fb.svg",
  },
  {
    id: "BR",
    name: "Brazil",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f7.svg",
  },
  {
    id: "IO",
    name: "British Indian Ocean Territory",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f4.svg",
  },
  {
    id: "BN",
    name: "Brunei Darussalam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f3.svg",
  },
  {
    id: "BG",
    name: "Bulgaria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ec.svg",
  },
  {
    id: "BF",
    name: "Burkina Faso",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1eb.svg",
  },
  {
    id: "BI",
    name: "Burundi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ee.svg",
  },
  {
    id: "KH",
    name: "Cambodia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ed.svg",
  },
  {
    id: "CM",
    name: "Cameroon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f2.svg",
  },
  {
    id: "CA",
    name: "Canada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg",
  },
  {
    id: "CV",
    name: "Cape Verde",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fb.svg",
  },
  {
    id: "KY",
    name: "Cayman Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fe.svg",
  },
  {
    id: "CF",
    name: "Central African Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1eb.svg",
  },
  {
    id: "TD",
    name: "Chad",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e9.svg",
  },
  {
    id: "CL",
    name: "Chile",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f1.svg",
  },
  {
    id: "CN",
    name: "China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f3.svg",
  },
  {
    id: "CX",
    name: "Christmas Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fd.svg",
  },
  {
    id: "CC",
    name: "Cocos (Keeling) Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e8.svg",
  },
  {
    id: "CO",
    name: "Colombia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f4.svg",
  },
  {
    id: "KM",
    name: "Comoros",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f2.svg",
  },
  {
    id: "CG",
    name: "Congo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ec.svg",
  },
  {
    id: "CD",
    name: "Congo, the Democratic Republic of the",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e9.svg",
  },
  {
    id: "CK",
    name: "Cook Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f0.svg",
  },
  {
    id: "CR",
    name: "Costa Rica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f7.svg",
  },
  {
    id: "CI",
    name: "C\u00f4te d'Ivoire",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ee.svg",
  },
  {
    id: "HR",
    name: "Croatia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f7.svg",
  },
  {
    id: "CU",
    name: "Cuba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fa.svg",
  },
  {
    id: "CW",
    name: "Cura\u00e7ao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fc.svg",
  },
  {
    id: "CY",
    name: "Cyprus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fe.svg",
  },
  {
    id: "CZ",
    name: "Czech Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ff.svg",
  },
  {
    id: "DK",
    name: "Denmark",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f0.svg",
  },
  {
    id: "DJ",
    name: "Djibouti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ef.svg",
  },
  {
    id: "DM",
    name: "Dominica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f2.svg",
  },
  {
    id: "DO",
    name: "Dominican Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f4.svg",
  },
  {
    id: "EC",
    name: "Ecuador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e8.svg",
  },
  {
    id: "EG",
    name: "Egypt",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ec.svg",
  },
  {
    id: "SV",
    name: "El Salvador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fb.svg",
  },
  {
    id: "GQ",
    name: "Equatorial Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f6.svg",
  },
  {
    id: "ER",
    name: "Eritrea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f7.svg",
  },
  {
    id: "EE",
    name: "Estonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ea.svg",
  },
  {
    id: "ET",
    name: "Ethiopia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f9.svg",
  },
  {
    id: "FK",
    name: "Falkland Islands (Malvinas)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f0.svg",
  },
  {
    id: "FO",
    name: "Faroe Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f4.svg",
  },
  {
    id: "FJ",
    name: "Fiji",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ef.svg",
  },
  {
    id: "FI",
    name: "Finland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ee.svg",
  },
  {
    id: "FR",
    name: "France",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f7.svg",
  },
  {
    id: "GF",
    name: "French Guiana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1eb.svg",
  },
  {
    id: "PF",
    name: "French Polynesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1eb.svg",
  },
  {
    id: "TF",
    name: "French Southern Territories",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1eb.svg",
  },
  {
    id: "GA",
    name: "Gabon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e6.svg",
  },
  {
    id: "GM",
    name: "Gambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f2.svg",
  },
  {
    id: "GE",
    name: "Georgia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ea.svg",
  },
  {
    id: "DE",
    name: "Germany",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ea.svg",
  },
  {
    id: "GH",
    name: "Ghana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ed.svg",
  },
  {
    id: "GI",
    name: "Gibraltar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ee.svg",
  },
  {
    id: "GR",
    name: "Greece",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f7.svg",
  },
  {
    id: "GL",
    name: "Greenland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f1.svg",
  },
  {
    id: "GD",
    name: "Grenada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e9.svg",
  },
  {
    id: "GP",
    name: "Guadeloupe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f5.svg",
  },
  {
    id: "GU",
    name: "Guam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fa.svg",
  },
  {
    id: "GT",
    name: "Guatemala",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f9.svg",
  },
  {
    id: "GG",
    name: "Guernsey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ec.svg",
  },
  {
    id: "GN",
    name: "Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f3.svg",
  },
  {
    id: "GW",
    name: "Guinea-Bissau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fc.svg",
  },
  {
    id: "GY",
    name: "Guyana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fe.svg",
  },
  {
    id: "HT",
    name: "Haiti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f9.svg",
  },
  {
    id: "HM",
    name: "Heard Island and McDonald Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f2.svg",
  },
  {
    id: "VA",
    name: "Holy See (Vatican City State)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e6.svg",
  },
  {
    id: "HN",
    name: "Honduras",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f3.svg",
  },
  {
    id: "HK",
    name: "Hong Kong",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f0.svg",
  },
  {
    id: "HU",
    name: "Hungary",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1fa.svg",
  },
  {
    id: "IS",
    name: "Iceland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f8.svg",
  },
  {
    id: "IN",
    name: "India",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f3.svg",
  },
  {
    id: "ID",
    name: "Indonesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e9.svg",
  },
  {
    id: "IR",
    name: "Iran, Islamic Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f7.svg",
  },
  {
    id: "IQ",
    name: "Iraq",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f6.svg",
  },
  {
    id: "IE",
    name: "Ireland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1ea.svg",
  },
  {
    id: "IM",
    name: "Isle of Man",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f2.svg",
  },
  {
    id: "IL",
    name: "Israel",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f1.svg",
  },
  {
    id: "IT",
    name: "Italy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f9.svg",
  },
  {
    id: "JM",
    name: "Jamaica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f2.svg",
  },
  {
    id: "JP",
    name: "Japan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f5.svg",
  },
  {
    id: "JE",
    name: "Jersey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1ea.svg",
  },
  {
    id: "JO",
    name: "Jordan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f4.svg",
  },
  {
    id: "KZ",
    name: "Kazakhstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ff.svg",
  },
  {
    id: "KE",
    name: "Kenya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ea.svg",
  },
  {
    id: "KI",
    name: "Kiribati",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ee.svg",
  },
  {
    id: "KP",
    name: "Korea, Democratic People's Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f5.svg",
  },
  {
    id: "KR",
    name: "Korea, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f7.svg",
  },
  {
    id: "KW",
    name: "Kuwait",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fc.svg",
  },
  {
    id: "KG",
    name: "Kyrgyzstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ec.svg",
  },
  {
    id: "LA",
    name: "Lao People's Democratic Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e6.svg",
  },
  {
    id: "LV",
    name: "Latvia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fb.svg",
  },
  {
    id: "LB",
    name: "Lebanon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e7.svg",
  },
  {
    id: "LS",
    name: "Lesotho",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f8.svg",
  },
  {
    id: "LR",
    name: "Liberia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f7.svg",
  },
  {
    id: "LY",
    name: "Libya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fe.svg",
  },
  {
    id: "LI",
    name: "Liechtenstein",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1ee.svg",
  },
  {
    id: "LT",
    name: "Lithuania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f9.svg",
  },
  {
    id: "LU",
    name: "Luxembourg",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fa.svg",
  },
  {
    id: "MO",
    name: "Macao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f4.svg",
  },
  {
    id: "MK",
    name: "Macedonia, the Former Yugoslav Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f0.svg",
  },
  {
    id: "MG",
    name: "Madagascar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ec.svg",
  },
  {
    id: "MW",
    name: "Malawi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fc.svg",
  },
  {
    id: "MY",
    name: "Malaysia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fe.svg",
  },
  {
    id: "MV",
    name: "Maldives",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fb.svg",
  },
  {
    id: "ML",
    name: "Mali",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f1.svg",
  },
  {
    id: "MT",
    name: "Malta",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f9.svg",
  },
  {
    id: "MH",
    name: "Marshall Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ed.svg",
  },
  {
    id: "MQ",
    name: "Martinique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f6.svg",
  },
  {
    id: "MR",
    name: "Mauritania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f7.svg",
  },
  {
    id: "MU",
    name: "Mauritius",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fa.svg",
  },
  {
    id: "YT",
    name: "Mayotte",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1f9.svg",
  },
  {
    id: "MX",
    name: "Mexico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fd.svg",
  },
  {
    id: "FM",
    name: "Micronesia, Federated States of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f2.svg",
  },
  {
    id: "MD",
    name: "Moldova, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e9.svg",
  },
  {
    id: "MC",
    name: "Monaco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e8.svg",
  },
  {
    id: "MN",
    name: "Mongolia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f3.svg",
  },
  {
    id: "ME",
    name: "Montenegro",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ea.svg",
  },
  {
    id: "MS",
    name: "Montserrat",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f8.svg",
  },
  {
    id: "MA",
    name: "Morocco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e6.svg",
  },
  {
    id: "MZ",
    name: "Mozambique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ff.svg",
  },
  {
    id: "MM",
    name: "Myanmar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f2.svg",
  },
  {
    id: "NA",
    name: "Namibia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e6.svg",
  },
  {
    id: "NR",
    name: "Nauru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f7.svg",
  },
  {
    id: "NP",
    name: "Nepal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f5.svg",
  },
  {
    id: "NL",
    name: "Netherlands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f1.svg",
  },
  {
    id: "NC",
    name: "New Caledonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e8.svg",
  },
  {
    id: "NZ",
    name: "New Zealand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ff.svg",
  },
  {
    id: "NI",
    name: "Nicaragua",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ee.svg",
  },
  {
    id: "NE",
    name: "Niger",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ea.svg",
  },
  {
    id: "NG",
    name: "Nigeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ec.svg",
  },
  {
    id: "NU",
    name: "Niue",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1fa.svg",
  },
  {
    id: "NF",
    name: "Norfolk Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1eb.svg",
  },
  {
    id: "MP",
    name: "Northern Mariana Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f5.svg",
  },
  {
    id: "NO",
    name: "Norway",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f4.svg",
  },
  {
    id: "OM",
    name: "Oman",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f4-1f1f2.svg",
  },
  {
    id: "PK",
    name: "Pakistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f0.svg",
  },
  {
    id: "PW",
    name: "Palau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fc.svg",
  },
  {
    id: "PS",
    name: "Palestine, State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f8.svg",
  },
  {
    id: "PA",
    name: "Panama",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1e6.svg",
  },
  {
    id: "PG",
    name: "Papua New Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ec.svg",
  },
  {
    id: "PY",
    name: "Paraguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fe.svg",
  },
  {
    id: "PE",
    name: "Peru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ea.svg",
  },
  {
    id: "PH",
    name: "Philippines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ed.svg",
  },
  {
    id: "PN",
    name: "Pitcairn",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f3.svg",
  },
  {
    id: "PL",
    name: "Poland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f1.svg",
  },
  {
    id: "PT",
    name: "Portugal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f9.svg",
  },
  {
    id: "PR",
    name: "Puerto Rico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f7.svg",
  },
  {
    id: "QA",
    name: "Qatar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f6-1f1e6.svg",
  },
  {
    id: "RE",
    name: "R\u00e9union",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1ea.svg",
  },
  {
    id: "RO",
    name: "Romania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f4.svg",
  },
  {
    id: "RU",
    name: "Russian Federation",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fa.svg",
  },
  {
    id: "RW",
    name: "Rwanda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fc.svg",
  },
  {
    id: "BL",
    name: "Saint Barth\u00e9lemy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f1.svg",
  },
  {
    id: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ed.svg",
  },
  {
    id: "KN",
    name: "Saint Kitts and Nevis",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f3.svg",
  },
  {
    id: "LC",
    name: "Saint Lucia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e8.svg",
  },
  {
    id: "MF",
    name: "Saint Martin (French part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1eb.svg",
  },
  {
    id: "PM",
    name: "Saint Pierre and Miquelon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f2.svg",
  },
  {
    id: "VC",
    name: "Saint Vincent and the Grenadines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e8.svg",
  },
  {
    id: "WS",
    name: "Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1f8.svg",
  },
  {
    id: "SM",
    name: "San Marino",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f2.svg",
  },
  {
    id: "ST",
    name: "Sao Tome and Principe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f9.svg",
  },
  {
    id: "SA",
    name: "Saudi Arabia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e6.svg",
  },
  {
    id: "SN",
    name: "Senegal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f3.svg",
  },
  {
    id: "RS",
    name: "Serbia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f8.svg",
  },
  {
    id: "SC",
    name: "Seychelles",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e8.svg",
  },
  {
    id: "SL",
    name: "Sierra Leone",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f1.svg",
  },
  {
    id: "SG",
    name: "Singapore",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ec.svg",
  },
  {
    id: "SX",
    name: "Sint Maarten (Dutch part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fd.svg",
  },
  {
    id: "SK",
    name: "Slovakia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f0.svg",
  },
  {
    id: "SI",
    name: "Slovenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ee.svg",
  },
  {
    id: "SB",
    name: "Solomon Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e7.svg",
  },
  {
    id: "SO",
    name: "Somalia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f4.svg",
  },
  {
    id: "ZA",
    name: "South Africa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1e6.svg",
  },
  {
    id: "GS",
    name: "South Georgia and the South Sandwich Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f8.svg",
  },
  {
    id: "SS",
    name: "South Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f8.svg",
  },
  {
    id: "ES",
    name: "Spain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f8.svg",
  },
  {
    id: "LK",
    name: "Sri Lanka",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f0.svg",
  },
  {
    id: "SD",
    name: "Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e9.svg",
  },
  {
    id: "SR",
    name: "Suriname",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f7.svg",
  },
  {
    id: "SJ",
    name: "Svalbard and Jan Mayen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ef.svg",
  },
  {
    id: "SZ",
    name: "Swaziland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ff.svg",
  },
  {
    id: "SE",
    name: "Sweden",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ea.svg",
  },
  {
    id: "CH",
    name: "Switzerland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ed.svg",
  },
  {
    id: "SY",
    name: "Syrian Arab Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fe.svg",
  },
  {
    id: "TW",
    name: "Taiwan, Province of China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fc.svg",
  },
  {
    id: "TJ",
    name: "Tajikistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ef.svg",
  },
  {
    id: "TZ",
    name: "Tanzania, United Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ff.svg",
  },
  {
    id: "TH",
    name: "Thailand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ed.svg",
  },
  {
    id: "TL",
    name: "Timor-Leste",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f1.svg",
  },
  {
    id: "TG",
    name: "Togo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ec.svg",
  },
  {
    id: "TK",
    name: "Tokelau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f0.svg",
  },
  {
    id: "TO",
    name: "Tonga",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f4.svg",
  },
  {
    id: "TT",
    name: "Trinidad and Tobago",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f9.svg",
  },
  {
    id: "TN",
    name: "Tunisia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f3.svg",
  },
  {
    id: "TR",
    name: "Turkey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f7.svg",
  },
  {
    id: "TM",
    name: "Turkmenistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f2.svg",
  },
  {
    id: "TC",
    name: "Turks and Caicos Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e8.svg",
  },
  {
    id: "TV",
    name: "Tuvalu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fb.svg",
  },
  {
    id: "UG",
    name: "Uganda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ec.svg",
  },
  {
    id: "UA",
    name: "Ukraine",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg",
  },
  {
    id: "AE",
    name: "United Arab Emirates",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ea.svg",
  },
  {
    id: "GB",
    name: "United Kingdom",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e7.svg",
  },
  {
    id: "US",
    name: "United States",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg",
  },
  {
    id: "UM",
    name: "United States Minor Outlying Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f2.svg",
  },
  {
    id: "UY",
    name: "Uruguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1fe.svg",
  },
  {
    id: "UZ",
    name: "Uzbekistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ff.svg",
  },
  {
    id: "VU",
    name: "Vanuatu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1fa.svg",
  },
  {
    id: "VE",
    name: "Venezuela, Bolivarian Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ea.svg",
  },
  {
    id: "VN",
    name: "Viet Nam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1f3.svg",
  },
  {
    id: "VG",
    name: "Virgin Islands, British",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ec.svg",
  },
  {
    id: "VI",
    name: "Virgin Islands, U.S.",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ee.svg",
  },
  {
    id: "WF",
    name: "Wallis and Futuna",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1eb.svg",
  },
  {
    id: "EH",
    name: "Western Sahara",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ed.svg",
  },
  {
    id: "YE",
    name: "Yemen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1ea.svg",
  },
  {
    id: "ZM",
    name: "Zambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1f2.svg",
  },
  {
    id: "ZW",
    name: "Zimbabwe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1fc.svg",
  },
];
