import React, { Component } from "react";

class CommandList extends Component<any, any> {
  private listRef: React.RefObject<HTMLDivElement>;
  private itemRefs: Array<React.RefObject<HTMLButtonElement>>;

  constructor(props: any) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
    this.listRef = React.createRef();
    this.itemRefs = this.props.items.map(() => React.createRef());
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.items !== prevProps.items) {
      this.setState({ selectedIndex: 0 });
    }
    if (this.state.selectedIndex !== prevState.selectedIndex) {
      this.scrollToSelectedItem();
    }
  }

  scrollToSelectedItem() {
    if (this.listRef.current && this.itemRefs && this.itemRefs[this.state.selectedIndex]?.current) {
      this.itemRefs[this.state.selectedIndex]?.current!.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }

  onKeyDown({ event }: { event: any }) {
    if (event.key === "ArrowUp") {
      this.upHandler();
      return true;
    }

    if (event.key === "ArrowDown") {
      this.downHandler();
      return true;
    }

    if (event.key === "Enter") {
      this.enterHandler();
      return true;
    }

    return false;
  }

  upHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + this.props.items.length - 1) % this.props.items.length,
    });
  }

  downHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length,
    });
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex);
  }

  selectItem(index: any) {
    const item = this.props.items[index];

    if (item) {
      this.props.command(item);
    }
  }

  render() {
    const { items } = this.props;
    if (!items.length) {
      return null;
    }
    return (
      <div className="dropdown-menu" ref={this.listRef}>
        {items.map((item: any, index: any) => {
          return (
            <button
              className={`flex flex-row items-center text-sm ${
                index === this.state.selectedIndex ? "is-selected" : ""
              }`}
              key={index}
              onClick={() => this.selectItem(index)}
              ref={this.itemRefs[index]}
            >
              <img src={item.logo} alt="logo" className="w-6 h-6 mr-2" />
              <p className="flex flex-col justify-center">
                {item.element || item.title}
                {item.parent && <span className="text-gray-400 text-xs mr-2">{item.parent}</span>}
              </p>
            </button>
          );
        })}
      </div>
    );
  }
}

export default CommandList;
