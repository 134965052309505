import LeftArrow from "@SVGs/TableTab/ReviewModal/LeftArrow";
import RightArrow from "@SVGs/TableTab/ReviewModal/RightArrow";
import React, { useEffect, useState } from "react";
import Formatter from "./Formatter";
import { getIndex } from "@/utils/functions";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { CELL_STATUS_DISPLAY, CELL_STATUS_SYMBOL, CELL_STATUS_TYPE } from "@/utils/constants";
import LoaderButton from "@/Components/LoaderButton";
import { FaRegSave } from "react-icons/fa";
import { useParams } from "react-router-dom";

interface Props {
  data: any;
  contentKey: string;
  currIdx: number;
  setCurridx: React.Dispatch<React.SetStateAction<number>>;
  maxIdx: number;
  section: number;
}

const TextSection = ({ data, contentKey, currIdx, setCurridx, maxIdx, section }: Props) => {
  const { markActionAsReviewedAndMoveOn, updateGeneratedEmail, retryAction, dataRef } = useWorkflow();
  const { extra, status, ...payload } = data;
  const [fetching, setFetching] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [content, setContent] = React.useState<string>(payload?.[contentKey]?.value || "");
  const { id } = useParams();

  useEffect(() => {
    const newData = dataRef.current.find((datas) => datas.key === extra?.dataId)?.[extra?.actionId];
    if (!newData) return;
    setContent(newData?.[contentKey]?.value || "");
  }, [dataRef.current.find((datas) => datas.key === extra?.dataId), data]);

  return (
    <section className="flex flex-[0.78] flex-col gap-4 items-start">
      <section className="min-w-full flex flex-wrap justify-between items-center">
        <div className="flex items-center gap-4">
          <button
            className={`p-2 flex justify-center items-center border border-gray-300 rounded-lg ${
              currIdx === 0 ? "bg-gray-100" : ""
            } `}
            disabled={currIdx === 0}
            onClick={() => setCurridx((prev) => prev - 1)}
          >
            <LeftArrow fill={currIdx === 0 ? "#6c727f" : ""} />
          </button>
          <span className="w-12 text-center">{getIndex(currIdx + 1)}</span>
          <button
            className={`p-2 flex justify-center items-center border border-gray-300 rounded-lg ${
              currIdx === maxIdx ? "bg-gray-100" : ""
            } `}
            disabled={currIdx === maxIdx}
            onClick={() => setCurridx((prev) => prev + 1)}
          >
            <RightArrow />
          </button>
        </div>
        <section className="flex items-center justify-center gap-2 flex-wrap">
          {extra.reviewNeeded && status === CELL_STATUS_TYPE.READY_TO_BE_REVIEWED && (
            <button
              className="flex justify-center items-center px-2 py-1 rounded-lg bg-gray-100 border border-gray-500 text-gray-600 font-semibold gap-1
                hover:bg-gray-200 transition
              "
              disabled={content === (payload[contentKey]?.value || "") || saving || regenerating}
              onClick={() => {
                setSaving(true);
                updateGeneratedEmail(
                  extra.workflowId,
                  extra.dataId,
                  extra.actionId,
                  contentKey,
                  content,
                  section
                ).finally(() => setSaving(false));
              }}
            >
              <FaRegSave />
              <span>{saving ? "Saving.." : "Save"}</span>
            </button>
          )}
          <div
            className={`font-semibold px-2 py-1 rounded-lg bg-gray-100 cursor-default shadow-sm shadow-gray-200 
              ${status === CELL_STATUS_TYPE.COMPLETED ? "text-green-500 bg-green-100" : "text-black bg-gray-100"}
            `}
          >
            <span>
              {status === CELL_STATUS_TYPE.COMPLETED ? "Completed" : CELL_STATUS_DISPLAY[status || "pending"]}
            </span>
          </div>
        </section>
      </section>
      {payload && [CELL_STATUS_TYPE.READY_TO_BE_REVIEWED, CELL_STATUS_TYPE.COMPLETED].includes(status) ? (
        <Formatter
          content={content}
          setContent={setContent}
          cellStatus={status}
          disabled={status === CELL_STATUS_TYPE.COMPLETED || saving || regenerating}
        />
      ) : (
        <span className="flex flex-wrap gap-2 items-center justify-center text-3xl text-gray-400 font-semibold">
          <span>{CELL_STATUS_SYMBOL[status || "pending"]}</span>
          <span>{CELL_STATUS_DISPLAY[status || "pending"]}</span>
        </span>
      )}
      {payload && extra.reviewNeeded && status === CELL_STATUS_TYPE.READY_TO_BE_REVIEWED && (
        <section className="w-full flex flex-col items-start justify-start gap-4">
          <section className="w-full flex items-center gap-2 flex-wrap">
            <button
              className="px-2 py-1 flex justify-center items-center gap-2 text-primary font-semibold border-2 border-primary/80 hover:bg-primary/10 transition rounded-lg"
              disabled={saving || regenerating}
              onClick={(e) => {
                e.preventDefault();
                setRegenerating(true);
                retryAction(extra?.actionId, id || "", [extra?.dataId], [extra?.dataId], section, "single")
                  .then((generatedEmail) => {
                    setContent(generatedEmail);
                    // setRegen(true);
                  })
                  .catch((err) => console.error(err))
                  .finally(() => {
                    setRegenerating(false);
                  });
              }}
            >
              <span>{regenerating ? "Regenerating..." : "Regenerate"}</span>
            </button>
            {/* {regen && (
                <span className="flex items-center justify-center gap-0.5 text-xs font-semibold italic">
                  <Tick size="20px" />
                  <span>Regenerated...</span>
                </span>
              )} */}
          </section>
          <LoaderButton
            text={
              // status === CELL_STATUS_TYPE.COMPLETED ? (
              //   <span className="flex justify-center items-center gap-2">
              //     <span className="text-[20px]">&#9989;</span>
              //     <span>Move to next step</span>
              //   </span>
              // ) : (
              "Review and move to next step"
              // )
            }
            disabled={fetching || saving || regenerating}
            error=""
            loaderText="Hold on.."
            onClickCallback={async () => {
              if (status !== CELL_STATUS_TYPE.COMPLETED) {
                setFetching(true);
                await markActionAsReviewedAndMoveOn(
                  extra.actionId,
                  extra.workflowId,
                  [extra.dataId],
                  [extra.dataId],
                  section,
                  "singleCell"
                );
                setFetching(false);
              }
            }}
            btnClasses="!px-3 !py-2"
            txtClasses="!text-sm"
          />
        </section>
      )}
    </section>
  );
};

export default TextSection;
