import { useEffect } from "react";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { areObjectsEqual, formatNumberWithComma } from "../utils";

const formSchema = z.object({
  field: z.string(),
});

export function SearchFilterValuePreviewArrayString({ value }: { value: string[] }) {
  const length = value ? value.length : 0;
  const are_urls = value.some((v) => v.includes("https://") || v.includes("http://") || v.includes("linkedin.com"));
  if (are_urls) {
    return (
      <p className="text-sm text-ellipsis hyphens-auto text-start max-w-80 text-nowrap overflow-hidden">
        {formatNumberWithComma(length)} urls
      </p>
    );
  }
  return (
    <p className="text-sm text-ellipsis hyphens-auto text-start max-w-80 text-nowrap overflow-hidden">{`${
      value ? value[0] : ""
    }${length > 1 ? ` + ${length - 1}` : ""}`}</p>
  );
}

export function SearchFilterFormArrayString({
  value,
  onChangeValue,
  onInteractOutside,
  closePopover,
}: {
  id: string;
  value: string[];
  onChangeValue: (_value: string[] | undefined) => void;
  onInteractOutside: boolean;
  closePopover: () => void;
}) {
  useEffect(() => {
    if (onInteractOutside === true) {
      onSubmit(form.getValues());
    }
  }, [onInteractOutside]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      field: value ? value.join("\n") : "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const newValue = values.field
      .split("\n")
      .filter((v) => v !== "" && v !== " " && v !== "\n" && v !== "\r")
      .map((v) => v.trim());
    if (areObjectsEqual(newValue, value)) {
      closePopover();
      return;
    }
    onChangeValue(newValue.length > 0 ? newValue : undefined);
  }

  return (
    <>
      <Form {...form}>
        <form className="grid max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="field"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <textarea
                    autoFocus={true}
                    className="flex min-h-[80px] w-full rounded-md border border-input bg-popover px-3 py-2 text-sm ring-offset-popover placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    placeholder="keyword1&#10;keyword2&#10;keyword3"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <button className="btn py-2" type="submit">
            Save
          </button>
        </form>
      </Form>
    </>
  );
}
