import { Handle, NodeProps, Position, useHandleConnections, useReactFlow } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import DeleteAction from "@/assets/SVGs/DeleteAction";

export function ActionNode({ data, id }: NodeProps) {
  const connections = useHandleConnections({
    type: "target",
  });
  const { deleteElements } = useReactFlow();
  return (
    <div className="nodrag w-[40vw]">
      {data.type !== "input" && <Handle type="target" position={Position.Top} isConnectable={connections.length < 1} />}
      {/* deleteElements */}
      <div className="relative group p-1 w-full">
        {data.type !== "input" && (
          <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-10 group-hover:opacity-100">
            <button
              onClick={async (e) => {
                e.stopPropagation();
                await deleteElements({
                  nodes: [
                    {
                      id: id,
                    },
                  ],
                });
              }}
              className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
            >
              <DeleteAction />
            </button>
          </div>
        )}
        <div
          className={`flex justify-between items-start sm:items-center transition-opacity duration-300 w-full py-3 px-4 md:p-6 border rounded-2xl cursor-pointer relative z-10 ${
            data.error ? "border-red-500 bg-red-100" : "border-gray-400 bg-white  hover:border-primary hover:bg-gray-50"
          }`}
        >
          <div className="flex items-center justify-center gap-4 w-full">
            <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0 ">
              {(data.logo as string).includes("https") ? (
                <img src={data.logo as string} alt="icon" />
              ) : (
                <DefaultAction />
              )}
            </div>

            <div className="flex w-fit items-center justify-between overflow-hidden">
              <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                {data.name as string}
              </label>
            </div>
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={handleStyle}
      /> */}
    </div>
  );
}
