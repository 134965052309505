import { useEffect, useState } from "react";
import LoaderSVG from "../assets/SVGs/LoaderSVG";
import { getLoaderMsg } from "../utils/functions";
import { LOADER_TYPES } from "../utils/constants";

type LoaderProps = {
  payload: string;
  loaderType: LOADER_TYPES;
};

const Loader = ({ payload, loaderType }: LoaderProps) => {
  const [loaderState, setLoaderState] = useState<number>(0);
  const loaderText = getLoaderMsg(payload, loaderType);
  useEffect(() => {
    const tot = setTimeout(() => {
      setLoaderState(1);
      clearTimeout(tot);
      setTimeout(() => {
        setLoaderState(2);
      }, 4000);
    }, 3000);
    return () => {
      clearTimeout(tot);
    };
  }, []);

  return (
    <div className="w-full h-full items-center justify-center bg-opacity-80 z-10 flex flex-col gap-2 text-gray-400 font-semibold text-2xl overflow-auto">
      <section className="relative w-full items-center flex justify-center text-center">
        {/* <img src={LoaderSVG} alt="loader" /> */}
        <LoaderSVG />
        {payload?.length > 0 && loaderState > 0 && <span className="absolute bottom-[-3rem] ">{`${loaderText}`}</span>}
        {payload?.length > 0 && loaderState > 1 && <span className="absolute bottom-[-5rem]">Almost there.</span>}
      </section>
    </div>
  );
};

export default Loader;
