import { useBuilder } from "@/contexts/BuilderContext";
import { setStateType } from "@/utils/constants";
import { Action, PayloadConfiguration, ResponseConfiguration } from "@/utils/interfaces";
import { Drawer, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: ResponseConfiguration[];
  variables: any[];
};
const AddSection = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [sectionList, setSectionList] = useState<any[]>([]);
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const { updateResponseConfig, saveDraftWorkflow, workflow, updatePayloadConfig } = useBuilder();
  const { id } = useParams();

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setResponses(responseConfiguration);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (
      !payloads ||
      !variables ||
      payloads.find((payload) => payload.payloadStructureId === "sectionListId")?.inputString === ""
    )
      return;
    const listId = payloads
      .find((payload) => payload.payloadStructureId === "sectionListId")
      ?.inputString.split("{{")[1]
      ?.split("}}")[0];

    const list: any[] = [];
    variables.forEach((action) => {
      action.variables.forEach((variable: any) => {
        if (variable.responseId === listId) {
          list.push(
            ...variable.sectionVariablesStructure.map((section: any) => {
              const config = variable.sectionVariablesConfiguration.find(
                (config: any) => config.responseStructureId === section.responseStructureId
              );
              return {
                ...section,
                ...config,
              };
            })
          );
        }
      });
    });

    setSectionList(list);
  }, [payloads, variables]);

  const saveOutputStructure = async () => {
    setLoading(true);
    updateResponseConfig(responses, action.id);
    updatePayloadConfig(payloads, action.id, false);
    await saveDraftWorkflow(id || "", workflow);
    setLoading(false);
  };

  const handleSelectList = (value: any) => {
    const temp = [...payloads];
    const index = temp.findIndex((payload) => payload.payloadStructureId === "sectionListId");
    if (index === -1) return;
    temp[index].inputString = `{{${value}}}`;
    setPayloads(temp);
  };

  return (
    <Drawer
      open={modal}
      onClose={() => setModal(false)}
      width="30%"
      title={
        <div className="flex items-center gap-2">
          <img src={action.logo} alt={action.name} className="w-6 h-6" />
          {action.name}
        </div>
      }
      footer={
        <button
          className={`w-full text-white rounded-lg p-2 mt-auto ${
            loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
          }`}
          onClick={saveOutputStructure}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      }
    >
      <div className="h-full bg-white gap-10 flex flex-col">
        {action.payloadStructure.map((field, idx) => {
          return (
            <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
              <div>
                <p className="tracking-primary font-semibold">{field.name.toUpperCase()}</p>
                <p className="text-sm font-light">{field.description}</p>
              </div>
              <Select
                placeholder={`Select ${field.name}`}
                defaultValue={
                  payloads
                    .find((payload) => payload.payloadStructureId === "sectionListId")
                    ?.inputString.split("{{")[1]
                    ?.split("}}")[0]
                }
                onChange={handleSelectList}
                options={variables
                  .filter((action) => action.variables.some((variable: any) => variable.type === field.type))
                  .map((variable) => {
                    return {
                      label: (
                        <div className="flex gap-2">
                          <img src={variable.logo} alt={variable.name} className="w-4 h-4" />
                          <span>{variable.name}</span>
                        </div>
                      ),
                      title: variable.name,
                      options: variable.variables.map((v: any, idx: number) => {
                        return {
                          label: (
                            <div
                              key={idx}
                              className={`px-2 flex items-center gap-2 rounded-lg
                                ${
                                  variable.type === "input"
                                    ? "bg-[#FFF3D4] text-[#AA7102]"
                                    : "bg-[#E3EDFF] text-[#0047C6]"
                                }`}
                            >
                              <img
                                src={variable.type === "input" ? YellowVarLogo : BlueVarLogo}
                                alt={v.name}
                                className="w-4 h-4"
                              />
                              {v.name}
                            </div>
                          ),
                          value: v.responseId,
                        };
                      }),
                    };
                  })}
              />
            </div>
          );
        })}
        {sectionList.length > 0 && (
          <div className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
            <div>
              <p className="tracking-primary font-semibold">{"SELECT VARIABLES"}</p>
            </div>
            <Select
              placeholder={`Select variables`}
              mode="multiple"
              defaultValue={
                responses.length > 0
                  ? responses.reduce((acc: any[], curr) => {
                      return [...acc, curr?.responseStructureId];
                    }, [])
                  : []
              }
              onChange={(value: any) => {
                setResponses(
                  value.map((res: any) => {
                    return sectionList.find((variable: any) => variable.responseStructureId === res);
                  })
                );
              }}
              options={sectionList.map((variable: any) => ({
                label: variable.name,
                value: variable.responseStructureId,
              }))}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AddSection;
