import ConnectionsIndex from "../Components/Connections";
import Navbar from "../Components/Navbar";
import { useUser } from "../contexts/UserContext";

const Connections = () => {
  const { collapsed } = useUser();

  return (
    <div className="h-screen flex flex-row justify-start">
      <div
        className={`fixed h-screen ${collapsed ? "w-[5%]" : "w-[20%]"} transition-all duration-500`}
        style={{
          transitionProperty: "all",
        }}
      >
        <Navbar />
      </div>
      <div
        className={`relative ${
          collapsed ? "w-[95%] left-[5%]" : "w-[80%] left-[20%]"
        } transition-all duration-500 bg-[#FBF8FF] p-10 overflow-auto`}
        style={{
          transitionProperty: "all",
        }}
      >
        <ConnectionsIndex />
      </div>
    </div>
  );
};

export default Connections;
