interface Props {
  width?: string;
  height?: string;
}

const Expand = ({ width, height }: Props) => {
  return (
    <svg
      width={width || "12"}
      height={height || "23"}
      viewBox="0 0 12 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      transform="matrix(-1,0,0,1,0,0)"
    >
      <path
        d="M1 1.17499L11 11.468L1 21.7611"
        stroke="#242424"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default Expand;
