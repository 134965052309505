import { setStateType } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import ArrayField from "./ArrayField";
import { FaPlus } from "react-icons/fa";
import TiptapField from "../Tiptap/TiptapField";
import { useBuilder } from "@/contexts/BuilderContext";
import { useParams } from "react-router-dom";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const SendingModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const { updatePayloadConfig, saveDraftWorkflow, workflow } = useBuilder();
  const { id } = useParams();

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    await saveDraftWorkflow(id || "", workflow);
    setChanged(false);
    setLoading(false);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "array":
        return (
          <ArrayField
            arr={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString.length > 0
                ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                : [""]
            }
            setContent={(idx: number, content: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: [content],
                  type: field.type,
                });
              } else {
                temp[index].inputString[idx] = content;
              }
              setPayloads(temp);
            }}
            add={() => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString.push("");
              setPayloads(temp);
            }}
            description={field.description}
          />
        );
      case "dropdown":
        return (
          <Skeleton loading={false}>
            <Select
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              // options={campaigns.map((campaign) => ({
              //   label: campaign.name,
              //   value: campaign.id,
              // }))}
              options={[]}
            />
          </Skeleton>
        );
      case "radio":
        return (
          <Switch
            className="w-fit"
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="" key={idx}>
                    <input
                      type="text"
                      className="w-full rounded-lg"
                      placeholder="Enter the name"
                      value={input.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString[idx].name = e.target.value;
                        setPayloads(temp);
                      }}
                    />
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                          idx
                        ].tiptapJson || ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString[idx].value = text;
                        temp[index].inputString[idx].tiptapJson = content;
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder="Enter the value"
                      variables={variables}
                    />
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "Variable Name",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !changed ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || !changed}
            >
              {loading ? "Saving..." : !changed ? "Saved" : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            {action.name}
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {field.needVars ? (
                  <TiptapField
                    content={
                      payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
                    }
                    setContent={(content: any, text: string) => {
                      const temp = [...payloads];
                      const index = payloads.findIndex(
                        (input) => input.payloadStructureId === field.payloadStructureId
                      );
                      if (index === -1) {
                        temp.push({
                          payloadStructureId: field.payloadStructureId,
                          tiptapJson: content,
                          inputString: text,
                          type: field.type,
                        });
                      } else {
                        temp[index].tiptapJson = content;
                        temp[index].inputString = text;
                      }
                      setPayloads(temp);
                    }}
                    refresh={updateField}
                    placeholder={field.name}
                    variables={variables}
                  />
                ) : (
                  fieldSwitchCase(field)
                )}
              </div>
            );
          })}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default SendingModal;
