import "./instrument.js";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./Components/UserWorkflows/inputStyle.css";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./contexts/UserContext.tsx";
import WorkflowContextProvider from "./contexts/WorkflowContext.tsx";
import { ConfigProvider } from "antd";
import ConnectionContextProvider from "./contexts/ConnectionContext.tsx";
import BuilderContextProvider from "./contexts/BuilderContext.tsx";
import { ChatProvider } from "./contexts/ChatContext.tsx";
import { Theme } from "@radix-ui/themes";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <UserContextProvider>
      <WorkflowContextProvider>
        <BuilderContextProvider>
          <ConnectionContextProvider>
            <ChatProvider>
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Inter",
                    colorBorder: "#000",
                    borderRadius: 3,
                    colorPrimary: "#5750FF",
                  },
                  components: {
                    Segmented: {
                      itemSelectedBg: "#5750FF",
                      itemSelectedColor: "#fff",
                    },
                    Input: {
                      activeBorderColor: "#5750FF",
                    },
                    Select: {
                      // optionActiveBg: "#5750FF",
                    },
                  },
                }}
              >
                <Theme accentColor="indigo">
                  <App />
                </Theme>
              </ConfigProvider>
            </ChatProvider>
          </ConnectionContextProvider>
        </BuilderContextProvider>
      </WorkflowContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);
