import { FaPlus } from "react-icons/fa";

type Props = {
  arr: string[];
  setContent: (idx: number, content: string) => void;
  add: () => void;
  description: string;
  small?: boolean;
};

const ArrayField = ({ arr, setContent, description, add, small }: Props) => {
  return (
    <div className="flex flex-col gap-6">
      {arr.map((item: any, idx2: number) => (
        <textarea
          key={idx2}
          className="w-full rounded-lg"
          placeholder={description}
          value={item}
          onChange={(e) => setContent(idx2, e.target.value)}
          rows={small ? 1 : 4}
        />
      ))}
      <button
        className="border border-black w-fit flex font-semibold items-center text-black gap-2 rounded-lg p-2"
        onClick={add}
        disabled={arr.length === 0 || arr[arr.length - 1] === ""}
      >
        <FaPlus />
        Add
      </button>
    </div>
  );
};

export default ArrayField;
