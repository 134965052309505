import { Collapse, Tooltip } from "antd";
import { useCallback, useState } from "react";
import KeyValue from "./KeyValue";
import { FaRegCopy } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

interface Props {
  itr: any;
  idx: number;
}

const CollapseItemWrapper = ({ itr, idx }: Props) => {
  const [copied, setCopied] = useState(false);
  const [content, setContent] = useState("fetching...");

  const copyContent = useCallback(
    (e: any) => {
      // e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(content).then(() => {
        setCopied(true);
      });
    },
    [content]
  );

  return (
    <Collapse
      bordered={false}
      className="flex flex-col gap-4 bg-white"
      expandIconPosition="end"
      expandIcon={({ isActive }) => (isActive ? <IoIosArrowDown /> : <IoIosArrowForward />)}
    >
      <Collapse.Panel
        key={idx}
        header={
          <span className="group !w-full flex items-center justify-between">
            <span className="text-lg font-semibold">{(itr as any).name || ""}</span>
            <Tooltip
              placement="bottomRight"
              title={copied ? "Copied!" : "Copy to clipboard"}
              arrow={false}
              overlayClassName="border border-gray-200 rounded-md"
              overlayInnerStyle={{
                fontWeight: 600,
                backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
                color: `${copied ? "#f3f4f6" : "#6c727f"}`,
                fontSize: "0.75rem",
              }}
            >
              <button
                className="opacity-0 group-hover:opacity-100 transition flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                onClick={copyContent}
              >
                <FaRegCopy className="text-gray-600" />
              </button>
            </Tooltip>
          </span>
        }
        className="mb-4 !border-0 !rounded-md bg-gray-50"
      >
        <KeyValue url={(itr as any).value || ""} content={content} setContent={setContent} />
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseItemWrapper;
