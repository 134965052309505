import LoaderButton from "@/Components/LoaderButton";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import { useBuilder } from "@/contexts/BuilderContext";
import { setStateType } from "@/utils/constants";
import { ConfigProvider, Drawer } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const getInputStringFromType = (field: string) => {
  switch (field) {
    case "string":
      return "";
    case "number":
      return 0;
    case "boolean":
      return false;
    case "array":
      return [""];
    case "jsonArray":
      return [];
    case "webhookArray":
      return [];
    case "jsonArrayWithDropDown":
      return [];
    case "formula":
      return {};
    default:
      return "";
  }
};

type Props = {
  modal: boolean;
  setModal: setStateType<boolean>;
  actions: any[];
  currActionId: string;
};

const AddActionModal = ({ modal, setModal, actions, currActionId }: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState<any>("");
  const { setWorkflow, workflow, saveDraftWorkflow } = useBuilder();
  const { id } = useParams();

  useEffect(() => {
    setSelectedAction("");
  }, [modal]);

  const handleActionSelect = (actionId: string) => {
    if (selectedAction === actionId) {
      setSelectedAction("");
    } else {
      setSelectedAction(actionId);
    }
  };

  const handleAdd = async () => {
    setLoading(true);
    const action = actions.find((act) => act.id === selectedAction);
    if (!action) return;
    const actId = uuidv4().toString();
    const payloadConfig = action.payloadStructure.map((field: any) => {
      const config: any = {
        payloadStructureId: field.payloadStructureId,
        inputString: getInputStringFromType(field.type),
      };
      if (field.type === "stepDownSearch") {
        config["stepDownSearchConfig"] = field.stepDownAPIs.map((api: any, idx: number) => ({
          apiId: api.apiId,
          order: idx + 1,
        }));
      }
      return config;
    });
    const currAction = workflow.actions[currActionId];
    const newAction: any = {
      delayAfterActionPerProspectPerSecond: 0,
      actionName: action.id,
      nextAction: currAction.nextAction,
      payloadConfiguration: payloadConfig,
    };
    if (action.outputStructureType === "predefined") {
      newAction["responseConfiguration"] = action.responseStructure.map((field: any) => {
        const x: any = {
          responseStructureId: field.responseStructureId,
          responseId: uuidv4().toString(),
        };
        if (field.type === "sectionList")
          x["sectionVariablesConfiguration"] = field.sectionVariablesStructure.map((section: any) => ({
            responseId: uuidv4().toString(),
            responseStructureId: section.responseStructureId,
          }));
        return x;
      });
    }

    const newDraft = {
      ...workflow,
      actions: {
        ...workflow.actions,
        [actId]: newAction,
        [currActionId]: {
          ...currAction,
          nextAction: [actId],
        },
      },
    };

    await saveDraftWorkflow(id || "", newDraft);

    setWorkflow(newDraft);
    setLoading(false);
    setModal(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <LoaderButton
              disabled={loading || selectedAction === ""}
              onClickCallback={handleAdd}
              //   loaderType="Saving"
              loaderText="Adding..."
              text="Add"
              btnClasses="!p-2"
              error=""
            />
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            {/* <img src={action.logo} alt={action.name} className="w-6 h-6" /> */}
            Select the Action you want to add to the workflow
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          {actions.map((action: any, idx: number) => (
            <div
              key={idx}
              className={`flex bg-white justify-between items-start sm:items-center transition-all duration-200 w-full max-w-full py-3 px-4 md:p-6 border border-grey-400 rounded-2xl cursor-pointer relative z-10 hover:border-primary hover:bg-gray-50 ${
                selectedAction === action.id ? "border-primary bg-primary/15" : ""
              }`}
              onClick={() => handleActionSelect(action.id)}
            >
              <div className="flex items-center justify-center gap-4 w-full">
                <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
                  {action.logo.includes("https") ? <img src={action.logo} alt="icon" /> : <DefaultAction />}
                </div>
                <div className="flex w-fit items-center justify-between overflow-hidden">
                  <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                    {action.name}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default AddActionModal;
