import { App, Drawer, message } from "antd";
import { useEffect, useState } from "react";
import { FaCoins, FaRegCopy } from "react-icons/fa";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: number | { [key: string]: number };
};

const CrunchBaseFundraisingViewer = ({ open, close, data, title, credits }: Props) => {
  const [newData, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const copied = [
        {
          name: "Funding Milestone Investors",
          value: !content[0]?.value ? "No data found" : content[0]?.value,
        },
        {
          name: "Funding Round",
          value: !content[1]?.value ? "No data found" : content[1]?.value,
        },
        {
          name: "Funding Amount USD",
          value: !content[2]?.value ? "No data found" : content[2]?.value,
        },
        {
          name: "Funding Date",
          value: !content[3]?.value ? "No data found" : content[3]?.value,
        },
      ];
      const jsonString = JSON.stringify(copied, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="30%">
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="text-[20px] font-bold">Retrieved list of funding rounds</div>
          <hr />

          <div className="space-y-2">
            {newData &&
              newData["List of funding rounds"] &&
              Object?.values(newData["List of funding rounds"])?.map((series: any, idx: number) => (
                <div key={idx}>
                  <div
                    key={idx}
                    className={`${
                      activeIndices.includes(1001 + idx + 1)
                        ? ""
                        : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
                    }  cursor-pointer mt-2 mb-2`}
                  >
                    <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001 + idx + 1)}>
                      <p className="text-[16px] font-bold flex items-center gap-x-2">
                        Funding Rounds {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                        {activeIndices.includes(1001 + idx + 1) ? (
                          <UpArrowNew color="black" />
                        ) : (
                          <DownArrowNew color="black" />
                        )}
                      </p>
                      <p
                        className="cursor-pointer flex items-center gap-x-2"
                        onClick={(e) => handleCopyJSON(series, e)}
                      >
                        Copy All <FaRegCopy className="text-gray-600" />
                      </p>
                    </div>
                    {activeIndices.includes(1001 + idx + 1) && (
                      <div className="">
                        <div className="space-y-2 mt-2 border p-2 rounded-sm">
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Funding Milestone Investors</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!series[0]?.value || series[0]?.value === "No data found" ? "\u00A0" : series[0]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(series[0]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Funding Round</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!series[1]?.value || series[1]?.value === "No data found" ? "\u00A0" : series[1]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(series[1]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Funding Amount USD</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!series[2]?.value || series[2]?.value === "No data found" ? "\u00A0" : series[2]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(series[2]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Funding Date</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!series[3]?.value || series[3]?.value === "No data found" ? "\u00A0" : series[3]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(series[3]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className="" />
                </div>
              ))}
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{typeof credits === "number" ? credits : Object.values(credits)[0]}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </Drawer>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default CrunchBaseFundraisingViewer;
