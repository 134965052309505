import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useWorkflow } from "../../contexts/WorkflowContext";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  close: () => void;
};

const NameModal = ({ id, close }: Props) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { copyWorkflowToUser } = useWorkflow();
  const navigate = useNavigate();

  const handleCopy = async () => {
    setLoading(true);
    const newId = await copyWorkflowToUser(id, name);
    navigate(`/workflow/${newId}`);
    setLoading(false);
    close();
  };

  return (
    <div className="fixed inset-0 w-screen h-screen bg-black bg-opacity-40 flex flex-col items-center justify-center overflow-auto z-20">
      <dialog className="bg-white w-1/2 rounded-lg shadow-lg overflow-hidden p-8 flex flex-col items-center justify-center gap-2 relative z-[999]">
        <div className="z-10 h-full bg-white">
          <div className="flex w-full justify-between mb-5 gap-16">
            <p className="text-2xl font-bold">What would you like to call your workflow?</p>
            <IoMdClose
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1"
              size={30}
              onClick={() => {
                close();
              }}
            />
          </div>
          <div className="flex flex-col w-full gap-4">
            <div>
              <input
                className="w-full rounded-lg"
                type="text"
                placeholder="Name of your Workflow..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <button className="text-primary font-semibold hover:underline w-fit" onClick={() => setOpen(!open)}>
              Why do you need to name your workflow?
            </button>
            <div className={`font-normal text-sm ${open ? "visible" : "invisible"}`}>
              Each of your workflows might be working with a different lead list, according to the input you provide it
              in your input step, hence, we recommend naming your workflows in the same manner as you would name a list
              of leads, to make it easier for you to categorize which kind of data you are working with within each copy
              of the workflow.
            </div>
            <button
              className={`w-fit rounded-lg py-2 px-4 font-semibold text-white transition ${
                loading ? "bg-gray-500" : "bg-primary hover:bg-dark"
              }`}
              onClick={handleCopy}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save and create workflow"}
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default NameModal;
