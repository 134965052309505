import "./spinner.css";

interface Props {
  white?: boolean;
}

const SpinnerStatus = ({ white }: Props) => {
  return (
    <div className={`spinner`}>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
      <div className={`spinner-blade ${white ? "spinner_blade_white" : "spinner_blade_gray"}`}></div>
    </div>
  );
};

export default SpinnerStatus;
