import { useEffect, useState } from "react";
import { useWorkflow } from "../../contexts/WorkflowContext";
import Loader from "../Loader";
import { LOADER_TYPES } from "../../utils/constants";
import WorkflowCard from "./WorkflowCard";
import UpcomingCard from "./UpcomingCard";

const WorkflowLibs = () => {
  const [workflows, setWorkflows] = useState<any[]>([]);
  const upcoming = [
    {
      name: "Sort employees by location in a table",
      description:
        "Import a list of company websites using a CSV, and this flow will automatically search all the employees along with their geographical regions where employees are located, aggregating the sorted list in a Floq table format.",
      inputs: ["Company URL"],
      upcoming: true,
      view: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/upcoming_workflow_logos/floq1.svg",
      steps: [
        {
          type: "input",
          description: "Imported company websites from CSV",
          logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/upload-csv-logo.svg",
        },
        {
          type: "data_enrichment",
          description: "Searched employee base of company",
          logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/sales_nav.svg",
        },
        {
          type: "data_enrichment",
          description: "Imported company websites from CSV",
          logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/sales_nav.svg",
        },
        {
          type: "floqer",
          description: "Aggregated locations in table",
          logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer.svg",
        },
      ],
    },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const { getAllPredefinedWorkflows } = useWorkflow();

  useEffect(() => {
    const fetchWorkflows = async () => {
      setLoading(true);
      const workflows = await getAllPredefinedWorkflows();
      setWorkflows(workflows);
      setLoading(false);
    };
    fetchWorkflows();
  }, []);

  if (loading)
    return (
      <div className="h-full w-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow Libraries" />
      </div>
    );

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-10">
      <div className="w-full flex flex-row justify-between items-center px-4">
        <div className="text-4xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Workflow Library
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-10">
        {workflows.length > 0 ? (
          workflows.map((workflow) => <WorkflowCard workflow={workflow} key={workflow.id} />)
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <div className="text-2xl font-semibold text-gray-400">No workflows available</div>
          </div>
        )}
      </div>
      {upcoming.length > 0 && (
        <>
          <div className="w-full flex flex-row justify-between items-center px-4">
            <div className="text-4xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
              Upcoming Workflows
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-10">
            {upcoming.map((workflow, idx) => (
              <UpcomingCard workflow={workflow} key={idx} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WorkflowLibs;
