export const funding_stages = [
  { id: "Angel", name: "Angel" },
  { id: "Convertible Note", name: "Convertible Note" },
  { id: "Debt Financing", name: "Debt Financing" },
  { id: "Equity Crowdfunding", name: "Equity Crowdfunding" },
  { id: "Other", name: "Other" },
  { id: "Private Equity", name: "Private Equity" },
  { id: "Seed", name: "Seed" },
  { id: "Series A", name: "Series A" },
  { id: "Series B", name: "Series B" },
  { id: "Series C", name: "Series C" },
  { id: "Series D", name: "Series D" },
  { id: "Series E", name: "Series E" },
  { id: "Series F", name: "Series F" },
  { id: "Series G", name: "Series G" },
  { id: "Series H", name: "Series H" },
  { id: "Venture (Round not Specified)", name: "Venture (Round not Specified)" },
];
