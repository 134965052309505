import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import { useCallback, useEffect } from "react";
import { CustomTag } from "../CustomTag";
import Variables from "../Variables";
import StarterKit from "@tiptap/starter-kit";
import { ErrorTag } from "../ErrorTag";
import { keyDownArr1, keyDownArr2 } from "@/utils/constants";
import Commands from "./Suggestions/Commands";
import { getCommandSuggestions } from "./Suggestions/Items";
import renderItems from "./Suggestions/RenderItems";
import { cn } from "@/utils/cn";

type Props = {
  content: any;
  setContent: (content: any, text: string) => void;
  placeholder?: string;
  NotificationNode?: any;
  refresh: number;
  variables: any[];
  skipDataValues?: boolean;
  placeholderText?: string;
  centerPlaceholder?: boolean;
  applyStyles?: boolean;
  className?: string;
  customStyles?: boolean;
  explodeSection?: string;
};

const TiptapField = ({
  content,
  NotificationNode,
  setContent,
  placeholder,
  refresh,
  variables,
  skipDataValues = false,
  className,
  placeholderText = "Enter the value",
  centerPlaceholder = false,
  applyStyles = true,
  customStyles = false,
  explodeSection = "",
}: Props) => {
  const extensions = [
    StarterKit.configure({
      codeBlock: false,
      code: false,
    }),
    CustomTag.configure({
      HTMLAttributes: {
        span: {
          class: customStyles ? "text-[#f9a605] bg-primary/30" : "",
        },
      },
    }),
    ErrorTag,
    Placeholder.configure({
      placeholder: placeholder
        ? `Enter your "${placeholder.trim()}" here.\nTip: Use the '/' key to access variables from previous steps.`
        : placeholderText,
    }),
    Commands.configure({
      suggestion: {
        // @ts-expect-error - editor is not defined in the options
        items: ({ editor, query }) =>
          getCommandSuggestions({
            query,
            variables,
            explodeSection,
          }),
        render: renderItems,
      },
    }),
  ];
  const editor = useEditor(
    {
      extensions: extensions,
      editorProps: {
        attributes: {
          class: cn(
            "w-full min-h-[120px] p-4 bg-white border border-gray-300 rounded-lg",
            className,
            !centerPlaceholder ? "" : "flex items-center"
          ),
          style: applyStyles ? "border: 1px solid #e2e8f0; border-radius: 0.375rem;" : "",
        },
      },
      content: content,
      onUpdate: ({ editor }) => {
        setContent(editor.getJSON(), editor.getText());
      },
    },
    [refresh]
  );

  editor?.setOptions({
    editorProps: {
      handleKeyDown(_view, event) {
        if (event.code === "Tab") {
          event.preventDefault();
          editor?.commands.insertContent("\t");
        } else if (keyDownArr2.includes(event.code)) {
          event.stopPropagation();
        } else if (
          (event.code[0] === "D" || keyDownArr1.includes(event.code)) &&
          event.ctrlKey === false &&
          event.metaKey === false &&
          event.altKey === false
        ) {
          event.preventDefault();
          handleSpecialKeys(event);
        }
      },
    },
  });

  const handleSpecialKeys = useCallback(
    (event: any) => {
      const capsOn = event.getModifierState("CapsLock");
      const shiftPressed = event.getModifierState("Shift");
      if (shiftPressed) {
        event.stopPropagation();
      }
      if (event.code[0] === "D") {
        editor?.commands.insertContent(event.key);
      } else {
        let isCap = shiftPressed ? !capsOn : capsOn;
        if (isCap) editor?.commands.insertContent(event.code[3]);
        else editor?.commands.insertContent(event.key);
      }
    },
    [editor]
  );

  const getParentAction = (variableId: any) => {
    // variables.forEach((action) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return action.name;
        else if (explodeSection && explodeSection === variable.responseId) {
          const sectionVar = variable.sectionVariablesConfiguration?.find(
            (item: any) => item.responseId === variableId
          );
          if (sectionVar) return action.name;
        }
      }
    }
    return "";
  };

  const checkValidNode = (node: any) => {
    if (node.type === "customTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (!parentAction) node.type = "errorTag";
      else node.attrs.parent = parentAction;
    } else if (node.type === "errorTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (parentAction) {
        node.type = "customTag";
        node.attrs.parent = parentAction;
      }
    } else if (node.content) {
      node.content.forEach((node: any) => {
        checkValidNode(node);
      });
    }
  };

  useEffect(() => {
    const newContent = content;
    if (!editor) return;
    if (newContent !== "" && newContent.type === "doc" && variables.length > 0)
      newContent.content.forEach((node: any) => {
        checkValidNode(node);
      });
    editor?.commands.setContent(newContent);
  }, [editor, variables]);

  const addVariable = (variable: any, actionName: string) => {
    if (editor?.isFocused) {
      editor?.commands.insertContentAt(editor?.state.selection.$anchor.pos, {
        type: "customTag",
        attrs: {
          id: variable.responseId,
          parent: actionName,
          label: variable.name,
          logo: variable.logo,
        },
      });
    } else
      editor
        ?.chain()
        .focus()
        .insertContent({
          type: "customTag",
          attrs: {
            id: variable.responseId,
            parent: actionName,
            label: variable.name,
            logo: variable.logo,
          },
        })
        .run();
    editor?.commands.focus();
  };
  return (
    <div className="w-full flex flex-col gap-2">
      <EditorContent editor={editor} />
      {NotificationNode ? NotificationNode : null}
      {!skipDataValues && variables.length > 0 && <Variables variables={variables} addVariable={addVariable} />}
    </div>
  );
};

export default TiptapField;
