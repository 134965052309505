import React from "react";

interface Props {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}

const Formatter = ({ content, setContent, disabled }: Props) => {
  return (
    <textarea
      rows={9}
      className="w-full text-[#474747] !text-sm font-normal p-1 rounded-lg bg-white"
      value={content}
      onChange={(e) => setContent(e.target.value)}
      disabled={disabled}
      style={{
        resize: "none",
      }}
    />
  );
};

export default Formatter;
