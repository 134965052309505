import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useWorkflow } from "@/contexts/WorkflowContext";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";
import ButtonAddFilter from "./Components/AddFilterButton";
import { filters } from "./Components/filters";
import { IFiltersProps } from "./Components/filter.schema";
import "./Components/Filters.css";
import SearchFilterBase from "./Components/AppliedFilters";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { filtersToParams } from "./Components/utils";
import { generateTSPreview, importTSCompanies } from "@/utils/apis";

interface SalesNavigatorProps {
  workflowName: string;
  selectedSource: string;
  selectedFolder: string;
  stepsClose: React.Dispatch<React.SetStateAction<boolean>>;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  v2?: boolean;
}

const order_by = [
  {
    desc: true,
    field: "confidence",
  },
  {
    desc: true,
    field: "jobs",
  },
  {
    desc: true,
    field: "num_jobs",
  },
];

const CompaniesListComponent = ({
  workflowName,
  selectedSource,
  selectedFolder,
  stepsClose,
  close,
  v2,
}: SalesNavigatorProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { createNewWorkflow } = useWorkflow();
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([]);
  const [maxCount, setMaxCount] = useState<number>();
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<number>();
  const [error, setError] = useState("");

  useEffect(() => {
    const missingPermanentFilters = filters
      .filter((filter) => filter.is_permanent)
      .map((filter) => ({ ...filter, operator: filter.availableOperators[0] }));
    setAppliedFilters(missingPermanentFilters);
  }, []);

  const addFilters = (filters: IFiltersProps[]) => {
    const newFilters = [...appliedFilters, ...filters];
    setAppliedFilters(newFilters);
  };

  const onRemoveFilter = (index: number) => {
    const newFilters = [...appliedFilters];
    newFilters.splice(index, 1);
    setAppliedFilters(newFilters);
  };

  const onChangeFilter = (index: number, filter: IFiltersProps) => {
    const newFilters = [...appliedFilters];
    newFilters[index] = filter;
    newFilters[index].hasChanged = true;
    setAppliedFilters(newFilters);
  };

  const isFilterRemovable = (filter: IFiltersProps) => {
    const exist_job_filter = appliedFilters.some(
      (f) => f.id.startsWith("job_filters.") && f.id !== "job_filters.posted_at"
    );
    if (filter.id === "job_filters.posted_at" && exist_job_filter) {
      return false;
    }
    return true;
  };

  const isFilterPermanent = (filter: IFiltersProps, index: number, appliedFilters: IFiltersProps[]) => {
    if (filter.is_permanent) {
      const firstOcurrenceSameId = appliedFilters.findIndex((appliedFilter) => appliedFilter.id === filter.id);
      if (firstOcurrenceSameId == index) return true;
    }
    return false;
  };

  const generatePreview = async () => {
    setLoading(true);
    try {
      const params = filtersToParams(appliedFilters, order_by);
      const response = await generateTSPreview(params, v2 ?? false);
      setTotalItems(response?.metadata?.total_results || 0);
      setPreviewData(response.data || []);
      setPreviewGenerated(true);
      setAppliedFilters(appliedFilters.map((filter) => ({ ...filter, hasChanged: false })));
      setLoading(false);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  const isAtomic = (value: any) => {
    return typeof value !== "object" && value !== null;
  };

  const skipFields = ["has_blurred_data", "id", "industry_id", "annual_revenue_usd"];

  const columns = React.useMemo(() => {
    if (previewData.length === 0) return [];
    const keys = Object.keys(previewData[0]);
    return keys
      .filter((key) => !skipFields.includes(key) && isAtomic(previewData[0][key]))
      .map((key) => ({
        title: key,
        dataIndex: key,
        key,
        render: (text: any) => <span className="line-clamp-1 min-w-72">{text}</span>,
      }));
  }, [previewData]);

  const data = React.useMemo(() => {
    return previewData.map((item, index) => {
      const obj: any = {};
      Object.keys(item).forEach((key) => {
        if (isAtomic(item[key])) {
          obj[key] = item[key];
        }
      });
      return {
        key: index,
        ...obj,
      };
    });
  }, [previewData]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col overflow-hidden h-screen">
        <div className="flex flex-row w-full items-center justify-between border-b py-3 px-10">
          <button
            onClick={() => close(false)}
            className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
          >
            <MdOutlineKeyboardBackspace size={30} />
            <span>Back</span>
          </button>
          <FaX
            className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer transition-all duration-300 ease-in-out"
            onClick={() => stepsClose(false)}
          />
        </div>
        <div className="flex flex-col space-y-6 overflow-y-auto h-full">
          <div className="flex flex-col gap-6 px-10 py-5">
            <p className="text-xl font-semibold">Add the filters to refine your companies search</p>
            <div className="flex flex-wrap items-center gap-2">
              <input
                type="number"
                className="broder border-gray-200 rounded-md h-9 px-4 w-50 shadow"
                placeholder="Number of companies to import"
                value={maxCount}
                onChange={(e) => setMaxCount(Number(e.target.value) || undefined)}
              />
              {appliedFilters.map((filter, index) => (
                <SearchFilterBase
                  key={index}
                  index={index}
                  filter={filter}
                  changeFilter={onChangeFilter}
                  removeFilter={onRemoveFilter}
                  appliedFilters={appliedFilters}
                  isRemovable={isFilterRemovable(filter)}
                  isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                  hasChanged={filter.hasChanged || false}
                />
              ))}
              <ButtonAddFilter availableFilters={filters} appliedFilters={appliedFilters} addFilters={addFilters} />
            </div>

            <div className="flex justify-start">
              <Button
                onClick={generatePreview}
                disabled={loading}
                type="primary"
                size="large"
                className={`bg-[#4F3FFF] flex flex-row items-center justify-center gap-2 rounded-none text-xl h-12 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-[#4F3FFF] disabled:text-white`}
              >
                {loading && <SpinnerStatus white />}
                {loading ? "Generating Preview..." : previewGenerated ? "Regenerate Preview" : "Generate Preview"}
              </Button>
            </div>
          </div>

          {!previewGenerated && (
            <div className="text-black px-10">
              <p className="font-bold">When using this source, please keep in mind:</p>
              <ul className="list-disc list-inside mt-3 text-sm">
                <li>
                  You can add filters by clicking on the{" "}
                  <span className="w-fit inline-flex items-center justify-center whitespace-nowrap text-sm font-semibold">
                    + Add Filters
                  </span>{" "}
                  button to find better companies according to your needs.
                </li>
                <li>
                  After you are satisfied with the filters, click on{" "}
                  <span className="font-semibold">Generate Preview</span> to see the companies that match your criteria.
                </li>
                <li>The preview will show you the first 25 companies that match your criteria.</li>
                <li>
                  The preview hides some of the data such as the company's name, website, and other sensitive
                  information. The imported companies will not have this data hidden.
                </li>
                <li>
                  If you are satisfied with the results, click on{" "}
                  <span className="font-semibold">Import x Companies</span> to import the companies to your workflow.
                </li>
                <li>
                  You can also specify the number of companies you want to import by entering the number in the input
                  field. If you don't specify the number, all the companies that match your criteria will be imported.
                </li>
                <li>
                  If you want to change the filters, you can click on the{" "}
                  <span className="font-semibold">Regenerate Preview</span> button to see the updated list of companies.
                </li>
                <li>
                  The cost of importing the companies will be displayed at the bottom of the page. The cost is <b>2</b>{" "}
                  credits per company.
                </li>
              </ul>
            </div>
          )}

          {previewGenerated && (
            <Table
              bordered
              rowClassName="bg-[#f7fdff] cursor-pointer"
              columns={columns}
              dataSource={data}
              pagination={false}
              title={() => (
                <span className="text-xl font-semibold bg-[#f7fdff] text-black">
                  Preview of Data {`(${previewData.length} of ${totalItems ?? 0})`}
                </span>
              )}
            />
          )}
        </div>

        <div className="flex flex-row items-center justify-between w-full bg-white p-4 border-t border-gray-200 min-h-[80px]">
          {previewGenerated && (
            <div className="flex flex-row items-center justify-between">
              <>
                <LoaderButton
                  disabled={false}
                  error=""
                  loaderText="Importing..."
                  text={`Import ${Math.min(
                    maxCount === undefined ? (totalItems ?? 0) : maxCount,
                    totalItems ?? 0
                  )} Items`}
                  onClickCallback={async () => {
                    const id = await createNewWorkflow(
                      workflowName,
                      selectedSource,
                      selectedFolder,
                      {
                        filters: filtersToParams(appliedFilters, []),
                        maxCount: maxCount === undefined ? (totalItems ?? 0) : maxCount,
                      },
                      v2
                    );
                    if (id === "") {
                      setError("Failed to create workflow");
                      return;
                    }
                    try {
                      await importTSCompanies(
                        id,
                        filtersToParams(appliedFilters, order_by),
                        maxCount === undefined ? 0 : maxCount,
                        totalItems ?? 0,
                        v2
                      );
                    } catch (e) {
                      setError((e as Error).message);
                    }
                    if (v2) {
                      navigate(`/workflow/${id}?v2=${v2}`);
                    } else {
                      navigate(`/workflow/${id}`);
                    }
                  }}
                  btnClasses="text-white text-md bg-[#4F3FFF] hover:bg-[#4F3FFF] rounded-none"
                />
                <div className="w-[0.5px] h-12 bg-gray-200 mx-4" />
                <span className="text-xl text-[#777777] font-semibold">
                  {`Cost: ${2 * Math.min(maxCount === undefined ? (totalItems ?? 0) : maxCount, totalItems ?? 0)} credits`}
                </span>
              </>
            </div>
          )}
          <span className="text-red-500 font-semibold">
            {error && "Invalid: "}
            {error}
          </span>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default CompaniesListComponent;
