import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/Command";

export function SearchFilterValuePreviewBoolean({ value }: { value: boolean }) {
  return <p className="text-sm">{value ? "Yes" : "No"}</p>;
}

export function SearchFilterFormBoolean({
  onChangeValue,
  value,
}: {
  id: string;
  value: boolean;
  onChangeValue: (_value: boolean) => void;
}) {
  const onChangeCondition = (newValue: boolean) => {
    if (newValue == value) return;
    onChangeValue(newValue);
  };
  return (
    <Command>
      <CommandInput placeholder="Type filter..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandItem onSelect={() => onChangeCondition(true)}>
            <span>Yes</span>
          </CommandItem>
          <CommandItem onSelect={() => onChangeCondition(false)}>
            <span>No</span>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
