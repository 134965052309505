import { ConfigProvider, Table, TableColumnsType, Checkbox } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { ResponseConfiguration } from "../../../utils/interfaces";
import { setStateType } from "../../../utils/constants";

type Template = {
  label: string;
  value: string;
  idx: number;
};

type Map = {
  [key: string]: string;
};

type Props = {
  headers: any;
  data: any[];
  responses: ResponseConfiguration[];
  map: Map;
  setMap: setStateType<Map>;
  setDuplicateMap: setStateType<Record<string, boolean>>;
};

const MapCSV = ({ headers, data, responses, map, setMap, setDuplicateMap }: Props) => {
  //   const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  // const [map, setMap] = useState<Map>({});
  const [err, setErr] = useState<string[]>([]);
  const [renderedRowsCount, setRenderedRowsCount] = useState(0);

  const mapper = (idx: string, responseId: string) => {
    if (err.includes(idx)) {
      const same = Object.entries(map).filter((item) => item[1] !== "" && item[1] === map[idx]);
      if (same.length === 2) {
        setErr((prev) => {
          return prev.filter((item) => item !== idx && item !== same[0][0]);
        });
      } else if (same.length > 2) {
        setErr((prev) => {
          return prev.filter((item) => item !== idx);
        });
      }
    }
    const same = Object.entries(map).filter((item) => item[1] === responseId);
    if (same.length > 0 && responseId !== "") {
      setErr((prev) => {
        return [...prev, same[0][0], idx];
      });
    }
    // if(!Object.values(map).includes(responseId)) {
    setMap((prev) => ({
      ...prev,
      [idx]: responseId,
    }));
    // }
    // console.log(map, "[MapCSV] map", responseId);
  };

  const columns: TableColumnsType<any> = [
    {
      title: "UPLOADED COLUMNS",
      dataIndex: "columns",
      width: 200,
    },
    {
      title: "SAMPLE DATA",
      dataIndex: "sample",
      width: 400,
      render: (textArr: string[]) => {
        return (
          <div className="flex flex-col">
            {textArr.map((item: string, idx: number) => (
              <div key={idx} className="overflow-hidden whitespace-nowrap text-ellipsis w-[400px]">
                {item}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "TEMPLATE COLUMNS",
      dataIndex: "template",
      render: (textArr: Template[], record: any) => {
        if (renderedRowsCount <= record.key) return null;
        // console.log("[MapCSV] textArr", map);
        const columnLabel = record.columns;
        return (
          <Dropdown
            options={textArr.filter((item) => !Object.values(map).includes(item.value))}
            mapper={mapper}
            idx={textArr[0].idx}
            err={err}
            defaultVal={textArr}
            map={map}
            columnLabel={columnLabel}
          />
        );
      },
    },
    {
      title: "FILTER DUPLICATES",
      dataIndex: "key",
      render: (text: number) => {
        const name = map[text];
        if (name === "") return null;
        return (
          <Checkbox
            onChange={(e) => {
              setDuplicateMap((prev) => {
                return name !== ""
                  ? {
                      ...prev,
                      [name]: e.target.checked,
                    }
                  : prev;
              });
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (Object.keys(headers).length > 0) {
      setRows(
        Object.keys(headers)
          .filter((item: any) => item !== "key")
          .map((item: any, idx: number) => {
            return {
              key: idx,
              columns: headers[item],
              sample: data
                .map((row) => {
                  if (!row[idx] || row[idx] === "") return "---";
                  return row[idx];
                })
                .slice(0, 3),
              template: responses.map((item: ResponseConfiguration) => {
                return {
                  label: item.name,
                  value: item.responseId + "",
                  idx: idx,
                };
              }),
            };
          })
      );
      if (Object.values(map).length === 0) {
        setMap(
          Object.keys(headers)
            .filter((item: any) => item !== "key")
            .reduce((acc: Map, item: any) => {
              acc[item] = "";
              return acc;
            }, {})
        );
      }
    }
  }, [headers, data, JSON.stringify(responses)]);

  // Render rows sequentially with a slight delay
  useEffect(() => {
    if (renderedRowsCount < rows.length) {
      const timer = setTimeout(() => {
        setRenderedRowsCount(renderedRowsCount + 1);
      }, 50); // Delay for each row
      return () => clearTimeout(timer);
    }
  }, [renderedRowsCount, rows]);

  // console.log(map, "[MapCSV] map");

  return (
    <div className="relative w-full h-full overflow-auto py-2">
      <ConfigProvider>
        <Table columns={columns} dataSource={rows} showHeader bordered pagination={false} />
      </ConfigProvider>
    </div>
  );
};

export default MapCSV;
