import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import React, { useCallback, useState } from "react";
import { FaRegCopy } from "react-icons/fa";

interface Props {
  content: string;
  placement?: TooltipPlacement;
}

const CopyButton: React.FC<Props> = ({ content, placement }) => {
  const [copied, setCopied] = useState(false);
  const copyContent = useCallback(
    (e: any) => {
      e.stopPropagation();
      navigator.clipboard.writeText(content).then(() => {
        setCopied(true);
      });
    },
    [content]
  );
  return (
    <Tooltip
      placement={placement || "bottomRight"}
      title={copied ? "Copied!" : "Copy to clipboard"}
      arrow={false}
      overlayClassName="border border-gray-200 rounded-md"
      overlayInnerStyle={{
        fontWeight: 600,
        backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
        color: `${copied ? "#f3f4f6" : "#6c727f"}`,
        fontSize: "0.75rem",
      }}
    >
      <button
        className="transition flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
        onClick={copyContent}
      >
        <FaRegCopy className="text-gray-600" />
      </button>
    </Tooltip>
  );
};

export default CopyButton;
