interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const Coins = ({ width, height, className }: Props) => {
  return (
    <svg
      width={width || "12"}
      height={height || "23"}
      className={className}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6276 5.84766C8.12888 5.84766 6.0957 7.87263 6.0957 10.3609C6.0957 12.8494 8.12905 14.8742 10.6276 14.8742C13.1268 14.8742 15.1595 12.8492 15.1595 10.3609C15.1595 7.87198 13.1262 5.84766 10.6276 5.84766ZM10.3745 10.1077H10.8807C11.5302 10.1077 12.0588 10.6341 12.0588 11.2809C12.0588 11.9276 11.5314 12.4522 10.8827 12.4534V13.034C10.8827 13.1747 10.7682 13.2886 10.627 13.2886C10.4857 13.2886 10.3713 13.1747 10.3713 13.034V12.4534C9.72314 12.4515 9.19643 11.9264 9.19643 11.2809C9.19643 11.1402 9.31085 11.0262 9.45211 11.0262C9.59338 11.0262 9.7078 11.1402 9.7078 11.2809C9.7078 11.6469 10.0069 11.9442 10.3745 11.9442H10.8807C11.2483 11.9442 11.5474 11.6463 11.5474 11.2809C11.5474 10.9148 11.2483 10.6169 10.8807 10.6169H10.3745C9.72504 10.6169 9.19643 10.0911 9.19643 9.44436C9.19643 8.79761 9.72378 8.27243 10.3726 8.2718V7.69251C10.3726 7.55183 10.487 7.43788 10.6282 7.43788C10.7695 7.43788 10.8839 7.55183 10.8839 7.69251V8.2718C11.5321 8.27371 12.0582 8.79888 12.0582 9.44436C12.0582 9.58505 11.9437 9.69899 11.8025 9.69899C11.6612 9.69899 11.5468 9.58505 11.5468 9.44436C11.5468 9.07833 11.2476 8.78042 10.8801 8.78042H10.6334C10.6315 8.78042 10.6302 8.78169 10.6283 8.78169C10.6263 8.78169 10.6251 8.78042 10.6231 8.78042H10.3739C10.0063 8.78042 9.70717 9.07833 9.70717 9.44436C9.70717 9.81039 10.0063 10.1077 10.3739 10.1077H10.3745Z"
        fill="#8665FF"
      />
      <path
        d="M0.841797 5.00663C0.841797 6.17282 2.80216 7.15823 5.12318 7.15823C5.76174 7.15823 6.37154 7.08693 6.93856 6.94815C7.59311 6.24728 8.44325 5.73421 9.40456 5.49421V4.00845C8.62346 4.87546 7.02038 5.45919 5.12383 5.45919C3.22663 5.45919 1.62349 4.87546 0.842451 4.00781V5.00596L0.841797 5.00663Z"
        fill="#8665FF"
      />
      <path
        d="M5.12319 4.95036C7.44094 4.95036 9.39885 3.96813 9.40392 2.80381V2.79999C9.40392 2.79999 9.40456 2.79872 9.40456 2.79808C9.40328 1.63252 7.44354 0.648438 5.12318 0.648438C2.80282 0.648438 0.841797 1.63322 0.841797 2.79939C0.841797 3.96555 2.80283 4.95036 5.12319 4.95036Z"
        fill="#8665FF"
      />
      <path
        d="M0.841797 9.42263C0.841797 10.5888 2.80216 11.5742 5.12318 11.5742C5.33028 11.5742 5.53291 11.564 5.73299 11.5488C5.63966 11.1668 5.58469 10.7709 5.58469 10.3609C5.58469 10.1916 5.59364 10.0248 5.61026 9.85993C5.44982 9.86884 5.28809 9.87521 5.12382 9.87521C3.22662 9.87521 1.62347 9.29148 0.842435 8.42383V9.42261L0.841797 9.42263Z"
        fill="#8665FF"
      />
      <path
        d="M0.841797 7.21431C0.841797 8.38051 2.80216 9.36591 5.12318 9.36591C5.31494 9.36591 5.5035 9.357 5.68823 9.34363C5.82246 8.69623 6.08263 8.09403 6.44122 7.5631C6.01678 7.6293 5.57702 7.66622 5.12318 7.66622C3.22598 7.66622 1.62283 7.08249 0.841797 6.21484V7.21431Z"
        fill="#8665FF"
      />
      <path
        d="M0.841846 11.6315C0.841846 12.7964 2.80221 13.7807 5.12323 13.7807C5.69085 13.7807 6.25654 13.7195 6.78446 13.6056C6.39519 13.1492 6.08965 12.6221 5.88319 12.0473C5.63518 12.0696 5.38205 12.0842 5.12318 12.0842C3.22598 12.0842 1.62283 11.5005 0.841797 10.6328L0.841846 11.6315Z"
        fill="#8665FF"
      />
    </svg>
  );
};

export default Coins;
