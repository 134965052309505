import { LuX } from "react-icons/lu";
import { Card } from "./Card";

export function Tag({
  id,
  name,
  onClick,
  className,
  img,
}: {
  id: number | string;
  name: string;
  onClick: (id: number | string) => void;
  className?: string;
  img?: string;
}) {
  return (
    <Card className={`flex flex-row items-center gap-1 p-1 ${className}`}>
      {img && <img src={img} alt={name} className="w-4 h-4" />}
      <p className="text-sm">{name}</p>
      <LuX className="h-3 w-3 cursor-pointer text-slate-500 hover:text-black " onClick={() => onClick(id)} />
    </Card>
  );
}
